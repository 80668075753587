import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classNames from 'classnames'

import Button from '../../forms/neon/button'

import styles from './subscription-confirmation.css'
import { useCommonStyles } from '../../../modules/signup/lib/common.styles'

import SUBSCRIPTION_PAYMENT_VIEW_DETAILS_FRAGMENT from '../../../../graphql/fragments/subscription-payment-view-details.gql'
import { TESTERS } from '../../../lib/tester-classnames'
import { signup } from '../../../lib/analytics/events'
import { signupImage } from '../../../modules/shared/subscription-constants'

function SubscriptionConfirmationOrder({
  details,
  cta,
  onCtaClick,
  orderId,
  isSignupSubscriptionFlow,
  gtmUserId
}) {
  useEffect(() => {
    if (isSignupSubscriptionFlow) {
      signup.subscriptionConfirmationSeen()
      signup.completeWithSubscription(gtmUserId, orderId, true)
    }
  }, [])

  const commonStyles = useCommonStyles()

  return (
    <div className={classNames(styles.wrapper, TESTERS.SIGNUP.CONFIRMATION_ORDER)}>
      <div className={styles.header}>You&apos;re good to go, welcome to Neon!</div>
      <div className={styles.confirmationBox}>
        <div className={styles.details}>
          {
            details.filter(detail => detail.name !== 'Plan').map(detail => (
              <div key={detail.name} className={styles.detail}>
                <div className={styles.detailLabel}>
                  {detail.name}
                </div>
                <div className={styles.detailValue}>
                  {detail.value}
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <img
        src={signupImage}
        alt="Plan confirmation"
        className={commonStyles.image}
      />
      <Button
        className={styles.button}
        text="Get started!"
        e2eIdentifier={cta}
        onClick={onCtaClick}
      />
    </div>
  )
}

SubscriptionConfirmationOrder.propTypes = {
  cta: PropTypes.string,
  details: PropTypes.arrayOf(propType(SUBSCRIPTION_PAYMENT_VIEW_DETAILS_FRAGMENT)),
  onCtaClick: PropTypes.func.isRequired,
  orderId: PropTypes.string,
  isSignupSubscriptionFlow: PropTypes.bool,
  gtmUserId: PropTypes.string.isRequired
}

SubscriptionConfirmationOrder.defaultProps = {
  details: [],
  cta: '',
  orderId: '',
  isSignupSubscriptionFlow: false
}

export default SubscriptionConfirmationOrder
