import { withRouter } from 'react-router-dom'
import {
  compose, branch, renderNothing, withProps, withState, withHandlers, lifecycle
} from 'recompose'
import { graphql } from 'react-apollo'
import { Formik } from 'formik'
import { path, pathOr, trim } from 'ramda'

import { getGQLErrorMsg } from '../../lib/apollo'
import { MODALS } from '../../constants'
import { getModalLocation } from '../../lib/modal'
import ProfilesAddForm from '../../components/profiles/profiles-add-form'

import withMutateAndLoading from '../../hoc/with-mutate-and-loading'
import withConfigCacheOnly from '../../hoc/with-config'
import { getAgeGroupOptions, getDefaultParentalRating, getParentalRatingOptions } from '../../lib/parental-ratings'
import { profileFormValidationSchema } from '../../utils/getValidationSchema'

import PARENTAL_RATINGS_QUERY from '../../../graphql/queries/parental-ratings.gql'
import PROFILE_MUTATION from '../../../graphql/mutations/profile.gql'
import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'
import { segmentTrackAddProfile } from '../../segment/segment-track'

const enhance = compose(
  withRouter,
  graphql(PARENTAL_RATINGS_QUERY, {
    name: 'parentalRatingsQuery'
  }),
  withConfigCacheOnly,
  branch(
    ({
      parentalRatingsQuery
    }) => (
      (
        parentalRatingsQuery && (
          parentalRatingsQuery.loading ||
          parentalRatingsQuery.error
        )
      )
    ),
    renderNothing
  ),
  graphql(PROFILE_MUTATION, {
    options: {
      update: (proxy, response) => {
        const data = proxy.readQuery({ query: ACCOUNT_QUERY })
        const getProfiles = pathOr(data.account.profiles, ['data', 'profile', 'profiles'])

        data.account.profiles = getProfiles(response)
        proxy.writeQuery({ query: ACCOUNT_QUERY, data })
      }
    }
  }),
  withMutateAndLoading, // add mutateWithLoading with loading screen handling
  withHandlers({
    onCancel: ({ history, location }) => () => {
      if (path(['state', 'from'], location)) {
        history.goBack()
      } else {
        history.push(getModalLocation(
          location,
          MODALS.qsParams.profilesManagement,
          null,
          { from: false }
        ))
      }
    }
  }),
  // Avatar selection
  withProps(({ appConfig }) => {
    return ({
      avatars: appConfig.avatars,
      avatar: null
    })
  }),
  withState('isAvatarSelected', 'setIsAvatarSelected', false),
  Formik({
    validateOnChange: true,
    validationSchema: profileFormValidationSchema,
    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: ({ parentalRatingsQuery, avatar }) => {
      const ageGroupOptions = getAgeGroupOptions(parentalRatingsQuery.ageGroups)

      return {
        isKid: false,
        avatar,
        name: '',
        ageGroup: undefined,
        ageGroups: ageGroupOptions,
        birthYear: '',
        gender: '',
        parentalRating: getDefaultParentalRating(parentalRatingsQuery.parentalRatings),
        parentalRatings: getParentalRatingOptions(parentalRatingsQuery.parentalRatings),
        email: '',
        mobile: ''
      }
    },

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (values, {
      props, setError, setSubmitting, setErrors
    }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setError('')
      const {
        mutateAndLoading,
        onCancel
      } = props

      mutateAndLoading('profilesAdd', {
        variables: {
          input: {
            isKid: values.isKid,
            isDefault: values.isDefault,
            name: trim(values.name),
            email: values.isKid ? '' : values.email,
            avatar: values.avatar && values.avatar.id,
            mobile: values.isKid ? '' : trim(values.mobile),
            ageGroup: values.isKid ? values.ageGroup.value.id : undefined,
            maxRating: values.parentalRating.value,
            birthYear: values.birthYear?.value,
            gender: values.gender?.value
          }
        }
      })
        .then((res) => {
          // Add segment data analytics for adding profile
          const addedProfile = res?.data?.profile?.profiles.find(profile => profile?.name === values.name)
          if (addedProfile) {
            const {
              id, name, gender, birthYear, email, mobile, isKid, maxRating
            } = addedProfile
            segmentTrackAddProfile({
              id,
              name,
              gender,
              birthYear,
              email,
              mobile,
              isKid,
              maxRating
            })
          }
          setSubmitting(false)
          onCancel()
        })
        .catch((errors) => {
          setSubmitting(false)
          const graphQLErrors = pathOr([], ['graphQLErrors'], errors)
          const nameError = graphQLErrors.find(e => e.code === 'PROFILE_NAME_DUPLICATE_ERROR')

          if (nameError) {
            return setErrors({
              name: nameError.message
            })
          }

          return setError(getGQLErrorMsg(errors))
        })
    }
  }),
  lifecycle({
    // if not kids clear age group
    UNSAFE_componentWillReceiveProps(newProps) {
      const {
        values,
        setValues,
        parentalRatingsQuery
      } = newProps

      const getAgeGroupIdFromValues = path(['ageGroup', 'value', 'id'])
      const getIsKidsFromValues = path(['isKid'])

      const getRatingOptions = (maxRating) => {
        const ratingOptions = []
        // limit the ratings options to the max allowed for the age group
        parentalRatingsQuery.parentalRatings.find((rating) => {
          ratingOptions.push({
            label: rating.label,
            value: rating.rating
          })
          return (rating.rating === maxRating)
        })
        return ratingOptions
      }

      // if values.isKid changes
      if (getIsKidsFromValues(this.props.values) !== getIsKidsFromValues(values)) {
        // Reset age groups and parental ratings according to isKid value
        let { ageGroup } = values
        if (getIsKidsFromValues(values)) {
          ageGroup = values.ageGroups[values.ageGroups.length - 1]
        } else {
          ageGroup = undefined
        }

        const parentalRatings = getRatingOptions(ageGroup && ageGroup.value.maxRating)
        const parentalRating = parentalRatings[parentalRatings.length - 1]

        setValues({
          ...values,
          parentalRatings,
          parentalRating,
          ageGroup
        })
      }

      // if values.ageGroup changes
      if (getAgeGroupIdFromValues(this.props.values) !== getAgeGroupIdFromValues(values)) {
        // Reset parental ratings according to ageGroup value
        // the current age group value for the profile
        const ageGroupValue = values.ageGroup && values.ageGroup.value

        const parentalRatings = getRatingOptions(ageGroupValue && ageGroupValue.maxRating)
        const parentalRating = parentalRatings[parentalRatings.length - 1]

        setValues({
          ...values,
          parentalRatings,
          parentalRating
        })
      }
    }
  })
)

export default enhance(ProfilesAddForm)
