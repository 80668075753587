import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Form from '../forms/layout-form'
import Row from '../forms/layout-row'
import Col from '../forms/layout-column'
import Input from '../forms/input'
import Toggle from '../forms/toggle'
import Select from '../forms/select'
import SubmitButton from '../forms/button'
import ProfileAvatar from './shared/profile-avatar'
import ProfilesAvatarForm from '../../containers/profiles/profiles-avatar-form'
import getGenderOptions from '../../utils/getGenderOptions'
import getBirthYearOptions from '../../utils/getBirthYearOptions'
import { PHONE_MAX_LENGTH } from '../../constants'
import { appConfigPropType } from '../player/utils/prop-types'

import styles from './profiles-add-form.css'

function ProfilesAddForm({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  error,
  touched,
  isSubmitting,
  setFieldValue,
  onCancel,
  setIsAvatarSelected,
  isAvatarSelected,
  avatars,
  header,
  footer,
  appConfig
}) {
  const isKid = values.isKid
  const configValues = appConfig?.values
  const birthYearOptions = getBirthYearOptions(configValues, false)
  const genderOptions = getGenderOptions()
  const birthYearWarning = (values.birthYear || touched.birthYear) && errors.birthYear
  const genderWarning = (values.gender || touched.gender) && errors.gender
  const nonKidsInput = (
    <div>
      <Input
        className={styles.full}
        label="Email"
        name="email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        warning={(values.email || touched.email) && errors.email}
        isValid={Boolean(values.email && !errors.email)}
      />

      <div>
        <div className={styles.inputWrapper}>
          <Select
            wrapperClass={styles.half}
            name="birthYear"
            label="Year of birth"
            value={values.birthYear}
            options={birthYearOptions}
            onChange={option => setFieldValue('birthYear', option)}
            onBlur={handleBlur}
            warning={birthYearWarning}
            isValid={Boolean(
              values.birthYear &&
              touched.birthYear &&
              !errors.birthYear
            )}
            exclamationClass={styles.noExclamation}
            isRequired
            plainTheme
          />
          <Select
            wrapperClass={styles.half}
            name="gender"
            label="Gender"
            value={values.gender}
            options={genderOptions}
            onChange={option => setFieldValue('gender', option)}
            onBlur={handleBlur}
            warning={genderWarning}
            isValid={Boolean(
              values.gender &&
              touched.gender &&
              !errors.gender
            )}
            exclamationClass={styles.noExclamation}
            isRequired
            plainTheme
          />
        </div>
        <div className={classnames(styles.ageAndGenderPolicy, (birthYearWarning || genderWarning) && styles.errorExtraMargin)}>
          <span>We’ll use this information to create a more personalised experience on NEON for you.</span>
        </div>
      </div>

      <Input
        className={styles.half}
        label="Mobile"
        name="mobile"
        value={values.mobile}
        onChange={handleChange}
        onBlur={handleBlur}
        warning={(values.mobile || touched.mobile) && errors.mobile}
        isValid={Boolean(values.mobile && !errors.mobile)}
        inputmode="numeric"
        maxLength={PHONE_MAX_LENGTH}
      />

      <div className={classnames(styles.mobileExtraInfo, errors.mobile && styles.errorExtraMargin)}>
        <p>
          (Why? So we can tell you about new shows you&#39;ll like)
        </p>
      </div>
    </div>
  )
  const kidsToggle = (
    <div className={styles.kidsToggleWrapper}>
      <Toggle
        id="kidsProfile"
        label="Kids profile"
        name="isKid"
        checked={isKid}
        border={!isKid}
        onChange={handleChange}
        className={classnames(
          styles.half,
          styles.toggleBorderFix
        )}
      />
      {
        isKid
          ? (
            <Select
              wrapperClass={classnames(
                styles.selectWrapper,
                styles.half
              )}
              name="ageGroup"
              value={values.ageGroup}
              options={values.ageGroups}
              onChange={option => setFieldValue('ageGroup', option)}
              onBlur={handleBlur}
              plainTheme
            />
          )
          : null
      }
    </div>
  )
  return (
    <div
      className={classnames({
        [styles.kidsProfile]: isKid
      })}
    >
      <Form
        isSubmitting={isSubmitting}
        handleSubmit={handleSubmit}
      >
        {
          isAvatarSelected || !values.avatar
            ? (
              <ProfilesAvatarForm
                setIsAvatarSelected={setIsAvatarSelected}
                selectedAvatar={values.avatar}
                setSelectedAvatar={avatar => setFieldValue('avatar', avatar)}
                avatars={avatars}
                onCancel={onCancel}
              />
            )
            : (
              <div>
                {header}
                <div className={styles.formRowWrap}>
                  <div className={styles.avatarColumn}>
                    <div>
                      <ProfileAvatar uri={values.avatar.uri} />
                    </div>
                    <SubmitButton
                      className={styles.selectAvatarButton}
                      onClick={() => {
                        setIsAvatarSelected(true)
                      }}
                      text="Change Pic"
                      secondary
                      disabled={false}
                    />
                  </div>
                  <div className={styles.inputColumn}>
                    {
                      values.isDefault
                        ? <div>Default profile</div>
                        : kidsToggle
                    }
                    <div className={styles.inputWrapper}>
                      <Input
                        className={styles.half}
                        label="Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        warning={(values.name || touched.name) && errors.name}
                        isValid={Boolean(values.name && !errors.name)}
                        isRequired
                      />
                      <Select
                        wrapperClass={styles.half}
                        name="parentalRating"
                        label="Content rating"
                        value={values.parentalRating}
                        options={values.parentalRatings}
                        onChange={option => setFieldValue('parentalRating', option)}
                        onBlur={handleBlur}
                        warning={
                          (values.parentalRating || touched.parentalRating) && errors.parentalRating
                        }
                        isValid={Boolean(
                          values.parentalRating &&
                          touched.parentalRating &&
                          !errors.parentalRating
                        )}
                        isRequired
                        plainTheme
                      />
                    </div>
                    {
                      isKid ? null : nonKidsInput
                    }
                  </div>
                </div>
                <Row>
                  <div className={styles.termsAndPrivacy}>
                    { !isKid && (
                      <p>
                        (By providing your details, you agree to the Neon
                        Terms of Service and Privacy Policy)
                      </p>
                    )}
                  </div>
                </Row>
                <Row className={styles.buttonWrapper}>
                  <Col className={styles.saveWrapper}>
                    <SubmitButton disabled={isSubmitting} text="Save" error={error} />
                  </Col>
                  <Col className={styles.cancelWrapper}>
                    <SubmitButton
                      disabled={isSubmitting}
                      text="Cancel"
                      onClick={(e) => { e.preventDefault(); onCancel() }}
                      secondary
                    />
                  </Col>
                </Row>
                {footer}
              </div>
            )
        }
      </Form>
    </div>
  )
}

ProfilesAddForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  isAvatarSelected: PropTypes.bool.isRequired,
  setIsAvatarSelected: PropTypes.func.isRequired,
  avatars: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  header: PropTypes.element,
  footer: PropTypes.element,
  appConfig: appConfigPropType.isRequired
}

ProfilesAddForm.defaultProps = {
  error: '',
  header: null,
  footer: null
}

export default ProfilesAddForm
