import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './add-profile.css'

const PlusIcon = ({ className, lineClassName, ...props }) => (
  <svg {...props} viewBox="0 0 200 200" className={className}>
    <line className={lineClassName} x1="100" y1="20" x2="100" y2="180" />
    <line className={lineClassName} x1="20" y1="100" x2="180" y2="100" />
  </svg>
)

PlusIcon.propTypes = {
  className: PropTypes.string.isRequired,
  lineClassName: PropTypes.string.isRequired
}

PlusIcon.defaultProps = {
  className: ''
}

function Profile({ onClick, isProfileAllowBasicUpsell }) {
  return (
    <div
      className={styles.wrapper}
      onClick={onClick}
    >
      <div // eslint-disable-line jsx-a11y/no-static-element-interactions
        className={styles.circle}
      >
        <PlusIcon
          lineClassName={styles.line}
          className={classNames(styles.plusIcon, isProfileAllowBasicUpsell && styles.disabledColor)}
          alt="Add Profile"
        />
      </div>
      <div className={classNames(styles.title, isProfileAllowBasicUpsell && styles.disabledColor)}>Add profile</div>
    </div>
  )
}

Profile.propTypes = {
  onClick: PropTypes.func.isRequired,
  isProfileAllowBasicUpsell: PropTypes.bool
}

export default Profile
