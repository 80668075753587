import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'

import VIDEO_ENCODING_FRAGMENT from '../../../graphql/fragments/video-encoding.gql'
import PRODUCT_FRAGMENT from '../../../graphql/fragments/product.gql'
import styles from './product-selector.css'

function getDownloadSize(encodings, product) {
  const encoding = encodings.find(v => v.format === product.streamVideoFormat)
  if (!encoding || !encoding.size) {
    return ''
  }

  // Encoding size is in MB
  const sizeInGB = Math.ceil(encoding.size / 1000)
  return `Up to ${sizeInGB}GB`
}

function ProductSelector({
  products,
  selectedProduct,
  setSelectedProduct,
  videoEncodings
}) {
  const downloadSize = getDownloadSize(videoEncodings, selectedProduct)

  return (
    <div className={styles.qualitySelector}>
      <div className={styles.label}>Select Quality</div>

      <div className={styles.selectorWrapper}>
        {products.map(product => (
          <div
            className={classNames({
              [styles.selected]: product.id === selectedProduct.id
            })}
            onClick={() => setSelectedProduct(product)}
            key={product.id}
          >
            {product.streamVideoFormat}
          </div>
        ))}
      </div>

      {downloadSize && (
        <div className={styles.downloadSize}>
          {downloadSize}
        </div>
      )}
    </div>
  )
}

ProductSelector.propTypes = {
  products: PropTypes.arrayOf(propType(PRODUCT_FRAGMENT)).isRequired,
  selectedProduct: propType(PRODUCT_FRAGMENT).isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  videoEncodings: PropTypes.arrayOf(propType(VIDEO_ENCODING_FRAGMENT))
    .isRequired
}

export default ProductSelector
