import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { useDispatch } from 'react-redux'

import Profile from './shared/profile'
import AddProfile from './shared/add-profile'
import { PROFILES } from '../../constants'
import styles from './profiles-manage-list.css'
import { useProfileIsDisabled } from '../../hooks/useProfileIsDisabled'
import PROFILE_FRAGMENT from '../../../graphql/fragments/profile.gql'
import { showPlanUpgradePopup } from '../../actions'

function ManageProfileList({
  profiles,
  currentProfile,
  onAddProfile,
  onEditProfile,
  onDeleteProfile,
  currentActiveEdit,
  setCurrentActiveEdit,
  isProfileAllowBasicUpsell,
  profileLimit
}) {
  const dispatch = useDispatch()
  const showUpgradePopup = (isProfileDisabled) => {
    if (isProfileDisabled) {
      dispatch(showPlanUpgradePopup())
    }
  }

  const onAddIconClick = () => {
    if (isProfileAllowBasicUpsell) {
      dispatch(showPlanUpgradePopup())
    } else {
      onAddProfile()
    }
  }

  return (
    <div className={styles.profileList}>
      {
        profiles.map((profile, index) => {
          const onDelete = profile.isDefault ? null : () => {
            onDeleteProfile(profile.id)
          }
          const isProfileDisabled = useProfileIsDisabled(isProfileAllowBasicUpsell, profileLimit, index)

          return (
            <div
              className={styles.profileListItem}
              key={profile.id}
              onClick={() => showUpgradePopup(isProfileDisabled)}
            >
              <Profile
                manage
                profile={profile}
                currentProfile={currentProfile}
                isProfileDisabled={isProfileDisabled}
                onEditProfile={() => onEditProfile(profile.id)}
                onDeleteProfile={onDelete}
                isEditActive={currentActiveEdit === profile.id}
                onActiveEdit={() => {
                  setCurrentActiveEdit(profile.id)
                }}
                onDeactiveEdit={() => {
                  setCurrentActiveEdit(null)
                }}
              />
            </div>
          )
        })
      }
      {
        profiles.length < PROFILES.maxNumber && (
          <div className={styles.profileListItem}>
            <AddProfile
              onClick={onAddIconClick}
              isProfileAllowBasicUpsell={isProfileAllowBasicUpsell}
            />
          </div>
        )
      }
    </div>
  )
}

ManageProfileList.propTypes = {
  profiles: PropTypes.arrayOf(propType(PROFILE_FRAGMENT)).isRequired,
  currentProfile: propType(PROFILE_FRAGMENT),
  onAddProfile: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  onDeleteProfile: PropTypes.func.isRequired,
  currentActiveEdit: PropTypes.string,
  setCurrentActiveEdit: PropTypes.func.isRequired,
  isProfileAllowBasicUpsell: PropTypes.bool.isRequired,
  profileLimit: PropTypes.number.isRequired
}

ManageProfileList.defaultProps = {
  currentProfile: {},
  currentActiveEdit: null,
  isProfileAllowBasicUpsell: false
}

export default ManageProfileList
