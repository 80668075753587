import {
  compose,
  withHandlers,
  withProps,
  withStateHandlers
} from 'recompose'
import { pathOr } from 'ramda'
import { graphql } from 'react-apollo'
import { connect } from 'react-redux'

import SignupSubscriptionRental from '../../../components/subscriptions/signup-subscription-rental'

import withIsAuthenticated from '../../../hoc/is-authenticated'
import withMutateAndLoading from '../../../hoc/with-mutate-and-loading'
import withSearchParams from '../../../hoc/with-search-params'

import { MW_ERRORS } from '../../../constants'
import { getGQLError } from '../../../lib/apollo'
import { displayErrorDialog } from '../../../actions'

import SUBSCRIPTION_MUTATION from '../../../../graphql/mutations/subscribe.gql'

function mapDispatchToProps(dispatch) {
  return {
    triggerErrorDialog(error) {
      dispatch(displayErrorDialog(error))
    }
  }
}

export default compose(
  withIsAuthenticated,
  withSearchParams,
  connect(null, mapDispatchToProps),
  graphql(SUBSCRIPTION_MUTATION),
  withMutateAndLoading,
  withProps(({ search }) => ({
    voucherCode: pathOr('', ['voucher'], search),
    paymentMethodId: pathOr('', ['paymentMethodId'], search)
  })),
  withStateHandlers(
    ({ search }) => {
      // If this modal loads with `orderId` and `subscriptionId` parameters
      // we can skip the selection screen (step 1).
      const orderId = pathOr('', ['orderId'], search)
      const subscriptionId = pathOr('', ['subscriptionId'], search)
      const step = orderId ? 2 : 1
      return {
        step,
        subscriptionId,
        orderId
      }
    },
    {
      setStep: () => step => ({
        step
      }),
      onSubscribeSuccess: () => (orderId, subscriptionId) => ({
        subscriptionId,
        orderId,
        step: 2
      })
    }
  ),
  withHandlers({
    dismiss: ({ history, location }) => () => {
      history.push(location.pathname)
    },
    onSubscriptionSelection: ({
      mutateAndLoading,
      orderId,
      voucherCode,
      onSubscribeSuccess,
      paymentMethodId,
      triggerErrorDialog
    }) => (subscriptionId) => {
      mutateAndLoading('subscribe', {
        variables: {
          productId: subscriptionId,
          voucher: voucherCode,
          paymentMethodId
        }
      })
        .catch((error) => {
          const errorObj = getGQLError(error)
          if (errorObj.code === MW_ERRORS.INVALID_SECURITY_CODE) {
            triggerErrorDialog(errorObj)
          }
        })
        .then((response) => {
          orderId = response.data.subscribe
          onSubscribeSuccess(orderId, subscriptionId)
        })
    }
  })
)(SignupSubscriptionRental)
