import { compose, withHandlers, withStateHandlers } from 'recompose'
import ProfilesAvatarForm from '../../components/profiles/profiles-avatar-form'

const initialProfilesAvatarFormState = {
  selectedAvatar: null
}

export const enhance = compose(
  withStateHandlers(
    initialProfilesAvatarFormState,
    {
      onAvatarClick: () => (avatar) => {
        return {
          selectedAvatar: avatar
        }
      }
    }
  ),
  withHandlers({
    onNextClick: ({
      selectedAvatar,
      setSelectedAvatar,
      setIsAvatarSelected
    }) => () => {
      setSelectedAvatar(selectedAvatar)
      setIsAvatarSelected(false)
    }
  })
)

export default enhance(ProfilesAvatarForm)
