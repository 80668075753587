import React from 'react'
import PropTypes from 'prop-types'

import NeonButton from './neon-form-elements/button'
import styles from './request-sent.css'

import { E2E_SELECTORS } from '../../e2e-selectors'

function PasswordResetRequestSent({ email, onLoginClick }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Next step, check your email
        </h1>
      </div>

      <p className={styles.description}>
        Go to your email and follow the instructions in the email we&apos;ve just
        sent you. If you haven&apos;t received the email, make sure to check your
        junk, spam or promotions folder.  The email was sent to this address:
      </p>

      <p className={styles.email}>{email}</p>

      <NeonButton
        className={styles.button}
        e2eIdentifier={E2E_SELECTORS.RESET_SENT_LOGIN_BUTTON}
        text="Log in"
        onClick={onLoginClick}
      />
    </div>
  )
}

PasswordResetRequestSent.propTypes = {
  email: PropTypes.string.isRequired,
  onLoginClick: PropTypes.func.isRequired
}

export default PasswordResetRequestSent
