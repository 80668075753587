import Yup from '../lib/yup'
import {
  FORM_MESSAGES,
  FORM_VALUES,
  PHONE_REG_EXP
} from '../constants'

export const profileFormValidationSchema = Yup.object().shape({
  isKid: Yup.boolean(),
  name: Yup.string().trim().max(12).required(FORM_MESSAGES.required),
  parentalRating: Yup.object().shape({
    value: Yup.string(FORM_MESSAGES.required),
    rating: Yup.string(FORM_MESSAGES.required)
  }, FORM_MESSAGES.required).required(FORM_MESSAGES.required),
  email: Yup.string().when('isKid', {
    is: true,
    then: Yup.string(),
    otherwise: Yup.string().email(FORM_MESSAGES.email.valid)
  }),
  birthYear: Yup.string().when('isKid', {
    is: false,
    then: Yup.string().required(FORM_MESSAGES.required)
  }),
  gender: Yup.string().when('isKid', {
    is: false,
    then: Yup.string().required(FORM_MESSAGES.required)
  }),
  mobile: Yup.string().when('isKid', {
    is: false,
    then: Yup.string().trim()
      .min(FORM_VALUES.mobile.min, FORM_MESSAGES.mobile.min)
      .matches(PHONE_REG_EXP, { message: FORM_MESSAGES.mobile.match, excludeEmptyString: true })
      .max(FORM_VALUES.mobile.max, FORM_MESSAGES.mobile.max)
  })
})
