import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { Formik } from 'formik'

import PinResetForm from '../../components/pin/pin-reset-form'

import Yup from '../../lib/yup'
import { getGQLErrorMsg } from '../../lib/apollo'

import withMutateAndLoading from '../../hoc/with-mutate-and-loading'

import ACCOUNT_MUTATION from '../../../graphql/mutations/account.gql'

import { FORM_VALUES, FORM_MESSAGES } from '../../constants'

const enhance = compose(
  withRouter,
  graphql(ACCOUNT_MUTATION),
  withMutateAndLoading, // add mutateWithLoading with loading screen handling
  connect(
    () => ({}),
    dispatch => ({
      dispatch
    })
  ),
  withHandlers({
    onCancel: ({ history, location }) => () => {
      history.push(location.pathname)
    }
  }),
  Formik({
    validateOnChange: true,
    // We now map React props to form values. These will be injected as [`values`] into
    // our form. (Note: in the real world, you would destructure props, but for clarity this is
    // not shown)
    mapPropsToValues: () => ({
      pin: ''
    }),

    // We can optionally define our a validation schema with Yup. It's like Joi, but for
    // the browser. Errors from Yup will be injected as `errors` into the form.
    validationSchema: Yup.object().shape({
      pin: Yup.string()
        .min(FORM_VALUES.pin.length, FORM_MESSAGES.pin.min)
        .max(FORM_VALUES.pin.length, FORM_MESSAGES.pin.max)
        .matches(/^[0-9]*$/, { message: FORM_MESSAGES.pin.valid, excludeEmptyString: false })
        .required(FORM_MESSAGES.required),
      pinConfirmation: Yup.string()
        .min(FORM_VALUES.pin.length, FORM_MESSAGES.pin.min)
        .max(FORM_VALUES.pin.length, FORM_MESSAGES.pin.max)
        .matches(/^[0-9]*$/, { message: FORM_MESSAGES.pin.valid, excludeEmptyString: false })
        .sameAs(Yup.ref('pin'), FORM_MESSAGES.pin.match)
        .required(FORM_MESSAGES.required)
    }),

    // Formik lets you colocate your submission handler with your form.
    // In addition to your from `values`, you have
    // access to all props and some stateful helpers.
    // handleSubmit: (values, { props, setErrors, setSubmitting }) => {
    handleSubmit: (values, { props, setError, setSubmitting }) => {
      // do stuff with your payload
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass validation (if you specify it).
      setError('')
      const { mutateAndLoading, onPinReset } = props

      mutateAndLoading('account', {
        variables: {
          input: {
            pin: values.pin
          }
        }
      })
        .then((res) => {
          setSubmitting(false)

          if (res.data.account.hasPin) {
            onPinReset(true)
            // props.history.push('/pin-reset');
          }
        })
        .catch((error) => {
          // display error
          setSubmitting(false)
          setError(getGQLErrorMsg(error))
        })
    }
  })
)

export default enhance(PinResetForm)
