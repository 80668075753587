import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import {
  compose,
  defaultProps,
  setPropTypes,
  branch,
  renderComponent,
  withState,
  withProps,
  withHandlers,
  lifecycle
} from 'recompose'
import { path, propEq } from 'ramda'

import { LoadingModal } from '../../../../components/loading/modal'
import Step3 from '../../../../components/subscriptions/neon/welcome-back/step-three'
import { pushQueryParams } from '../../../../lib/location'

import SUBSCRIPTION_PAYMENT_VIEW_QUERY from '../../../../../graphql/queries/subscription-payment-view.gql'
import ACCOUNT_QUERY_WITH_DIR from '../../../../../graphql/queries/account-with-directives.gql'

export const withStepThreeLifecycle = lifecycle({
  componentDidMount() {
    const {
      orderId, history, location, setOnCloseHandler, paymentMethodId
    } = this.props

    saveOrderIdToConfirmationViewIfNotNeedPayment({ _this: this })

    setOnCloseHandler(() => {
      pushQueryParams(history, location, {
        step: 4,
        orderId,
        paymentMethodId
      })
    })
  },
  componentWillUnmount() {
    this.props.setOnCloseHandler(null)
  }
})

const enhance = compose(
  defaultProps({
    voucherCode: ''
  }),
  setPropTypes({
    voucherCode: PropTypes.string,
    subscriptionId: PropTypes.string.isRequired
  }),
  withStepThreeLifecycle,
  withState('savedOrderId', 'setSavedOrderId', ''),
  graphql(SUBSCRIPTION_PAYMENT_VIEW_QUERY, {
    name: 'paymentQueryPromo',
    options: ({ voucherCode, subscriptionId }) => {
      return {
        variables: {
          voucher: voucherCode,
          subscriptionId,
          promotions: true
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    }
  }),
  graphql(SUBSCRIPTION_PAYMENT_VIEW_QUERY, {
    name: 'paymentQuery',
    options: ({ voucherCode, subscriptionId }) => {
      return {
        variables: {
          voucher: voucherCode,
          subscriptionId,
          promotions: false
        },
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true
      }
    }
  }),
  // Given that we explicitly get an `orderId` with the paymentViewQuery,
  // we need to refetch the account subscription information, so
  // that the UI gets updated. This is specifically when a user redeems
  // a voucher (Spark) that doesn't require a payment method, but has an effect
  // on their previous subscription status.
  graphql(ACCOUNT_QUERY_WITH_DIR, {
    name: 'accountQuery',
    options: {
      variables: {
        withSubscription: true
      },
      fetchPolicy: 'network-only'
    },
    skip: ({ paymentQueryPromo }) => (
      path(['subscriptionPaymentView', 'orderId'], paymentQueryPromo) == null
    )
  }),
  branch(
    ({ paymentQuery, paymentQueryPromo }) => (
      (paymentQuery && (paymentQuery.loading || paymentQuery.error)) ||
      (paymentQueryPromo && (paymentQueryPromo.loading || paymentQueryPromo.error))
    ),
    renderComponent(LoadingModal)
  ),
  withState('withPromo', 'setWithPromo', true),
  withProps(({
    withPromo,
    paymentQueryPromo,
    paymentQuery
  }) => {
    const query = withPromo ? paymentQueryPromo : paymentQuery
    return ({
      header: query.subscriptionPaymentView.header,
      subheader: query.subscriptionPaymentView.subHeader,
      message: query.subscriptionPaymentView.message,
      cta: query.subscriptionPaymentView.cta || '',
      continueCta: query.subscriptionPaymentView.continueCta || '',
      details: query.subscriptionPaymentView.details,
      subscribed: query.subscriptionPaymentView.subscribed,
      promotion: query.subscriptionPaymentView.promotion,
      iframe: paymentQueryPromo.subscriptionPaymentView.iframe,
      orderId: query.subscriptionPaymentView.orderId
    })
  }),
  withHandlers({
    proceedToConfirmation: ({ onPaymentSubmission, savedOrderId }) => () => {
      proceedToConfirmationWithOrderId({ onPaymentSubmission, savedOrderId })
    },
    onIframeLoad: ({ onPaymentSubmission, savedOrderId }) => (iframeQueryParams) => {
      const orderId = savedOrderId || path(['orderId'], iframeQueryParams)
      if (!orderId) {
        return
      }
      if (propEq('status', 'success', iframeQueryParams)) {
        const paymentMethodId = path(['paymentMethodId'], iframeQueryParams)
        onPaymentSubmission(orderId, paymentMethodId)
      }
    }
  })
)

const saveOrderIdToConfirmationViewIfNotNeedPayment = ({ _this }) => {
  if (_this.props.orderId && !_this.props.savedOrderId) {
    _this.props.setSavedOrderId(_this.props.orderId)
  }
}

const proceedToConfirmationWithOrderId = ({ onPaymentSubmission, savedOrderId }) => {
  onPaymentSubmission(savedOrderId)
}

export default enhance(Step3)
