import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import ManageProfileList from '../../containers/profiles/profiles-manage-list'

import PROFILE_FRAGMENT from '../../../graphql/fragments/profile.gql'

import styles from './profiles-manage.css'

function ManageProfiles({
  profiles,
  profileLimit,
  isProfileAllowBasicUpsell,
  currentProfile,
  onAddProfile,
  onEditProfile,
  onDeleteProfile,
  onDoneClick
}) {
  return (
    <div className={`manage-profiles ${styles.wrapper}`}>
      <div className={styles.header}>
        <h1 className={styles.title}>Manage your profile or add a new one</h1>
      </div>
      <ManageProfileList
        profiles={profiles}
        currentProfile={currentProfile}
        onAddProfile={onAddProfile}
        onEditProfile={onEditProfile}
        onDeleteProfile={onDeleteProfile}
        isProfileAllowBasicUpsell={isProfileAllowBasicUpsell}
        profileLimit={profileLimit}
      />
      <button
        onClick={onDoneClick}
        className={styles.doneButton}
      >
        Done
      </button>
    </div>
  )
}

ManageProfiles.propTypes = {
  profiles: PropTypes.arrayOf(propType(PROFILE_FRAGMENT)).isRequired,
  currentProfile: propType(PROFILE_FRAGMENT),
  onAddProfile: PropTypes.func.isRequired,
  onEditProfile: PropTypes.func.isRequired,
  onDeleteProfile: PropTypes.func.isRequired,
  onDoneClick: PropTypes.func.isRequired,
  isProfileAllowBasicUpsell: PropTypes.bool.isRequired,
  profileLimit: PropTypes.number.isRequired
}

ManageProfiles.defaultProps = {
  currentProfile: {},
  isProfileAllowBasicUpsell: false
}

export default ManageProfiles
