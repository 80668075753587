import { lifecycle } from 'recompose'
import { getGQLErrorCode, getGQLErrorMsg } from '../../../../../lib/apollo'
import { MW_ERRORS, VOUCHERS } from '../../../../../constants'
import { statusIneligible } from './helper'
import { findDefaultSVODCreditCard } from '../../../../../lib/account'

export const withSubscriptionSelectionViewLifecycle = lifecycle({
  componentDidUpdate() {
    const {
      voucherQueryError,
      subscriptionsQueryError,
      voucherDetails
    } = this.props
    if (voucherQueryError) {
      const errorCode = getGQLErrorCode(voucherQueryError)
      if (errorCode !== MW_ERRORS.INTERNAL_SERVER_ERROR) {
        this.props.renderStep(1, { error: getGQLErrorMsg(voucherQueryError) })
      }
    } else if (voucherDetails && voucherDetails.type !== VOUCHERS.types.SVOD) {
      this.props.renderStep(1, {
        error: 'Invalid voucher: only subscription vouchers are accepted.'
      })
    } else if (subscriptionsQueryError) {
      const errorCode = getGQLErrorCode(subscriptionsQueryError)
      if (errorCode !== MW_ERRORS.INTERNAL_SERVER_ERROR) {
        this.props.renderStep(1, {
          error: getGQLErrorMsg(subscriptionsQueryError)
        })
      }
    }
  }
})

export const subscribeAndProceedToConfirmation = ({
  account,
  subscribeToPlan,
  planId,
  voucher,
  setOnCloseHandler,
  _this
}) => {
  const hasDefaultSVODCard = findDefaultSVODCreditCard(account)
  if (hasDefaultSVODCard && statusIneligible(account)) {
    // Because we're already loading, we do not need to manually call `startSubscribing` here.
    const { id: paymentMethodId } = hasDefaultSVODCard
    return subscribeToPlan({
      variables: { productId: planId, voucher, paymentMethodId }
    })
      .catch(() => {
        _this.props.renderStep(3, {
          voucher,
          planId,
          addSubscriptionFlow: true,
          setOnCloseHandler
        })
      })
      .then(response => {
        if (!response.error) {
          const {
            data: { subscribe: orderId }
          } = response
          _this.props.renderStep(4, { orderId, voucher })
        } else {
          _this.props.renderStep(3, {
            voucher,
            planId,
            addSubscriptionFlow: true,
            setOnCloseHandler
          })
        }
      })
  }
  return proceedToStep3IfNoDefaultPayment({
    voucher,
    planId,
    setOnCloseHandler,
    _this
  })
}

const proceedToStep3IfNoDefaultPayment = ({
  voucher,
  planId,
  setOnCloseHandler,
  _this
}) => {
  _this.props.renderStep(3, {
    voucher,
    planId,
    addSubscriptionFlow: true,
    setOnCloseHandler
  })
}
