import React from 'react'
import PropTypes from 'prop-types'

import styles from './subscription-card.css'

function SubscriptionCard({ highlight, children }) {
  const classes = [
    styles.subscriptionCard,
    (highlight && styles.highlighted) || '' // highlighted class
  ].join(' ')
  return (
    <div className={classes}>
      {children}
    </div>
  )
}

SubscriptionCard.propTypes = {
  highlight: PropTypes.bool.isRequired
}

export default SubscriptionCard
