import {
  branch, compose, renderComponent, withProps
} from 'recompose'
import { graphql } from 'react-apollo'

import { LoadingModal } from '../../../components/loading/modal'
import SubscriptionSelection from '../../../components/subscriptions/subscription-selection'

import SUBSCRIPTION_SELECTION_VIEW_QUERY from '../../../../graphql/queries/subscription-selection-view.gql'

const enhance = compose(
  graphql(SUBSCRIPTION_SELECTION_VIEW_QUERY, {
    name: 'subscriptionsQuery',
    options: ({ voucherCode, paymentMethodId }) => {
      return {
        variables: {
          voucher: voucherCode,
          paymentMethodId
        },
        notifyOnNetworkStatusChange: true,
        // Give the cache a chance in case this was fetched in `rental-confirmation`
        fetchPolicy: 'cache-first'
      }
    },
    props: ({ ownProps, subscriptionsQuery }) => ({
      ...ownProps,
      subscriptionsQuery,
      loading: subscriptionsQuery.loading,
      error: subscriptionsQuery.error
    })
  }),
  branch(
    ({ loading, error }) => (loading || error),
    renderComponent(LoadingModal)
  ),
  withProps(({ dismiss, subscriptionsQuery: { subscriptionSelectionView } }) => {
    const {
      header, subHeader, subscriptions, footer, continueCta
    } = subscriptionSelectionView
    return {
      header,
      subHeader,
      subscriptions,
      footer,
      continueCta,
      onContinueCtaClick: dismiss
    }
  })
)

export default enhance(SubscriptionSelection)
