import PropTypes from 'prop-types'
import {
  pathOr, isEmpty, propEq, find, path
} from 'ramda'
import { graphql } from 'react-apollo'
import { propType } from 'graphql-anywhere'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  compose,
  setDisplayName,
  setPropTypes,
  branch,
  renderComponent,
  withProps,
  withHandlers,
  lifecycle,
  withState
} from 'recompose'

import PaymentView from '../../components/rentals/payment-view'
import { LoadingModal } from '../../components/loading/modal'
import { MW_ERRORS } from '../../constants'
import { displayErrorDialog } from '../../actions'

import { getGQLErrorCode, getGQLError } from '../../lib/apollo'

import CONTENT_ITEM_FRAGMENT from '../../../graphql/fragments/content-item.gql'
import RENTAL_PAYMENT_VIEW_QUERY from '../../../graphql/queries/rental-payment-view.gql'
import { dataLayerPurchase } from '../../lib/analytics/datalayer'
import { segmentTrackInitiatePurchase } from '../../segment/segment-track'

function mapDispatchToProps(dispatch) {
  return {
    triggerErrorDialog(error) {
      dispatch(displayErrorDialog(error))
    }
  }
}

export const enhance = compose(
  setDisplayName('RentalPaymentViewContainer'),
  withRouter,
  connect(null, mapDispatchToProps),
  withProps(({ contentItem }) => ({
    defaultSelectedProductId: path(['products', '0', 'id'], contentItem)
  })),
  setPropTypes({
    contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired,
    defaultSelectedProductId: PropTypes.string.isRequired,
    onPaymentSubmission: PropTypes.func.isRequired,
    onRequestClose: PropTypes.func.isRequired,
    dataLayerPurchaseFunction: PropTypes.func
  }),
  graphql(RENTAL_PAYMENT_VIEW_QUERY, {
    name: 'rentalPaymentViewQuery',
    options: ({ contentItem, defaultSelectedProductId }) => {
      return {
        variables: {
          contentItemId: contentItem.id,
          productId: defaultSelectedProductId
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'cache-and-network'
      }
    },
    props: ({
      ownProps,
      rentalPaymentViewQuery: {
        loading: rentalPaymentViewLoading,
        error: rentalPaymentViewError,
        rentalPaymentView,
        refetch: refetchRentalPaymentView,
        variables: lastSetVariables,
        networkStatus
      }
    }) => {
      const { productId: selectedProductId } = lastSetVariables
      const { contentItem } = ownProps
      const selectedProduct = find(propEq('id', selectedProductId), contentItem.products)
      const { priceText, promotionalPrice } = (rentalPaymentView || {})
      return {
        rentalPaymentViewLoading,
        rentalPaymentViewError,
        rentalPaymentView,
        refetchRentalPaymentView,
        selectedProduct,
        isInitialLoading: networkStatus === 1,
        shouldShowPriceText: Boolean(
          priceText
        ),
        hasPromoPrice: Boolean(promotionalPrice && promotionalPrice !== '0'),
        ...ownProps
      }
    }
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps({ rentalPaymentViewError, triggerErrorDialog, onRequestClose }) {
      if (rentalPaymentViewError) {
        const errorCode = getGQLErrorCode(rentalPaymentViewError)
        if (errorCode !== MW_ERRORS.INTERNAL_SERVER_ERROR) {
          const errorObj = getGQLError(rentalPaymentViewError)
          triggerErrorDialog(errorObj)
          onRequestClose()
        }
      }
    },
    componentDidMount() {
      // Add segment data analytics for initiating rental payment
      segmentTrackInitiatePurchase(null, this.props.contentItem)
    }
  }),
  branch(
    ({
      rentalPaymentViewLoading,
      rentalPaymentViewError,
      isInitialLoading
    }) => {
      return (
        // Don't unmount/remount after payment view is
        // refetched with promotions array provided to us by EV iframe
        (rentalPaymentViewLoading && isInitialLoading) ||
        rentalPaymentViewError
      )
    },
    renderComponent(LoadingModal)
  ),
  // the iframe url should only update after switching quality tabs
  withState('iframe', 'updateIframe',
    ({ rentalPaymentView }) => pathOr('', ['iframe'], rentalPaymentView)),
  withProps(({ rentalPaymentView }) => {
    return {
      header: pathOr('', ['header'], rentalPaymentView),
      image: pathOr('', ['image', 'uri'], rentalPaymentView),
      notes: pathOr([], ['notes'], rentalPaymentView),
      priceText: pathOr('', ['priceText'], rentalPaymentView),
      price: pathOr('', ['price'], rentalPaymentView),
      promotionalPrice: pathOr('', ['promotionalPrice'], rentalPaymentView)
    }
  }),
  withHandlers({
    onIframeLoad: ({
      onPaymentSubmission,
      contentItem,
      selectedProduct,
      dataLayerPurchaseFunction = dataLayerPurchase
    }) => (iframeQueryParams) => {
      if (isEmpty(iframeQueryParams)) return

      if (propEq('status', 'success', iframeQueryParams)) {
        dataLayerPurchaseFunction(
          contentItem,
          selectedProduct.currentPrice,
          selectedProduct.name,
          iframeQueryParams.orderId
        )
        onPaymentSubmission(iframeQueryParams.orderId)
      }
    },
    onCancel: ({ history, location, onRequestClose }) => () => {
      onRequestClose()
    },
    onIframePostMessage: ({
      refetchRentalPaymentView
    }) => (data) => {
      // {"type":"promotion","promotions":[],"discountAmount":0,"price":5.99}
      if (!data || isEmpty(data) || data.type !== 'promotion') {
        return
      }

      // Refetch the payment view with the promotions array provided by EV
      const { promotions = [] } = data
      refetchRentalPaymentView({ promotions })
    },
    setSelectedProduct: ({
      refetchRentalPaymentView,
      updateIframe
    }) => (product) => {
      refetchRentalPaymentView({
        productId: product.id
      })
        .then(({ data, errors }) => {
          if (errors) {
            return
          }

          const iframe = pathOr('', ['rentalPaymentView', 'iframe'], data)
          updateIframe(iframe)
        })
    }
  })
)

export default enhance(PaymentView)
