import { graphql } from 'react-apollo'
import SUBSCRIBE_MUTATION from '../../../../../../graphql/mutations/subscribe.gql'

export const withSubscriptionMutation = graphql(SUBSCRIBE_MUTATION, {
  props: handleProps
})

function handleProps({
  ownProps: {
    renderStep,
    payload: { voucher }
  },
  mutate: subscribeToPlan
}) {
  return {
    subscribeToPlan,
    onSubscriptionSelection: productId => {
      return renderStep(3, {
        voucher,
        subscriptionId: productId,
        addSubscriptionFlow: true
      })
    }
  }
}

export const subscribeAndStepToConfirmation = ({
  hasDefaultSVODCard,
  startSubscribing,
  subscribeToPlan,
  productId,
  voucher,
  renderStep
}) => {
  const { id: paymentMethodId } = hasDefaultSVODCard
  displayLoadingIndicator(startSubscribing)
  return subscribeToPlan({
    variables: { productId, voucher, paymentMethodId }
  })
    .then(response => {
      if (!response.errors) {
        renderConfirmationViewWithOrderID({ response, renderStep, voucher })
      } else {
        renderStep(1)
      }
    })
    .catch(() => {
      renderStep(3, {
        voucher,
        subscriptionId: productId,
        addSubscriptionFlow: true
      })
    })
}

export const displayLoadingIndicator = startSubscribing => {
  startSubscribing(true)
}

export const renderConfirmationViewWithOrderID = ({
  response,
  renderStep,
  voucher
}) => {
  const {
    data: { subscribe: orderId }
  } = response
  renderStep(4, { orderId, voucher })
}
