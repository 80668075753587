import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import SubscriptionCard from './subscription-card'
import Button from '../../forms/neon/button'

import { subscriptionGa } from '../../../lib/analytics/ga'

import SUBSCRIPTION_VIEW_FRAGMENT from '../../../../graphql/fragments/subscription-view.gql'

import styles from './subscription.css'

function Subscription({
  subscription,
  onSubscriptionSelection,
  buttonStyle
}) {
  return (
    <SubscriptionCard>
      <div
        className={styles.priceText}
      >
        <span className={styles.currency}>$</span>
        13.95
      </div>
      <div className={styles.frequency}>per month</div>
      <div className={styles.features}>
        <div className={styles.featuresHeading}>Your subscription includes</div>
        <ul>
          <li>Hit shows and blockbuster movies</li>
          <li>Binge watch with no ads</li>
          <li>Download &amp; Go selected content</li>
          <li>Watch on two screens at once</li>
        </ul>
      </div>
      <div className={styles.ctaWrapper}>
        <Button
          className={buttonStyle || styles.cta}
          text="Continue"
          onClick={() => {
            onSubscriptionSelection(subscription.id)
            subscriptionGa({ action: 'Choose plan', label: 'premiumNeon' })
          }}
        />
      </div>
    </SubscriptionCard>
  )
}

Subscription.propTypes = {
  subscription: propType(SUBSCRIPTION_VIEW_FRAGMENT).isRequired,
  onSubscriptionSelection: PropTypes.func,
  buttonStyle: PropTypes.string
}

Subscription.defaultProps = {
  onSubscriptionSelection: null,
  buttonStyle: null
}

export default Subscription
