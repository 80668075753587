import { compose, mapProps, setDisplayName } from 'recompose'

import VoucherValidateErrorModal from '../../../../../components/modals/voucher-validate-error'

export const withMapProps = mapProps(({ payload: { error, type, renderStep } }) => ({
  error,
  type,
  renderStep
}))

// Need to pull the orderId out and into the confirmation container
const enhance = compose(
  setDisplayName('StepZeroVoucherValidateError'),
  withMapProps
)

export default enhance(VoucherValidateErrorModal)
