import {
  path, isEmpty, filter, isNil, either
} from 'ramda'
import {
  dataLayerSubscriptionConfirmation,
  dataLayerNoSubscriptionConfirmation
} from './datalayer'

export const datalayerSubscriptionConfirmationHandler = (
  account,
  voucherCode
) => {
  const subscriptionInfo = {
    vpv: 'vpv_successful_subscription',
    value: getPrice(account),
    planType: getSubscriptionName(account),
    voucherCode,
    svodUserType: isEmpty(voucherCode) ? 'non-vouchered' : 'vouchered',
    planPaymentMethod: getDefaultSvodPayment(account),
    subscriptionStartDate: getSubscriptionStartDate(account),
    subscriptionEndDate: getSubscriptionEndDate(account),
    userSubscriptionCount: getUserSubscriptionCount(account)
  }

  dataLayerSubscriptionConfirmation(subscriptionInfo)
}

export const getPrice = account => path(['account', 'subscription', 'currentSubscription', 'price'], account)
export const getSubscriptionName = account => path(['account', 'subscription', 'currentSubscription', 'name'], account)
export const getSubscriptionStartDate = account => path(['account', 'subscription', 'currentSubscription', 'startsAt'], account)
export const getSubscriptionEndDate = account => path(['account', 'subscription', 'currentSubscription', 'endsAt'], account)
export const getUserSubscriptionCount = account => path(
  ['account', 'subscription', 'currentSubscription', 'subscriptionGAType'],
  account
)

const isEmptyNil = either(isEmpty, isNil)

export const getDefaultSvodPayment = account => {
  const creditcards = path(['account', 'creditcards'], account)

  if (isEmptyNil(creditcards)) return null

  const defaultSvodPayments = filter(
    creditcard => creditcard.useForSubscriptions,
    creditcards
  )

  if (!isEmptyNil(defaultSvodPayments)) {
    return defaultSvodPayments[0].type
  }
  return null
}

export const datalayerNonSubscriptionConfirmationHandler = () => {
  dataLayerNoSubscriptionConfirmation({})
}
