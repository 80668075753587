import React from 'react'

import PinResetForm from '../../containers/pin/pin-reset-form'

import styles from './reset.css'

function ResetPin({ ...props }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>Reset Your PIN</h1>
        <span className={styles.subHeader}>
          Please enter your new PIN below.
        </span>
      </div>
      <PinResetForm
        {...props}
      />
    </div>
  )
}

export default ResetPin
