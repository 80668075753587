import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import classNames from 'classnames'
import qs from 'query-string'

import { useStyles } from './add-payment-cancel-modal-content.styles'

const AddPaymentCancelModalContent = () => {
  const styles = useStyles()
  const history = useHistory()
  const location = useLocation()
  const gotoHomepage = () => {
    history.push('/')
  }
  const { plan } = qs.parse(location.search)
  const onClose = () => {
    history.replace({
      ...location,
      search: qs.stringify({
        plan
      })
    })
  }

  return (
    <div className={styles.cancelModal}>
      <div className={styles.title}>Are you sure you want to cancel?</div>
      <div className={styles.content}>
        We will direct you to home page, and current payment details will not be saved
      </div>
      <div className={styles.buttonContainer}>
        <div
          className={classNames(styles.primaryButton, styles.button)}
          onClick={() => onClose()}
        >
          Continue
        </div>
        <div
          className={classNames(styles.outlineButton, styles.button)}
          onClick={gotoHomepage}
        >
          Cancel
        </div>
      </div>
    </div>
  )
}

AddPaymentCancelModalContent.propTypes = {}

export default AddPaymentCancelModalContent
