import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { T } from 'ramda'

import { useStyles } from './voucher-validate-error.styles'
import exclamationWhiteSmall from '../../../../images/exclamation-white-small.svg'
import { useVoucherValidateError } from '../../../modules/add-subscription-modal/hooks/use-voucher-validate-error'

import {
  VOUCHER_VALIDATE_AFTER_LOGIN_WITH_PLAN
} from '../../../constants'

const VoucherValidateErrorModal = ({ error, type, renderStep }) => {
  const styles = useStyles()
  const { changePlanHandler, skipVoucherHandler } = useVoucherValidateError(type)

  return (
    <div className={styles.voucherContainer}>
      <div className={styles.iconFrame}>
        <img
          src={exclamationWhiteSmall}
          alt="exclamation"
        />
      </div>

      <div className={styles.title}>
        This voucher isn&apos;t valid for this Plan
      </div>

      <p className={styles.errorMessage}>
        {error}
      </p>

      <div className={styles.buttonContainer}>
        {
          type === VOUCHER_VALIDATE_AFTER_LOGIN_WITH_PLAN ? (
            <button
              onClick={() => renderStep(1)}
              className={classNames(styles.primaryButton, styles.buttons)}
            >
              Go Back
            </button>
          ) : (
            <>
              <button
                onClick={changePlanHandler}
                className={classNames(styles.primaryButton, styles.buttons)}
              >
                Change Plan
              </button>
              <button
                onClick={skipVoucherHandler}
                className={classNames(styles.secondaryButton, styles.buttons)}
              >
                Skip
              </button>
            </>
          )
        }
      </div>
    </div>
  )
}

VoucherValidateErrorModal.propTypes = {
  error: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  renderStep: PropTypes.func
}

VoucherValidateErrorModal.defaultProps = {
  error: '',
  type: '',
  renderStep: T
}

export default VoucherValidateErrorModal
