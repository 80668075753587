
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SubscriptionViewFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SubscriptionView"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"price"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"currency"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"period"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"priceText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"priceNote"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"highlighted"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"features"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"SubscriptionFeatureViewFields"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"cta"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"isActive"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":259}};
    doc.loc.source = {"body":"#import \"../fragments/subscription-feature-view.gql\"\n\nfragment SubscriptionViewFields on SubscriptionView {\n  id\n  name\n  price\n  currency\n  period\n  priceText\n  priceNote\n  highlighted\n  features {\n    ...SubscriptionFeatureViewFields\n  }\n  cta\n  isActive\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("../fragments/subscription-feature-view.gql").definitions));


      module.exports = doc;
    
