import React from 'react'

import { ADD_VOUCHER_SUCCESSFUL_MESSAGES } from '../../../../constants'
import { useStyles } from './voucher-messages.styles'

/**
 * Renders successful voucher messages
 * in a card.
 */
const VoucherMessages = () => {
  const styles = useStyles()

  return (
    <>
      <div className={styles.copyMessage}>
        {ADD_VOUCHER_SUCCESSFUL_MESSAGES.successMsg}
      </div>
      <div className={styles.copy}>
        {ADD_VOUCHER_SUCCESSFUL_MESSAGES.confirmMsg}
      </div>
    </>
  )
}

export default VoucherMessages
