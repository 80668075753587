import React from 'react'
import { propType } from 'graphql-anywhere'
import classnames from 'classnames'
import { sanitizeDom } from '../../../utils'

import SUBSCRIPTION_FEATURE_VIEW_FRAGMENT from '../../../../graphql/fragments/subscription-feature-view.gql'

import styles from './subscription-feature.css'

function SubscriptionFeature({ feature }) {
  return (
    <div
      className={classnames(styles.subscriptionFeature, {
        [styles.subscriptionFeatureDisabled]: !feature.active
      })}
    >
      <div className={styles.subscriptionImg}>
        <img
          alt="feature-icon"
          src={feature.image}
          className={classnames({ [styles.isNotActive]: !feature.active })}
        />
      </div>
      <div
        className={styles.subscriptionDescription}
      >
        <span
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: sanitizeDom(feature.description) }}
        />
        {
          feature.tooltip && (
            <div className={styles.tooltipWrapper}>
              {feature.tooltip}
            </div>
          )
        }
      </div>
    </div>
  )
}

SubscriptionFeature.propTypes = {
  feature: propType(SUBSCRIPTION_FEATURE_VIEW_FRAGMENT).isRequired
}

export default SubscriptionFeature
