import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  standardScreenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'nowrap',
    alignSelf: 'center',
    [theme.media.smallMobile]: {
      display: 'none'
    },
    [theme.media.mobile]: {
      display: 'none'
    },
    [theme.media.tablet]: {
      display: 'none'
    },
    [theme.media.desktop]: {
      width: '100%'
    },
    [theme.media.largeDesktop]: {
      width: 1120
    },
    margin: [20, 0, 0, 0]
  },

  tableRowContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    gap: '10px',
    [theme.media.desktop]: {
    },
    width: '100%',
    marginBottom: -10
  },

  tableRowTitleFrame: {
    flex: 2,
    paddingTop: 50
  },

  tableRowTitle: {
    flex: 2,
    minHeight: 60,
    // paddingLeft: 30,
    display: 'flex',
    alignItems: 'center',
    fontSize: 22
  },

  tableRowContent: {
    display: 'flex',
    flexDirection: 'row',
    flex: 3,
    alignItems: 'flex-start',
    gap: '10px'
  },

  divider: {
    height: 1,
    margin: [15, 0, 15],
    backgroundColor: theme.field,
    width: '100%'
  },

  activeSubscription: {
    color: theme.neonGreen
  }
}))

export default useStyles
