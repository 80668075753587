import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import Subscription from '../subscription'

import SUBSCRIPTION_VIEW_FRAGMENT from '../../../../../graphql/fragments/subscription-view.gql'

import styles from '../subscription-selection.css'

/**
 * Per https://bcconsulting.atlassian.net/browse/LBXW-1207,
 * this copy is supposed to be slightly different than what appears
 * in the normal signup flow, but it's controlled via the backend.
 * TODO: Perhaps figure out why or how to address this.
 */
function Step2({
  header,
  subheader,
  subscriptions,
  onSubscriptionSelection,
  validCreditCard
}) {
  const onSubscriptionSelectionWithPayment = (subscriptionId) => {
    if (validCreditCard) {
      return onSubscriptionSelection(subscriptionId, validCreditCard.id)
    }

    return onSubscriptionSelection(subscriptionId)
  }
  const [subscription] = subscriptions.filter(sub => sub.name === 'Premium')
  return (
    <div className={styles.wrapper}>
      <h2>Add your subscription</h2>
      <div className={styles.subscriptionsList}>
        <Subscription
          subscription={subscription}
          onSubscriptionSelection={onSubscriptionSelectionWithPayment}
        />
      </div>
    </div>
  )
}

Step2.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  subscriptions: PropTypes.arrayOf(
    propType(SUBSCRIPTION_VIEW_FRAGMENT)
  ).isRequired,
  onSubscriptionSelection: PropTypes.func.isRequired,
  validCreditCard: PropTypes.shape({
    id: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    useForSubscriptions: PropTypes.bool.isRequired
  })
}

Step2.defaultProps = {
  validCreditCard: null
}

export default Step2
