import React from 'react'
import { graphql } from 'react-apollo'
import { Redirect } from 'react-router-dom'
import { compose, branch, renderComponent } from 'recompose'

import ProfilesAdd from '../../components/profiles/profiles-add'
import withIsAuthenticatedAndRedirect from '../../hoc/is-authenticated-redirect'
import requirePin from '../../hoc/require-pin'
import { requirePinForManageProfile } from '../../lib/account'
import { getModalLocation } from '../../lib/modal'
import { PROFILES, MODALS } from '../../constants'
import { LoadingModal } from '../../components/loading/modal'

import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'

function RedirectToProfileManagement({ location }) {
  return (
    <Redirect
      to={getModalLocation(location, MODALS.qsParams.profilesManagement)}
    />
  )
}

const enhance = compose(
  withIsAuthenticatedAndRedirect,
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated
  }),
  branch(
    ({ accountQuery }) => accountQuery && (accountQuery.loading || accountQuery.error),
    renderComponent(LoadingModal)
  ),
  branch(
    ({ accountQuery }) => accountQuery &&
      accountQuery.account.profiles.length === PROFILES.maxNumber,
    renderComponent(RedirectToProfileManagement)
  ),
  branch(
    ({ accountQuery }) => requirePinForManageProfile(accountQuery.account),
    requirePin
  )
)

export default enhance(ProfilesAdd)
