import React from 'react'
import PropTypes from 'prop-types'

import { RENTAL_STATUS } from '../../constants'

import styles from './rental-info.css'

function RentalInfo({
  rentalInfo
}) {
  return (
    <div className={styles.rentalInfoWrapper}>
      <span className={styles.status}>{RENTAL_STATUS.RENTED}</span>
      <span className={styles.timeLeft}>
        {rentalInfo.canWatchUntilText}
      </span>
    </div>
  )
}

RentalInfo.propTypes = {
  rentalInfo: PropTypes.shape({
    canWatchUntilText: PropTypes.string
  }).isRequired
}

export default RentalInfo
