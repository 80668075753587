import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'

import Input from '../forms/input'
import NeonButton from './neon-form-elements/button'
import styles from './request-form.css'

import { E2E_SELECTORS } from '../../e2e-selectors'

function PasswordForgottenForm({
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  error,
  // touched,
  isSubmitting,
  onCancel
}) {
  const isSubmittingClass = isSubmitting ? styles.isSubmitting : ''
  const title = `Reset password - NEON`

  return (
    <form
      className={`${styles.form} ${isSubmittingClass}`}
      onSubmit={handleSubmit}
    >
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <div className={styles.formRow}>
        <div className={styles.formRowItem}>
          <Input
            label="Email"
            name="email"
            labelClass={styles.label}
            inputClass={styles.input}
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            warning={
              (values.email && errors.email) ||
              (error && values.email === error.email && error.errorMessage)
            }
            isValid={!error && Boolean(values.email && !errors.email)}
          />
        </div>
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.saveWrapper}>
          <NeonButton
            disabled={
              isSubmitting ||
              !!(error && values.email === error.email && error.errorMessage)
            }
            e2eIdentifier={E2E_SELECTORS.RESET_SUBMIT_BUTTON}
            text="Reset"
          />
        </div>
        <div className={styles.cancelWrapper}>
          <NeonButton
            disabled={isSubmitting}
            text="Cancel"
            onClick={() => { onCancel() }}
            secondary
          />
        </div>
      </div>
    </form>
  )
}

PasswordForgottenForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  // touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

PasswordForgottenForm.defaultProps = {
  error: ''
}

export default PasswordForgottenForm
