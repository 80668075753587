import qs from 'query-string'
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { useLazyQuery } from '@apollo/react-hooks'
import { path, pathEq } from 'ramda'

import { MODALS, SUBSCRIPTION_STATUS } from '../../../constants'
import { firstPaymentLabel, firstPaymentDueLabel } from '../../change-subscription/constants'
import { modalContent, planType } from '../../shared/subscription-constants'
import SUBSCRIPTION_CONFIRMATION_VIEW_QUERY
  from '../../../../graphql/queries/subscription-confirmation-view.gql'
import { getModalLocation } from '../../../lib/modal'
import { getGQLErrorCode } from '../../../lib/apollo'
import SUBSCRIPTION_AND_CREDIT_CARDS from './graphql/subscription-and-creditcards.gql'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'
import { segmentTrackAddSubscription, segmentTrackCompletePurchase } from '../../../segment/segment-track'

/**
 * Responsible for fetching and providing data to the
 * subscription confirmation screen.
 */
export const useSubscriptionModalConfirmation = () => {
  const [loading, setLoading] = useState(true)
  const [details, setDetails] = useState([])
  const [hasActiveSubscription, setActiveSubscription] = useState(false)

  const history = useHistory()
  const location = useLocation()
  const getPlanDetails = usePlanChangeFlag()

  const {
    voucher,
    orderId,
    plan
  } = qs.parse(location.search)
  const isAnnuallyPlan = plan === planType.ANNUAL
  const isStandardPlan = plan === planType.STANDARD
  const isBasicPlan = plan === planType.BASIC

  const [updateAccount, { loading: accountLoading, error: accountError, data: accountData }] = useLazyQuery(SUBSCRIPTION_AND_CREDIT_CARDS, {
    fetchPolicy: 'network-only'
  })

  useEffect(() => {
    if (accountData) {
      const currentSubscription = pathEq(
        ['account', 'subscription', 'status'],
        SUBSCRIPTION_STATUS.ACTIVE
      )(accountData)
      setActiveSubscription(currentSubscription)

      // Add segment data analytics for adding subscription
      const {
        name, sku
      } = accountData?.account?.subscription?.currentSubscription
      segmentTrackAddSubscription({
        name,
        sku
      })
    }
    if (accountError) {
      pushToError(getGQLErrorCode(accountError))
    }
  }, [accountData, accountError, accountLoading])

  /**
   * Query for get subscription details
   */
  const [getSubscriptionConfirmation, { loading: confirmationLoading, data: confirmationData, error: confirmationError }] = useLazyQuery(SUBSCRIPTION_CONFIRMATION_VIEW_QUERY, {
    variables: {
      orderId,
      paymentMethodId: ''
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  })

  useEffect(() => {
    setLoading(confirmationLoading)

    if (confirmationData) {
      const confirmationDetails = path(
        ['subscriptionConfirmationView', 'details'],
        confirmationData
      )

      let staticDetail = null
      if (isAnnuallyPlan) {
        staticDetail = getPlanDetails?.annual.staticDetail
      } else if (isStandardPlan) {
        staticDetail = getPlanDetails?.standard.staticDetail
      } else if (isBasicPlan) {
        staticDetail = getPlanDetails?.basic.staticDetail
      }

      setDetails([staticDetail, ...confirmationDetails])

      // Add segment data analytics for completing purchase
      const paymentIndex = confirmationDetails?.findIndex(e => e.name === firstPaymentLabel)
      const paymentDueIndex = confirmationDetails?.findIndex(e => e.name === firstPaymentDueLabel)
      if (paymentIndex >= 0 && paymentDueIndex >= 0) {
        segmentTrackCompletePurchase({
          planTitle: plan,
          paymentDue: confirmationDetails[paymentDueIndex]?.value,
          payment: Number(confirmationDetails[paymentIndex]?.value?.replace('$', '')),
          voucher,
          orderId
        })
      }
    }

    if (confirmationError) {
      pushToError(getGQLErrorCode(confirmationError))
    }
  }, [confirmationData, confirmationError, confirmationLoading])

  /**
   * Change modal content to error content
   * @param errorCode
   */
  const pushToError = (errorCode) => {
    const errorContent = getModalLocation(location, MODALS.qsParams.addSubscription, {
      content: modalContent.error,
      error: errorCode
    })
    history.push(errorContent)
  }

  useEffect(() => {
    if (orderId) {
      getSubscriptionConfirmation()
    }
  }, [orderId])

  useEffect(() => {
    updateAccount()
  }, [])

  return {
    loading,
    accountLoading,
    details,
    orderId,
    hasVoucher: Boolean(voucher),
    hasActiveSubscription
  }
}
