
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"SubscriptionSuspendedViewFields"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"SubscriptionSuspendedView"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"header"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"subHeader"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"resumeCta"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"cancelCta"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"voucherText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"voucherLinkText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"paymentText"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"paymentLinkText"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":183}};
    doc.loc.source = {"body":"fragment SubscriptionSuspendedViewFields on SubscriptionSuspendedView {\n  header\n  subHeader\n  resumeCta\n  cancelCta\n  voucherText\n  voucherLinkText\n  paymentText\n  paymentLinkText\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  

      module.exports = doc;
    
