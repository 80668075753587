import React from 'react'
import PropTypes from 'prop-types'

// import Step1 from '../../../../containers/subscriptions/neon/welcome-back/step-one'
import Step1New from './step-one-new'
import Step2 from '../../../../containers/subscriptions/neon/welcome-back/step-two'
import Step3 from '../../../../containers/subscriptions/neon/welcome-back/step-three'
import SubscriptionConfirmation from '../../../../containers/subscriptions/neon/subscription-confirmation'
import styles from '../../../signup/neon/signup-subscriptions.css'

/**
 * repurpose the welcomeBack modal(since it's not being used) to display a pop-up for unsubscribed user after login
 * Only use Step1 to display the button to navigate user to the price table
 */
const WelcomeBack = (props) => {
  const { step } = props
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1New {...props} />
        )
      case 2:
        return (
          <Step2 {...props} />
        )
      case 3:
        return (
          <Step3 {...props} />
        )
      case 4:
        return (
          <SubscriptionConfirmation {...props} />
        )
      default:
        return null
    }
  }
  return (
    <div className={styles.wrapper}>
      {renderStep(props)}
    </div>
  )
}

WelcomeBack.propTypes = {
  step: PropTypes.number
}

WelcomeBack.defaultProps = {
  step: 1,
  error: ''
}

export default WelcomeBack
