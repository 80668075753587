
import React from 'react'
import PropTypes from 'prop-types'

import EvIframe from '../../../../containers/shared/evergent-iframe'

import styles from './step-three.css'

function SubscriptionPayment({
  header,
  subheader,
  iframe,
  onIframeLoad,
  continueCta,
  proceedToConfirmation
}) {
  return (
    <div className={styles.wrapper}>
      <h2>
        Add payment details
        <p>
          Please provide your credit card details below to complete your account set up.
        </p>
      </h2>
      <div className={styles.content}>
        <div className={styles.creditCard}>
          <EvIframe
            src={iframe}
            onLoad={onIframeLoad}
          />
          {
            continueCta && (
              <div
                data-lbx-e2e="signup-continue-without-payment"
                className={styles.cta}
                onClick={proceedToConfirmation}
              >
                <p>{continueCta}</p>
              </div>
            )
          }
        </div>
      </div>
    </div>
  )
}

SubscriptionPayment.propTypes = {
  header: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  iframe: PropTypes.string.isRequired,
  onIframeLoad: PropTypes.func.isRequired,
  continueCta: PropTypes.string,
  proceedToConfirmation: PropTypes.func.isRequired
}

SubscriptionPayment.defaultProps = {
  continueCta: ''
}

export default SubscriptionPayment
