import { graphql } from 'react-apollo'
import { path, isEmpty, propEq } from 'ramda'
import SUBSCRIPTION_REDEEM_VOUCHER_VIEW from '../../../../../../graphql/queries/subscription-redeem-voucher-view.gql'

const findOrderId = path(['subscriptionRedeemVoucherView', 'orderId'])

export const withRedeemVoucherViewQuery = graphql(
  SUBSCRIPTION_REDEEM_VOUCHER_VIEW,
  {
    name: 'redeemVoucherViewQuery',
    options: ({ payload: { voucher } }) => ({
      variables: {
        voucher,
        warn: true
      },
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    }),
    props: handleProps
  }
)

function handleProps({
  ownProps: {
    renderStep,
    payload: { voucher }
  },
  redeemVoucherViewQuery: {
    loading: redeemVoucherViewLoading,
    error: redeemVoucherViewError,
    subscriptionRedeemVoucherView,
    refetch: refetchRedeemVoucherView
  }
}) {
  return {
    redeemThisVoucher: () => {
      refetchRedeemVoucherView({
        voucher,
        warn: false
      })
        // One annoying caveat with apollo,d
        // refetching a query loses it's original alias
        .then(({ data }) => {
          goToStep4IfOrderExists({ data, renderStep, voucher })
        })
        .catch(() => {
          // If the API call errors, send them back to step 1.
          renderStep(1)
        })
    },
    onCancelCta: () => {
      renderStep(1)
    },
    onIframeLoad: iframeQueryParams => {
      if (isEmpty(iframeQueryParams)) return

      if (propEq('status', 'success', iframeQueryParams)) {
        const orderIdFromCreditCardSaved = path(['orderId'], iframeQueryParams)
        renderStep(4, { orderIdFromCreditCardSaved, voucher, hasPlan: true })
      }
    },
    redeemVoucherViewLoading,
    redeemVoucherViewError,
    ...(subscriptionRedeemVoucherView || {})
  }
}

const goToStep4IfOrderExists = ({ data, renderStep, voucher }) => {
  const orderId = findOrderId(data)
  if (orderId) {
    renderStep(4, { orderId, voucher, hasPlan: true })
  }
}
