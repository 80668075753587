import AddVoucher from '../../../containers/modals/add-voucher/neon/index'
import VoucherValidateErrorForSignup from '../../../containers/modals/voucher-validate-error-for-signup'
import VoucherValidateErrorModalAfterLogin from '../../../containers/modals/voucher-validate-error-after-login'
import Login from '../../../containers/login'
import SubscriptionCancel from '../../../containers/subscriptions/neon/cancel'
import SignupSubscriptionRental from '../../../containers/subscriptions/signup-subscription-rental'
import SubscriptionRepair from '../../../containers/subscriptions/neon/subscription-repair'
import SubscriptionResume from '../../../containers/subscriptions/neon/subscription-resume'
import SubscriptionOffer from '../../../containers/subscriptions/neon/subscription-offer'
import SubscriptionVoucherWarn from '../../../containers/subscriptions/neon/subscription-voucher-warn'
import LoginRental from '../../../containers/login/login-rental'
import ProfilesManage from '../../../containers/profiles/profiles-manage'
import ProfilesAdd from '../../../containers/profiles/profiles-add'
import ProfilesEdit from '../../../containers/profiles/profiles-edit'
import ProfilesSwitch from '../../../containers/profiles/profiles-switch'
import PasswordResetRequest from '../../../containers/password-reset/password-reset-request'
import PasswordReset from '../../../containers/password-reset/password-reset'
import PinResetRequest from '../../../containers/pin/pin-reset-request'
import UpdatePaymentDetails from '../../../containers/modals/update-payment-details'
import WelcomeBack from '../../../containers/subscriptions/neon/welcome-back'
import { MODALS, THEME_OPTIONS } from '../../../constants'
import AddSubscriptionContents from '../../../modules/add-subscription-modal/add-subscription-contents'
import AddPaymentCancelModalContent from '../../../modules/shared/add-payment-cancel-modal-content/add-payment-cancel-modal-content'
import {
  UpgradeSubscriptionModal,
  DowngradeSubscriptionModal
} from '../../../modules/change-subscription'
import UserExistsModalContent
  from '../../../modules/signup/components/user-exists-modal/user-exists-modal-content'

const modalsParams = MODALS.qsParams

// List of modals to be rendered
const modals = {
  // auth modals
  [modalsParams.addVoucher]: AddVoucher,
  [modalsParams.voucherValidateErrorForSignup]: VoucherValidateErrorForSignup,
  [modalsParams.voucherValidateErrorAfterLogin]: VoucherValidateErrorModalAfterLogin,
  [modalsParams.addSubscription]: AddSubscriptionContents,
  [modalsParams.upgradeSubscription]: UpgradeSubscriptionModal,
  [modalsParams.downgradeSubscription]: DowngradeSubscriptionModal,
  [modalsParams.login]: Login,
  // REMOVE ME - remove ALL old sign up models
  [modalsParams.subscriptionCancel]: SubscriptionCancel,
  [modalsParams.subscriptionRepair]: SubscriptionRepair,
  [modalsParams.subscriptionResume]: SubscriptionResume,
  [modalsParams.subscriptionSelectionUpsell]: SignupSubscriptionRental,
  [modalsParams.subscriptionOffer]: SubscriptionOffer,
  [modalsParams.subscriptionVoucherWarn]: SubscriptionVoucherWarn,
  [modalsParams.loginRental]: LoginRental,
  [modalsParams.updatePaymentDetails]: UpdatePaymentDetails,
  [modalsParams.welcomeBack]: WelcomeBack,
  [modalsParams.signupTVODCancelAddPayment]: AddPaymentCancelModalContent,
  // profiles modals
  [modalsParams.profilesManagement]: ProfilesManage,
  [modalsParams.profilesAdd]: ProfilesAdd,
  [modalsParams.profilesEdit]: ProfilesEdit,
  [modalsParams.profilesSwitch]: ProfilesSwitch,
  // password modals
  [modalsParams.passwordForgotten]: PasswordResetRequest,
  [modalsParams.passwordReset]: PasswordReset,
  // pin modals
  [modalsParams.pinForgotten]: PinResetRequest,
  // user exists
  [modalsParams.userExists]: UserExistsModalContent
}

const modalTheme = {
  [modalsParams.login]: THEME_OPTIONS.dark,
  [modalsParams.passwordForgotten]: THEME_OPTIONS.dark,
  [modalsParams.passwordReset]: THEME_OPTIONS.dark,
  [modalsParams.loginRental]: THEME_OPTIONS.dark,
  [modalsParams.signup]: THEME_OPTIONS.dark,
  [modalsParams.subscription]: THEME_OPTIONS.dark,
  [modalsParams.subscriptionCancel]: THEME_OPTIONS.dark,
  [modalsParams.subscriptionRepair]: THEME_OPTIONS.dark,
  [modalsParams.subscriptionResume]: THEME_OPTIONS.dark,
  [modalsParams.subscriptionSelection]: THEME_OPTIONS.dark,
  [modalsParams.subscriptionSelectionUpsell]: THEME_OPTIONS.dark,
  [modalsParams.subscriptionOffer]: THEME_OPTIONS.dark,
  [modalsParams.subscriptionVoucherWarn]: THEME_OPTIONS.dark,
  [modalsParams.upgradeSubscription]: THEME_OPTIONS.dark,
  [modalsParams.downgradeSubscription]: THEME_OPTIONS.dark,
  [modalsParams.addSubscription]: THEME_OPTIONS.dark,
  [modalsParams.addVoucher]: THEME_OPTIONS.dark,
  [modalsParams.voucherValidateErrorForSignup]: THEME_OPTIONS.dark,
  [modalsParams.voucherValidateErrorAfterLogin]: THEME_OPTIONS.dark,
  [modalsParams.welcomeBack]: THEME_OPTIONS.dark,
  [modalsParams.signupTVODCancelAddPayment]: THEME_OPTIONS.dark,
  [modalsParams.userExists]: THEME_OPTIONS.dark,
  [modalsParams.updatePaymentDetails]: THEME_OPTIONS.dark
}

function getModalComponent(modal) {
  return modals[modal]
}

function getModalTheme(modal) {
  return modalTheme[modal] || THEME_OPTIONS.light
}

const MODALS_NOT_SHOW_AGAIN_NAVIGATE_BACK = [modalsParams.loginRental]

export { getModalComponent, getModalTheme, MODALS_NOT_SHOW_AGAIN_NAVIGATE_BACK }
