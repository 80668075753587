import { mapProps } from 'recompose'
import {
  prop, pipe, find, whereEq
} from 'ramda'

import Step1 from './step-one'
import Step2 from './step-two'
import Step3 from './step-three'
import Step4 from './step-four'
import Step0 from './step-zero'

const steps = [
  {
    step: 1,
    component: Step1
  },
  {
    step: 2,
    component: Step2
  },
  {
    step: 3,
    component: Step3
  },
  {
    step: 4,
    component: Step4
  },
  {
    step: 0,
    component: Step0
  }
]

const getStepComponent = step => pipe(
  find(whereEq({ step })),
  prop('component')
)(steps)

export const withAddVoucherStepProps = mapProps(
  ({
    step, renderStep, payload, onRequestClose, setOnCloseHandler
  }) => {
    const stepComponent = getStepComponent(step)
    return {
      stepComponent,
      renderStep,
      payload,
      onRequestClose,
      setOnCloseHandler
    }
  }
)
