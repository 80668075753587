import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  voucherContainer: {
    maxWidth: 550,
    paddingTop: 40,
    [theme.media.smallMobile]: {
      padding: 40
    },
    [theme.media.mobile]: {
      padding: 40
    }
  },

  inputError: {
    borderColor: theme.error,

    '&:focus': {
      borderColor: theme.error
    }
  },

  title: {
    fontSize: 30,
    fontWeight: 350,
    lineHeight: '36px',
    textAlign: 'center',
    marginBottom: 30,
    [theme.media.smallMobile]: {
      fontSize: 26
    },
    [theme.media.mobile]: {
      fontSize: 26
    }
  },

  fieldError: {
    visibility: 'hidden',
    display: 'inline-block',
    color: theme.error,
    fontSize: 10,
    margin: [2, 0, 0]
  },

  visible: {
    visibility: 'visible'
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.media.desktop]: {
      width: 550
    }
  },

  buttons: {
    width: '48%',
    [theme.media.mobile]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },

  secondaryButton: {
    display: 'block',
    fontSize: 18,
    padding: [10, 0],
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: 350,
    color: theme.black,
    background: theme.white,
    border: `1px solid transparent`,
    outline: 'none',
    borderRadius: 4,
    transition: 'all 0.25s',
    margin: [20, 0],

    '&:hover': {
      background: theme.neonGreenLight
    }
  }
}))
