import React from 'react'
import { useSelector } from 'react-redux'

import VoucherValidateErrorModal from '../../../components/modals/voucher-validate-error'
import { VOUCHER_VALIDATE_FOR_SIGNUP } from '../../../constants'

const VoucherValidateErrorModalForSignup = () => {
  const mapState = useSelector(state => state)
  const { voucherValidateError } = mapState

  return <VoucherValidateErrorModal error={voucherValidateError} type={VOUCHER_VALIDATE_FOR_SIGNUP} />
}

export default VoucherValidateErrorModalForSignup
