import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { propType } from 'graphql-anywhere'
import { graphql } from 'react-apollo'
import {
  compose, branch, lifecycle, renderComponent, withHandlers, withProps
} from 'recompose'
import { filter, propEq } from 'ramda'
import { withRouter } from 'react-router-dom'
import qs from 'query-string'

import ProfilesList from '../../components/profiles/profiles-list'
import withApolloQuery from '../../hoc/with-apollo-query-and-loading'
import { checkIsAuthenticated } from '../../lib/auth'
import { requirePinForSwitchProfile } from '../../lib/account'
import { hasHigherParentalRating } from '../../lib/utils'
import { getModalLocation } from '../../lib/modal'
import { switchProfileWithPin, switchProfile } from '../../actions/profile'
import { LoadingModal } from '../../components/loading/modal'
import { useProfilesEntitlement } from '../../hooks/useProfilesEntitlement'
import { MODALS, NAVIGATION_BAR_GA_KIDS_PROFILE_SELECTION } from '../../constants'

import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'
import ACCOUNT_FRAGMENT from '../../../graphql/fragments/account.gql'

const ProfilesSwitchContainer = ({ accountQuery, ...rest }) => {
  const { profiles, profileLimit, isProfileAllowBasicUpsell } = useProfilesEntitlement(accountQuery)
  return (
    <ProfilesList
      profiles={profiles}
      isProfileAllowBasicUpsell={isProfileAllowBasicUpsell}
      profileLimit={profileLimit}
      {...rest}
    />
  )
}

ProfilesSwitchContainer.propTypes = {
  accountQuery: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.object]),
    account: propType(ACCOUNT_FRAGMENT)
  }).isRequired,
  onSwitchProfile: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
  const isAuthenticated = checkIsAuthenticated(state)
  return {
    isAuthenticated // used to skip account query
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch
})

const isNoProfileSlotForNewKids = ({ location, profiles }) => {
  const parsedSearch = qs.parse(location.search)
  const allKids = filter(profile => propEq('isKid', true)(profile), profiles)
  return !!(parsedSearch && parsedSearch.kids_full && allKids.length === 0)
}

const enhance = compose(
  withRouter,
  withApolloQuery,
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated
  }),
  branch(
    ({ accountQuery }) => (
      !accountQuery ||
      (accountQuery && (accountQuery.loading || accountQuery.error))
    ),
    renderComponent(LoadingModal)
  ),
  lifecycle({
    componentDidMount() {
      const { setOnCloseHandler } = this.props
      setOnCloseHandler(null)
    }
  }),
  withProps(({ accountQuery }) => {
    return {
      requirePin: requirePinForSwitchProfile(accountQuery.account),
      currentProfile: accountQuery.account.profiles.find(
        profile => profile.id === accountQuery.account.selectedProfile
      ),
      isKidsNoProfileSlot: isNoProfileSlotForNewKids({ location, profiles: accountQuery && accountQuery.account.profiles })
    }
  }),
  withHandlers({
    onAddProfileClick: ({ location, history }) => () => {
      history.push(getModalLocation(location, MODALS.qsParams.profilesAdd))
    },
    onConfigureProfile: ({ history }) => (profileId) => {
      const profilesEditLocation = getModalLocation(location, MODALS.qsParams.profilesEdit, {
        profileId
      })
      history.push(profilesEditLocation)
    },
    onSwitchProfile: ({ dispatch, requirePin, currentProfile }) => (selectedProfile) => {
      const parsedSearch = qs.parse(location.search)
      const kidsFromNav = parsedSearch.nav && selectedProfile.isKid
      if (requirePin &&
          hasHigherParentalRating(currentProfile.maxRating, selectedProfile.maxRating)) {
        dispatch(switchProfileWithPin(selectedProfile))
        return
      }
      const gaOption = kidsFromNav ? NAVIGATION_BAR_GA_KIDS_PROFILE_SELECTION : null
      dispatch(switchProfile(selectedProfile, null, gaOption))
    }
  })
)

export default enhance(ProfilesSwitchContainer)
