import React from 'react'
import PropTypes from 'prop-types'

import Form from '../forms/layout-form'
import Row from '../forms/layout-row'
import Col from '../forms/layout-column'
import PinInput from '../forms/pin-input'
import SubmitButton from '../forms/button'
import { FORM_VALUES } from '../../constants'

function PinResetForm({
  // Formik props
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  error,
  touched,
  isSubmitting
}) {
  return (
    <Form isSubmitting={isSubmitting} handleSubmit={handleSubmit}>
      <div>
        <Row>
          <Col>
            <PinInput
              name="pin"
              label="PIN"
              value={values.pin}
              onChange={handleChange}
              onBlur={handleBlur}
              warning={(values.pin || touched.pin) && (errors.pin)}
              minLength="0"
              maxLength={`${FORM_VALUES.pin.length}`}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <PinInput
              name="pinConfirmation"
              label="Confirm PIN"
              value={values.pinConfirmation}
              onChange={handleChange}
              onBlur={handleBlur}
              warning={
                (values.pinConfirmation || touched.pinConfirmation) &&
                (errors.pinConfirmation || error)
              }
              minLength="0"
              maxLength={`${FORM_VALUES.pin.length}`}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton disabled={isSubmitting} text="RESET PIN" />
          </Col>
        </Row>
      </div>
    </Form>
  )
}

PinResetForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

PinResetForm.defaultProps = {
  error: ''
}

export default PinResetForm
