import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './price.css'

function Price({
  amount,
  strikethrough,
  secondary
}) {
  return (
    <div
      className={classNames({
        [styles.price]: true,
        [styles.strikethrough]: Boolean(strikethrough),
        [styles.secondary]: Boolean(secondary)
      })}
    >
      <span className={styles.currency}>$</span>
      <span>{amount}</span>
    </div>
  )
}

Price.propTypes = {
  amount: PropTypes.string.isRequired,
  strikethrough: PropTypes.bool,
  secondary: PropTypes.bool
}

Price.defaultProps = {
  strikethrough: false,
  secondary: false
}

export default Price
