import { pathOr, path } from 'ramda'
import { lifecycle } from 'recompose'
import { isSubscriptionDatalayerReleased } from '../../../constants'
import {
  datalayerNonSubscriptionConfirmationHandler,
  datalayerSubscriptionConfirmationHandler
} from '../../../lib/analytics/datalayer-handler'

const findFirstAvailableCardId = pathOr('', ['account', 'creditcards', '0', 'id'])

export const withSubscriptionConfirmationLifeCycle = lifecycle({
  componentDidMount() {
    if (!this.props.defaultTVODCreditCard) {
      const paymentMethodId = findFirstAvailableCardId(this.props.accountQuery)
      this.props.setCreditcardDefault(paymentMethodId, 'TVOD')
        .then(() => {
          console.info('Default TVOD payment method set')
        }).catch(() => {
          console.info('Error occurred setting default TVOD payment method')
        })
    }

    if (isSubscriptionDatalayerReleased) {
      if (path(['account', 'subscription', 'currentSubscription'], this.props.accountQuery)) {
        datalayerSubscriptionConfirmationHandler(this.props.accountQuery, this.props.voucherCode)
      } else {
        datalayerNonSubscriptionConfirmationHandler()
      }
    }
  }
})
