import PropTypes from 'prop-types'
import {
  compose,
  setDisplayName,
  setPropTypes,
  branch,
  renderComponent,
  lifecycle,
  shouldUpdate
} from 'recompose'
import { graphql } from 'react-apollo'
import { path, F } from 'ramda'
import { withRouter } from 'react-router-dom'

import SubscriptionConfirmationOrderView from '../../../components/subscriptions/subscription-confirmation-order'
import { LoadingModal } from '../../../components/loading/modal'

import { getModalLocation } from '../../../lib/modal'
import { MODALS } from '../../../constants'
import { findDefaultSVODCreditCard, findDefaultTVODCreditCard } from '../../../lib/account'
import withSetCreditcardDefaultMutation from '../../../hoc/with-set-creditcard-default-mutation'

import SUBSCRIPTION_CONFIRMATION_VIEW_QUERY from '../../../../graphql/queries/subscription-confirmation-view.gql'
import ACCOUNT_QUERY_WITH_DIR from '../../../../graphql/queries/account-with-directives.gql'

const findFirstAvailableCardId = path('', ['account', 'creditcards', '0', 'id'])

const enhance = compose(
  setDisplayName('StepTwoConfirmationView'),
  setPropTypes({
    // Although not having an `orderId` does not fail the query,
    // it is needed in order for MW
    // to perform the necessary operations to make sure everything
    // updates correctly, which includes the copy to be displayed.
    payload: PropTypes.shape({
      orderId: PropTypes.string.isRequired
    })
  }),
  withRouter,
  // We need to prevent this entire container from re-rendering
  // after the default creditcard mutation, because we are subscribed
  // to account data changes after fetching the account data.
  shouldUpdate(F),
  graphql(SUBSCRIPTION_CONFIRMATION_VIEW_QUERY, {
    name: 'subscriptionConfirmationQuery',
    options: ({ payload: { orderId } }) => {
      return {
        variables: { orderId },
        fetchPolicy: 'network-only'
      }
    },
    props: ({
      ownProps: {
        history, location, ...restOwnProps
      },
      subscriptionConfirmationQuery: {
        loading: subscriptionConfirmationLoading,
        error: subscriptionConfirmationError,
        subscriptionConfirmationView
      }
    }) => ({
      ...restOwnProps,
      subscriptionConfirmationLoading,
      subscriptionConfirmationError,
      ...(subscriptionConfirmationView || {}),
      onCtaClick() {
        // Replicating behavior from current subscription-confirmation container here
        history.push(
          getModalLocation(
            location,
            MODALS.qsParams.profilesSwitch
          )
        )
      }
    })
  }),
  graphql(ACCOUNT_QUERY_WITH_DIR, {
    name: 'accountQuery',
    options: {
      fetchPolicy: 'network-only',
      variables: {
        withCreditcards: true,
        withSubscription: true
      }
    },
    props: ({
      ownProps,
      accountQuery: {
        loading: accountQueryLoading,
        error: accountQueryError,
        account = {}
      }
    }) => ({
      ...ownProps,
      accountQueryLoading,
      accountQueryError,
      account,
      hasDefaultSVODCard: findDefaultSVODCreditCard(account),
      hasDefaultTVODCard: findDefaultTVODCreditCard(account)
    })
  }),
  withSetCreditcardDefaultMutation,
  branch(
    ({
      subscriptionConfirmationLoading,
      subscriptionConfirmationError,
      accountQueryLoading
    }) => (
      subscriptionConfirmationLoading ||
      subscriptionConfirmationError ||
      accountQueryLoading
    ),
    renderComponent(LoadingModal)
  ),
  lifecycle({
    componentDidMount() {
      // If the user already has a default SVOD card setup,
      // then we don't need to set anything
      if (this.props.hasDefaultSVODCard) {
        return
      }

      if (this.props.account) {
        const paymentMethodId = findFirstAvailableCardId(this.props.account)
        // If there's no default SVOD already setup, then we'll find the first
        // available credit card on the account and make it the default one for SVOD.
        if (paymentMethodId) {
          this.props.setCreditcardDefault(paymentMethodId, 'SVOD')
            .then(() => {
              if (process.env.NODE_ENV !== 'production') {
                console.info('Default SVOD payment method set')
              }
            }).catch(() => {
              if (process.env.NODE_ENV !== 'production') {
                console.info('Error occurred setting default SVOD payment method')
              }
            })
          // We'll also make it the default one for TVOD, if one does not currently exist.
          if (!this.props.hasDefaultTVODCard) {
            this.props.setCreditcardDefault(paymentMethodId, 'TVOD')
              .then(() => {
                if (process.env.NODE_ENV !== 'production') {
                  console.info('Default TVOD payment method set')
                }
              }).catch(() => {
                if (process.env.NODE_ENV !== 'production') {
                  console.info('Error occurred setting default TVOD payment method')
                }
              })
          }
        }
      }
    }
  })
)

export default enhance(SubscriptionConfirmationOrderView)
