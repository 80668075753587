import PropTypes from 'prop-types'
import React from 'react'
import Button from '../../forms/neon/button'
import styles from './subscription-offer.css'
import { MANAGE_PLAN_OPTIONS, MODALS } from '../../../constants'
import { useOneWaySubscriptionChange } from '../../../hooks/useOneWaySubscriptionChange'
import { modalContent } from '../../../modules/shared/subscription-constants'
import { usePlanChangeFlag } from '../../../hooks/usePlanChange'

const SubscriptionOffer = ({
  showModal,
  manageSubsOptions,
  currentSubscriptionName
}) => {
  const oneWayChangeDirection = useOneWaySubscriptionChange(manageSubsOptions)
  const getPlanDetails = usePlanChangeFlag()
  // Go to subscription selection modal
  const goToSubscriptionSelection = () => {
    showModal(MODALS.qsParams.addSubscription, {
      content: modalContent.selection,
      currentSubscription: currentSubscriptionName,
      oneWayChangeDirection
    })
  }

  // Go to subscription cancel modal
  const goToSubscriptionCancel = () => {
    showModal(MODALS.qsParams.subscriptionCancel)
  }

  return (
    <div className={styles.subscriptionOfferModal}>
      <h1 className={styles.header}>Before you decide to cancel</h1>
      {(manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.UPGRADE) || manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.DOWNGRADE)) && (
        <div>
          <div className={styles.body}>
            Why not save on your Neon plan with one of our flexible offers
          </div>

          <div className={styles.planCard}>
            <div className={styles.planName}>{getPlanDetails?.basic.offerDetail.name}</div>
            <div>{getPlanDetails?.basic.offerDetail.desc}</div>
          </div>
          <div className={styles.planCard}>
            <div className={styles.planName}>{getPlanDetails?.standard.offerDetail.name}</div>
            <div>{getPlanDetails?.standard.offerDetail.desc}</div>
          </div>
          <div className={styles.planCard}>
            <div className={styles.planName}>{getPlanDetails?.annual.offerDetail.name}</div>
            <div>{getPlanDetails?.annual.offerDetail.desc}</div>
          </div>

          <div className={styles.buttonRow}>
            <Button
              onClick={goToSubscriptionSelection}
              wrapperClassName={styles.buttonWrapper}
              className={styles.button}
              text="Yes, Change my plan"
            />
            <Button
              secondary
              onClick={goToSubscriptionCancel}
              wrapperClassName={styles.buttonWrapper}
              className={styles.button}
              text="No, Thank you"
            />
          </div>
        </div>
      )}
    </div>
  )
}

SubscriptionOffer.propTypes = {
  showModal: PropTypes.func.isRequired,
  manageSubsOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentSubscriptionName: PropTypes.string.isRequired
}

export default SubscriptionOffer
