import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 30,
    [theme.media.desktop]: {
      minWidth: 1000
    }
  },

  subTitle: {
    fontSize: 20,
    lineHeight: 0.95,
    fontWeight: 400,
    letterSpacing: 'normal',
    textAlign: 'center',
    marginTop: 0
  },

  buttonContainer: {
    paddingBottom: 30,
    maxWidth: 320,
    width: '100%',
    minWidth: 120
  }
}))

export default useStyles
