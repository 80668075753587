import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'

import Form from '../forms/layout-form'
import Row from '../forms/layout-row'
import Col from '../forms/layout-column'
import PasswordInput from '../forms/password-input'
import SubmitButton from '../forms/button'

function PinRequestForm({
  // Formik props
  values,
  handleChange,
  handleBlur,
  handleSubmit,
  errors,
  error,
  touched,
  isSubmitting,
  // Custom props
  passwordForgottenLocation
}) {
  return (
    <Form isSubmitting={isSubmitting} handleSubmit={handleSubmit}>
      <div>
        <Row>
          <Col>
            <PasswordInput
              name="password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              warning={touched.password && (errors.password || error)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to={passwordForgottenLocation}>
              Forgot your password?
            </Link>
          </Col>
        </Row>
        <Row>
          <Col>
            <SubmitButton disabled={isSubmitting} text="RESET PIN" />
          </Col>
        </Row>
      </div>
    </Form>
  )
}

PinRequestForm.propTypes = {
  values: PropTypes.oneOfType([PropTypes.object]).isRequired,
  errors: PropTypes.oneOfType([PropTypes.object]).isRequired,
  touched: PropTypes.oneOfType([PropTypes.object]).isRequired,
  error: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  passwordForgottenLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired
}

PinRequestForm.defaultProps = {
  error: ''
}

export default PinRequestForm
