import { createUseStyles } from 'react-jss'

export const useStyles = createUseStyles(theme => ({
  copy: {
    fontSize: 14,
    fontWeight: 325,
    textAlign: 'center',
    marginBottom: 30,

    [theme.media.mobile]: {
      padding: [0, 10, 0]
    }
  },

  copyMessage: {
    fontSize: 14,
    fontWeight: 325,
    textAlign: 'center',
    color: theme.neonGreen,
    paddingBottom: 5,

    [theme.media.mobile]: {
      padding: 10
    }
  },

  container: {
    [theme.media.desktop]: {
      padding: [40, 160]
    }
  },

  border: {
    border: `1px solid ${theme.white}`,
    borderRadius: '4px',
    maxWidth: 550,
    padding: 15,
    [theme.media.desktop]: {
      width: 550
    }
  },

  paymentContainer: {
    marginTop: 15
  },

  selector: {
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: 10
  },

  icon: {
    width: 13,
    height: 13,
    marginRight: 10
  }
}))
