import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useQuery } from 'react-apollo'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { path, head } from 'ramda'
import { format, toDate } from 'date-fns'

import styles from './subscription-voucher-confirmation-view.css'
import { useCommonStyles } from '../../../../../modules/signup/lib/common.styles'
import { signupImage } from '../../../../../modules/shared/subscription-constants'
import Button from '../../../../forms/neon/button'
import { LoadingModal } from '../../../../loading/modal'
import PlanDetails from '../../../../../modules/signup/components/plan-details'
import VoucherMessages from '../../../../../modules/signup/components/voucher-messages'
import { getModalReturnTarget } from '../../../../../lib/modal'
import GetOrderDetails from '../../../../../../graphql/queries/subscription-voucher-confirmation-view.gql'
import { usePlanChangeFlag } from '../../../../../hooks/usePlanChange'
import { segmentTrackCompletePurchase } from '../../../../../segment/segment-track'

const AddVoucherConfirmation = ({ orderId, voucherCode, hasPlan }) => {
  const commonStyles = useCommonStyles()
  const history = useHistory()
  const location = useLocation()
  const [details, setDetails] = useState()
  const getPlanDetails = usePlanChangeFlag()

  const onClick = () => {
    history.push(getModalReturnTarget(location))
  }

  // Get voucher info and update subscription info in apollo cache
  const { data, loading, error } = useQuery(GetOrderDetails, {
    fetchPolicy: 'network-only',
    variables: {
      withCreditcards: true,
      withSubscription: true,
      withClearCachedSubscriptions: true
    }
  })

  // Set details
  useEffect(() => {
    const getDetails = accountInfo => {
      if (!accountInfo) {
        return {
          productName: null,
          productPrice: null,
          nextPaymentDate: null,
          chargedPrice: null
        }
      }
      const vouchers = path(
        ['account', 'subscription', 'recurringVouchers'],
        accountInfo
      )
      const voucher = head(vouchers)
      const nextPaymentDate = path(['orderDetails', 'nextPaymentDate'], voucher)
      const nextPaymentDateFormatted = format(
        toDate(parseInt(nextPaymentDate, 10)),
        'd MMM yyyy'
      )
      const chargedPrice = path(['orderDetails', 'chargedPrice'], voucher)

      const sku = path(['orderDetails', 'sku'], voucher)
      const subscriptions = [
        getPlanDetails.basic,
        getPlanDetails.standard,
        getPlanDetails.annually,
        getPlanDetails.legacy
      ]
      const subscriptionStaticDetail = subscriptions.find(sub => sub.sku === sku).staticDetail || { name: null, value: null }

      // Add segment data analytics for completing purchase
      segmentTrackCompletePurchase({
        planTitle: subscriptionStaticDetail.name,
        planSku: sku,
        paymentDue: nextPaymentDateFormatted,
        payment: Number(chargedPrice),
        voucher: voucherCode,
        orderId
      })

      return {
        productName: subscriptionStaticDetail.name,
        productPrice: subscriptionStaticDetail.value,
        nextPaymentDate: nextPaymentDateFormatted,
        chargedPrice
      }
    }

    const {
      productName, productPrice, nextPaymentDate, chargedPrice
    } = getDetails(data)
    if (nextPaymentDate && chargedPrice) {
      setDetails([
        {
          name: `${productName}`,
          value: `${productPrice}`
        },
        {
          name: hasPlan ? 'Payment due' : 'First payment due',
          value: `${nextPaymentDate}`
        },
        {
          name: hasPlan ? 'Payment' : 'First payment',
          value: `$${chargedPrice}`
        }
      ])
    }
  }, [data])

  if (error) {
    history.push(getModalReturnTarget(location))
  } else if (loading || !data) {
    return <LoadingModal />
  }

  return (
    <div className={classNames(styles.wrapper)}>
      <div className={styles.header}>Your voucher is good to go!</div>
      {details && <PlanDetails detail={details} />}
      <VoucherMessages />
      <img
        src={signupImage}
        alt="Plan confirmation"
        className={commonStyles.image}
      />
      <Button className={styles.button} text="Get started!" onClick={onClick} />
    </div>
  )
}

AddVoucherConfirmation.propTypes = {
  orderId: PropTypes.string.isRequired,
  voucherCode: PropTypes.string,
  hasPlan: PropTypes.bool
}

AddVoucherConfirmation.defaultProps = {
  hasPlan: false
}

export default AddVoucherConfirmation
