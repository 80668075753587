import qs from 'query-string'
import { withProps } from 'recompose'

import { pushQueryParams } from '../lib/location'

export default withProps(({ history, location }) => {
  const search = qs.parse(location.search)
  return {
    search,
    pushSearch: pushQueryParams(history, location)
  }
})
