import {
  compose, lifecycle, withHandlers, withProps
} from 'recompose'
import { connect } from 'react-redux'
import {
  evolve, isNil, pipe, prop, view
} from 'ramda'
import { graphql } from 'react-apollo'

import { findDefaultSVODCreditCard } from '../../../../lib/account'
import { parseDecimalInt } from '../../../../lib/utils'
import { lensSearch, pushQueryParams } from '../../../../lib/location'
import SubscriptionResume from '../../../../components/subscriptions/neon/subscription-resume'
import { saveIntroductionVersion } from '../../../../actions'
import withIsAuthenticated from '../../../../hoc/is-authenticated'

import ACCOUNT_QUERY from '../../../../../graphql/queries/account.gql'
import SUBSCRIBE_MUTATION from '../../../../../graphql/mutations/subscribe.gql'

const mapStateToProps = ({ session: { decodedJwt } }) => ({
  accountId: prop('accountId', decodedJwt)
})

const mapDispatchToProps = (dispatch, { accountId }) => {
  return {
    resetPremiumIntroductionVersion: () => {
      dispatch(saveIntroductionVersion(accountId, 0))
    }
  }
}

export default compose(
  withIsAuthenticated,
  withProps(pipe(
    prop('location'),
    view(lensSearch),
    evolve({
      step: parseDecimalInt
    })
  )),
  connect(mapStateToProps, mapDispatchToProps),
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    options: {
      fetchPolicy: 'cache-only'
    },
    props: ({ ownProps, accountQuery: { error, loading, account } }) => ({
      ...ownProps,
      account,
      validCreditCard: findDefaultSVODCreditCard(account),
      accountQueryError: error,
      accountQueryLoading: loading
    })
  }),
  graphql(SUBSCRIBE_MUTATION, {
    name: 'subscribeMutation'
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const {
        isAuthenticated,
        onRequestClose
      } = this.props
      // Dismiss the modal if not logged in.
      if (!isAuthenticated) {
        onRequestClose()
      }
    },
    componentDidMount() {
      const { setOnCloseHandler } = this.props
      setOnCloseHandler(null)
    }
  }),
  withHandlers({
    selectPlanNoVoucher: ({ history, location }) => () => {
      pushQueryParams(history, location, {
        step: 2
      })
    },
    selectPlanWithVoucher: ({ history, location }) => (voucherCode) => {
      pushQueryParams(history, location, {
        step: 2,
        voucherCode
      })
    },
    onSubscriptionSelection: ({
      history, location, subscribeMutation, voucherCode
    }) => (subscriptionId, paymentMethodId) => {
      if (!isNil(paymentMethodId)) {
        return subscribeMutation({
          variables: {
            paymentMethodId,
            productId: subscriptionId,
            voucherCode
          }
        }).then(({ data: { subscribe: orderId } }) => {
          pushQueryParams(history, location, {
            step: 4,
            orderId,
            paymentMethodId
          })
        })
      }

      return pushQueryParams(history, location, {
        step: 3,
        subscriptionId
      })
    },
    onPaymentSubmission: ({ history, location, resetPremiumIntroductionVersion }) => (orderId, eligiblePromotionId) => {
      /**
         * Reset the promo modal so they see an upsell prompt if applicable.
         */
      resetPremiumIntroductionVersion()
      return pushQueryParams(history, location, {
        orderId, // come from the iframe
        eligiblePromotionId, // come from the iframe
        step: 4
      })
    }
  })
)(SubscriptionResume)
