import { compose } from 'recompose'
import { connect } from 'react-redux'

import { getAccountIdFromState } from '../../../selectors/session'
import { saveHasSeenRenewalPrompt as saveHasSeenRenewalPromptAction } from '../../../actions'
import SubscriptionCancel from '../../../components/subscriptions/neon/cancel'
import withIsAuthenticated from '../../../hoc/is-authenticated'
import { withShowModalClick } from '../../../hoc'

const mapStateToProps = state => ({
  accountId: getAccountIdFromState(state)
})

const mapDispatchToProps = {
  saveHasSeenRenewalPrompt: saveHasSeenRenewalPromptAction
}

const SubscriptionCancelContainer = compose(
  withIsAuthenticated,
  connect(mapStateToProps, mapDispatchToProps),
  withShowModalClick
)(SubscriptionCancel)

export default SubscriptionCancelContainer
