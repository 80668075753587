import { GENDERS } from '../constants'
import getCapitalizedText from './getCapitalizedText'

const getGenderOptions = () => {
  return GENDERS.map(value => ({
    label: getCapitalizedText(value),
    value
  }))
}

export default getGenderOptions
