import { createUseStyles } from 'react-jss'
import { commonStyles } from '../common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  cancelModal: {
    textAlign: 'center',
    padding: 50
  },

  title: {
    fontSize: 26,
    fontWeight: 500,
    fontcolor: theme.white
  },

  content: {
    fontSize: 14,
    fontcolor: theme.white,
    marginTop: 20
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 10
  },

  button: {
    width: 270,
    margin: 10
  }
}))
