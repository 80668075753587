import React from 'react'

import PasswordResetForm from '../../containers/password-reset/password-reset-form'

import styles from './reset.css'

function PasswordReset({ ...props }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Reset your password
        </h1>

        <span className={styles.subHeader}>
          Please enter your new password below.
        </span>
      </div>

      <PasswordResetForm
        {...props}
      />
    </div>
  )
}

export default PasswordReset
