import React from 'react'
import {
  compose,
  branch,
  renderComponent
} from 'recompose'
import { withRouter, Redirect } from 'react-router-dom'

import { getModalLocation } from '../lib/modal'
import withIsAuthenticated from './is-authenticated'

import { MODALS } from '../constants'

function RedirectToLogin({ location }) {
  return (
    <Redirect
      to={getModalLocation(location, MODALS.qsParams.login)}
    />
  )
}

// Higher order component to manage loading screen on mutations
export default compose(
  branch(
    ({ location }) => !location,
    withRouter
  ),
  withIsAuthenticated,
  branch(
    ({ isAuthenticated }) => !isAuthenticated,
    renderComponent(RedirectToLogin)
  )
)
