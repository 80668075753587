import React from 'react'

import PinForgottenForm from '../../containers/pin/pin-reset-request-form'

import styles from './request.css'

function PinForgotten({ ...props }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>Forgot PIN?</h1>
        <span className={styles.subHeader}>
          Enter the account password to reset your PIN.
        </span>
      </div>
      <PinForgottenForm
        {...props}
      />
    </div>
  )
}

export default PinForgotten
