import {
  compose,
  withHandlers
} from 'recompose'
import { withApollo } from 'react-apollo'
import { Formik } from 'formik'
import Step1 from '../../../../components/subscriptions/neon/welcome-back/step-one'

import Yup from '../../../../lib/yup'
import { getGQLErrorMsg } from '../../../../lib/apollo'
import { VOUCHERS } from '../../../../constants'

import VOUCHER_QUERY from '../../../../../graphql/queries/voucher.gql'
import { navigationGa } from '../../../../lib/analytics/ga'

export default compose(
  withApollo,
  withHandlers({
    browseAsGuest: ({ onRequestClose }) => () => {
      navigationGa({
        action: 'Browse as guest'
      })
      onRequestClose()
    }
  }),
  Formik({
    validateOnChange: true,

    validationSchema: () => {
      return Yup.object().shape({
        voucherCode: Yup.string()
      })
    },

    mapPropsToValues: ({ voucherCode }) => ({
      voucherCode: voucherCode || ''
    }),

    handleSubmit: (values, { props, setErrors, setSubmitting }) => {
      setErrors({ voucherCode: '' })
      const {
        client,
        selectPlanNoVoucher,
        selectPlanWithVoucher
      } = props

      if (!values.voucherCode) {
        selectPlanNoVoucher()
        return
      }
      client.query({
        query: VOUCHER_QUERY,
        variables: {
          code: values.voucherCode
        }
      })
        .then((response) => {
          setSubmitting(false)
          if (
            !response.data.voucher ||
            (response.data.voucher && response.data.voucher.type !== VOUCHERS.types.SVOD)
          ) {
            throw new Error('Invalid voucher: only subscription vouchers are accepted.')
          }
          selectPlanWithVoucher(values.voucherCode)
        })
        .catch((error) => {
          setSubmitting(false)
          setErrors({
            voucherCode: error.graphQLErrors ? getGQLErrorMsg(error) : error.message
          })
          throw error
        })
    }
  })
)(Step1)
