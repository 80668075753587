import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'

import styles from './subscription-voucher-view.css'
import SubscriptionVoucherViewForm from './subscription-voucher-view-form'

function SubscriptionVoucherView({
  header,
  subHeader,
  label,
  handleSubmitVoucher,
  error
}) {
  const title = 'Enter your voucher - NEON'
  return (
    <div className={styles.wrapper}>

      <Helmet>
        <meta property="og:title" content={title} />
        <title>{title}</title>
      </Helmet>

      <h1 className={styles.header}>
        Do you have a voucher code?
        <br />
        Enter it here.
      </h1>

      <SubscriptionVoucherViewForm
        handleSubmitVoucher={handleSubmitVoucher}
        error={error}
      />
    </div>
  )
}

SubscriptionVoucherView.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  label: PropTypes.string.isRequired,
  handleSubmitVoucher: PropTypes.func.isRequired,
  error: PropTypes.string
}

SubscriptionVoucherView.defaultProps = {
  error: '',
  subHeader: ''
}

export default SubscriptionVoucherView
