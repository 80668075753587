import React from 'react'

import PasswordForgottenForm from '../../containers/password-reset/password-reset-request-form'
import styles from './request.css'

function PasswordForgotten({ ...props }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Reset your password
        </h1>

        <span className={styles.subHeader}>
          Forgot your password? It happens.
          Please enter the email associated with your account
          and we&apos;ll send you a password reset link.
        </span>
      </div>

      <PasswordForgottenForm
        {...props}
      />
    </div>
  )
}

export default PasswordForgotten
