import { compose } from 'recompose'
import { connect } from 'react-redux'

import { getAccountIdFromState } from '../../../selectors/session'
import SubscriptionOffer from '../../../components/subscriptions/neon/subscription-offer'
import withIsAuthenticated from '../../../hoc/is-authenticated'
import { withShowModalClick } from '../../../hoc'

const mapStateToProps = state => ({
  accountId: getAccountIdFromState(state),
  manageSubsOptions: state.manageSubsOptions,
  currentSubscriptionName: state.currentSubscriptionName
})

const SubscriptionOfferContainer = compose(
  withIsAuthenticated,
  connect(mapStateToProps),
  withShowModalClick
)(SubscriptionOffer)

export default SubscriptionOfferContainer
