
import {
  compose,
  setDisplayName,
  branch,
  renderComponent,
  lifecycle
} from 'recompose'
import { graphql } from 'react-apollo'
import { path, isEmpty, propEq } from 'ramda'

import SubscriptionCreditcard from '../../../../../components/subscriptions/neon/subscription-creditcard'
import { LoadingModal } from '../../../../../components/loading/modal'

import SUBSCRIPTION_CREDITCARD_VIEW_QUERY from '../../../../../../graphql/queries/subscription-creditcard-view.gql'

const enhance = compose(
  setDisplayName('CreditcardViewContainer'),
  graphql(SUBSCRIPTION_CREDITCARD_VIEW_QUERY, {
    name: 'subscriptionCreditcardViewQuery',
    options: ({ payload: { subscriptionId } }) => {
      return {
        variables: { subscriptionId },
        fetchPolicy: 'network-only'
      }
    },
    props: ({
      ownProps: { renderStep },
      subscriptionCreditcardViewQuery: {
        loading: creditcardViewLoading,
        error: creditcardViewError,
        subscriptionCreditcardView
      }
    }) => ({
      onIframeLoad: (iframeQueryParams) => {
        if (isEmpty(iframeQueryParams)) return

        if (propEq('status', 'success', iframeQueryParams)) {
          const orderId = path(['orderId'], iframeQueryParams)
          renderStep(4, { orderId })
        }
      },
      creditcardViewLoading,
      creditcardViewError,
      ...(subscriptionCreditcardView || {})
    })
  }),
  lifecycle({
    UNSAFE_componentWillReceiveProps({ creditcardViewError, iframe, orderId }) {
      if (orderId) {
        this.props.renderStep(4, { orderId })
        return
      }

      if (creditcardViewError || iframe == null) {
        this.props.renderStep(1)
      }
    }
  }),
  branch(
    ({ creditcardViewLoading, creditcardViewError, iframe }) => (
      creditcardViewLoading || creditcardViewError || iframe == null
    ),
    renderComponent(LoadingModal)
  )
)

export default enhance(SubscriptionCreditcard)
