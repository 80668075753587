import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { propType } from 'graphql-anywhere'
import {
  branch,
  compose,
  renderComponent,
  withHandlers,
  withProps,
  withState
} from 'recompose'
import { path, find, propEq } from 'ramda'

import ManageProfiles from '../../components/profiles/profiles-manage'
import ConfirmationModal from '../../components/shared/confirmation-modal'
import withIsAuthenticatedAndRedirect from '../../hoc/is-authenticated-redirect'
import withMutateAndLoading from '../../hoc/with-mutate-and-loading'
import { LoadingModal } from '../../components/loading/modal'
import { useProfilesEntitlement } from '../../hooks/useProfilesEntitlement'
import { requirePin } from '../../actions'
import { requirePinForManageProfile } from '../../lib/account'
import { isPinValid } from '../../lib/pin'
import { getModalLocation } from '../../lib/modal'

import { MODALS } from '../../constants'

import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'
import ACCOUNT_FRAGMENT from '../../../graphql/fragments/account.gql'
import DELETE_PROFILE_MUTATION from '../../../graphql/mutations/delete-profile.gql'
import {
  switchProfile
} from '../../actions/profile'
import { segmentTrackDeleteProfile } from '../../segment/segment-track'

const ManageProfilesContainer = ({
  accountQuery,
  isConfirmationModalOpen,
  onCancelClick,
  onConfirmClick,
  ...rest
}) => {
  // Basic subscription has profile limitation
  const { profiles, profileLimit, isProfileAllowBasicUpsell } = useProfilesEntitlement(accountQuery)

  return (
    <>
      <ManageProfiles
        profiles={profiles}
        isProfileAllowBasicUpsell={isProfileAllowBasicUpsell}
        profileLimit={profileLimit}
        {...rest}
      />
      {
        isConfirmationModalOpen && (
          <ConfirmationModal
            header="Are you sure?"
            message={
              `You're about to permanently delete this profile,
              are you sure this is what you'd like to do?`
            }
            confirmText="Yes, Delete this profile"
            cancelText="Cancel"
            onCancelClick={onCancelClick}
            onConfirmClick={onConfirmClick}
          />
        )
      }
    </>
  )
}

ManageProfilesContainer.propTypes = {
  accountQuery: PropTypes.shape({
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.object]),
    account: propType(ACCOUNT_FRAGMENT)
  }).isRequired,
  deleteProfile: PropTypes.func.isRequired,
  isConfirmationModalOpen: PropTypes.bool.isRequired,
  setDisplayConfirmationModal: PropTypes.func.isRequired,
  onDeleteProfile: PropTypes.func.isRequired,
  onCancelClick: PropTypes.func.isRequired,
  onConfirmClick: PropTypes.func.isRequired
}

const enhance = compose(
  withIsAuthenticatedAndRedirect,
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    skip: ownProps => !ownProps.isAuthenticated
  }),
  branch(
    ({ accountQuery }) => accountQuery && (accountQuery.loading || accountQuery.error),
    renderComponent(LoadingModal)
  ),
  graphql(DELETE_PROFILE_MUTATION),
  withMutateAndLoading,
  withState('isConfirmationModalOpen', 'setDisplayConfirmationModal', false),
  withState('selectedProfileId', 'setSelectedProfileId', null),
  withHandlers({
    deleteProfile: ({ mutateAndLoading, dispatch, accountQuery }) => async (profileId) => {
      if (accountQuery.account.selectedProfile === profileId) {
        // Switch to default profile before user delete active profile
        await dispatch(switchProfile(find(propEq('isDefault'), accountQuery.account.profiles)))
      }

      return mutateAndLoading('profileDelete', {
        variables: {
          id: profileId
        },
        update: (proxy, result) => {
          const data = proxy.readQuery({ query: ACCOUNT_QUERY })

          data.account.profiles = result.data.deleteProfile.profiles

          proxy.writeQuery({ query: ACCOUNT_QUERY, data })

          // Add segment data analytics for deleting profile
          const deletedProfile = accountQuery.account.profiles.find(profile => profile.id === profileId)
          segmentTrackDeleteProfile({
            id: deletedProfile.id,
            name: deletedProfile.name
          })
        }
      })
    }
  }),
  connect(
    state => ({
      isPinRequired: !isPinValid(state)
    }),
    dispatch => ({
      dispatch
    }),
    ({ isPinRequired }, dispatch, ownProps) => {
      return {
        ...dispatch,
        ...ownProps,
        isPinRequired: isPinRequired && requirePinForManageProfile(ownProps.accountQuery.account)
      }
    }
  ),
  withProps(({ accountQuery }) => ({
    currentProfile: accountQuery.account.profiles.find(
      profile => profile.id === accountQuery.account.selectedProfile
    )
  })),
  withHandlers({
    onAddProfile: ({
      location, history, dispatch, isPinRequired
    }) => () => {
      const profilesAddLocation = getModalLocation(location, MODALS.qsParams.profilesAdd)
      if (isPinRequired) {
        dispatch(requirePin(() => {
          history.push(profilesAddLocation)
        }))
        return
      }
      history.push(profilesAddLocation)
    },
    onEditProfile: ({
      history, location, dispatch, isPinRequired
    }) => (profileId) => {
      const profilesEditLocation = getModalLocation(location, MODALS.qsParams.profilesEdit, {
        profileId
      })
      if (isPinRequired) {
        dispatch(requirePin(() => {
          history.push(profilesEditLocation)
        }))
        return
      }
      history.push(profilesEditLocation)
    },
    onCancelClick: ({ setDisplayConfirmationModal }) => () => {
      setDisplayConfirmationModal(false)
    },
    onDeleteProfile: ({
      dispatch,
      setDisplayConfirmationModal,
      setSelectedProfileId,
      isPinRequired
    }) => (profileId) => {
      if (isPinRequired) {
        dispatch(requirePin(() => {
          setSelectedProfileId(profileId)
          setDisplayConfirmationModal(true)
        }))
        return
      }
      setSelectedProfileId(profileId)
      setDisplayConfirmationModal(true)
    },
    setDisplayConfirmationModal: ({ setDisplayConfirmationModal }) => (display) => {
      setDisplayConfirmationModal(display)
    },
    onConfirmClick: ({ deleteProfile, setDisplayConfirmationModal, selectedProfileId }) => () => {
      deleteProfile(selectedProfileId)
      setDisplayConfirmationModal(false)
    },
    onDoneClick: ({ history, location }) => () => {
      if (path(['state', 'from'], location)) {
        history.goBack()
      } else {
        history.push(location.pathname)
      }
    }
  })
)

export default enhance(ManageProfilesContainer)
