import React from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import { graphql } from 'react-apollo'
import {
  compose,
  withProps,
  branch,
  renderComponent,
  withState,
  pure,
  withHandlers
} from 'recompose'
import { Helmet } from 'react-helmet'

import PasswordReset from '../../components/password-reset/reset'
import PasswordResetSent from '../../components/password-reset/reset-sent'
import PasswordResetError from '../../components/password-reset/reset-expired'

import { LoadingModal } from '../../components/loading/modal'
import { getModalLocation } from '../../lib/modal'

import { MODALS } from '../../constants'

import VALIDATE_PASSWORD_RESET_TOKEN_QUERY from '../../../graphql/queries/validate-password-reset-token.gql'

const PasswordForgottenContainer = ({
  validationQuery,
  isRequestDone,
  ...rest
}) => {
  const brand = 'NEON'
  const title = `Set password - ${brand}`

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {
        validationQuery.error && (
          <PasswordResetError
            {...rest}
          />
        )
      }
      {
        validationQuery.validatePasswordResetToken && !isRequestDone && (
          <PasswordReset
            {...rest}
          />
        )
      }
      {
        isRequestDone && (
          <PasswordResetSent
            {...rest}
          />
        )
      }
    </div>
  )
}

PasswordForgottenContainer.propTypes = {
  validationQuery: PropTypes.shape({
    error: PropTypes.oneOfType([PropTypes.object]),
    validatePasswordResetToken: PropTypes.bool
  }).isRequired,
  isRequestDone: PropTypes.bool.isRequired
}

// ?email=mail%40pedroafonso.net&token=M5A3-C2kr-xhRS-uEBt-00M4-UtqB-UK
const enhance = compose(
  withProps(({ location }) => {
    const parsedSearch = qs.parse(location && location.search)
    const email = parsedSearch && parsedSearch.email
    const passwordResetToken = parsedSearch && parsedSearch.token

    return {
      email,
      passwordResetToken,
      passwordForgottenLocation: getModalLocation(location, MODALS.qsParams.passwordForgotten)
    }
  }),
  graphql(VALIDATE_PASSWORD_RESET_TOKEN_QUERY, { // validates password reset token and email
    name: 'validationQuery',
    options: ({ email, passwordResetToken }) => ({
      variables: {
        email,
        passwordResetToken
      }
    })
  }),
  branch(
    ({ accountQuery }) => accountQuery && accountQuery.loading,
    renderComponent(LoadingModal)
  ),
  withState('isRequestDone', 'setIsRequestDone', false),
  withHandlers({
    onLoginClick: ({ history, location }) => () => {
      history.push(getModalLocation(location, MODALS.qsParams.login))
    }
  }),
  pure
)

export default enhance(PasswordForgottenContainer)
