import React from 'react'
import qs from 'query-string'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactModal from 'react-modal'
import { pathEq, pathOr } from 'ramda'
import { withRouter, matchPath, useHistory } from 'react-router-dom'
import {
  compose, withProps, withHandlers, withStateHandlers
} from 'recompose'
import { ThemeProvider } from 'react-jss'

import {
  MODALS,
  THEME_OPTIONS,
  FLEXIBLE_SIZE_MODALS,
  LARGE_SIZE_MODALS
} from '../../../constants'
import { getModalComponent, getModalTheme, MODALS_NOT_SHOW_AGAIN_NAVIGATE_BACK } from './modals'
import themeStyles from '../../../theme'
import styles from './modal.css'

const getModalFromPath = location => {
  const parsedLocation = matchPath(location.pathname, {
    path: '/movie/:movieSlug/:modal?'
  })

  if (pathEq(['params', 'modal'], 'rent', parsedLocation)) {
    // TODO: Ideally this would use a dictionary, like exists for ?modal={...}
    // That said, it's not worth it for a single use case.
    return {
      modal: MODALS.qsParams.loginRental,
      modalCloseTarget: `/movie/${parsedLocation.params.movieSlug}`
    }
  }

  return {
    modal: null,
    modalCloseTarget: location.pathname
  }
}

const getModalFromSearch = location => {
  // Modal component will otherwise look for a ?modal=name query string
  // parameter in react-router location object and will render
  const parsedSearch = qs.parse(location && location.search)

  return {
    modal: pathOr(null, ['modal'], parsedSearch),
    modalCloseTarget: location.pathname
  }
}

// We're now mixing two different ways of determining whether a URL should
// display a modal. Rather than mush them into one function,
// let's break them up so we can still read this thing.
const getModalParameter = location => {
  const modalParameter = getModalFromPath(location)

  if (modalParameter.modal) {
    return modalParameter
  }

  return getModalFromSearch(location)
}

const enhance = compose(
  // Adds react-router location and history objects
  withRouter,
  // Adds props for modal config
  withProps(({ location }) => {
    let { modalCloseTarget } = getModalParameter(location)
    let modalCloseTargetSearch = ''
    const { modal } = getModalParameter(location)
    if (modal === 'login' && location.pathname.includes('my-account')) modalCloseTarget = '/'
    // reset modalCloseTarget when open login modal in sign up pages
    // to prevent missing other search params
    const isInSignupFlow = location.pathname.includes('signup')
    if (isInSignupFlow) {
      const search = qs.parse(location.search)
      const { plan } = search
      const searchObject = { ...search }
      const { modal: loginModal, ...otherParams } = searchObject
      if (plan) {
        modalCloseTargetSearch = qs.stringify(otherParams)
      }
    }
    return {
      modal,
      modalCloseTarget,
      modalCloseTargetSearch,
      isOpen: Boolean(modal),
      className: {
        base: styles.modal,
        afterOpen: styles.modalAfterOpen,
        beforeClose: styles.modalBeforeClose
      },
      overlayClassName: {
        base: styles.overlay,
        afterOpen: styles.overlayAfterOpen,
        beforeClose: styles.overlayBeforeClose
      },
      shouldCloseOnOverlayClick: false,
      doNotShowAgainWhenNavigateBack: MODALS_NOT_SHOW_AGAIN_NAVIGATE_BACK.includes(modal)
    }
  }),
  // Adds onRequestClose prop to handle modal dismiss
  withStateHandlers(
    () => ({
      customCloseHandler: null
    }),
    {
      setOnCloseHandler: () => handler => ({
        customCloseHandler: handler
      })
    }
  ),
  withHandlers({
    onRequestClose: ({
      customCloseHandler,
      history,
      modalCloseTarget,
      modalCloseTargetSearch,
      doNotShowAgainWhenNavigateBack
    }) => () => {
      if (customCloseHandler) {
        customCloseHandler()
      } else if (doNotShowAgainWhenNavigateBack) {
        /**
        * We can't remove history, so we first replace the current history by removing the query string, e.g.
        * "/movie/the-hangover-part-ii?modal=login-rental" becomes "/movie/the-hangover-part-ii",
        * then go back one history automatically, otherwise user have to click the browser back twice
        */
        history.replace({
          ...location,
          search: null
        })
        history.goBack()
      } else {
        history.push({
          pathname: modalCloseTarget,
          search: modalCloseTargetSearch
        })
      }
    }
  })
)

const CloseIcon = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 0 L24 24z" />
    <path d="M24 0 L0 24z" />
  </svg>
)

CloseIcon.propTypes = {
  className: PropTypes.string.isRequired
}

export default enhance(({ ...props }) => {
  const { modal } = props
  const Component = modal && getModalComponent(modal)
  const theme = getModalTheme(modal)
  const history = useHistory()
  const flexibleSizeModal = FLEXIBLE_SIZE_MODALS.filter(item => history.location?.search.includes(`modal=${item}`))
  const largeSizeModal = LARGE_SIZE_MODALS.filter(item => history.location?.search.includes(`modal=${item}`))

  const applyTheme = (...classes) => classNames(...classes, {
    [styles.darkTheme]: theme === THEME_OPTIONS.dark
  })

  return (
    <ReactModal contentLabel="lbx_modal" {...props}>
      <div
        className={applyTheme(styles.bodyWrapper, flexibleSizeModal.length > 0 && styles.flexibleSizeModal, largeSizeModal.length > 0 && styles.largeSizeModal)}
      >
        <div className={styles.headline}>
          <button
            type="button"
            data-lbx-e2e="modal-close-button"
            className={styles.modalCloseIcon}
            onClick={props.onRequestClose}
          >
            <CloseIcon className={applyTheme(styles.closeIcon)} />
          </button>
        </div>
        <div className={styles.modalBody}>
          <ThemeProvider theme={themeStyles}>
            {Component && React.isValidElement(<Component />) ? (
              <Component {...props} />
            ) : (
              `${modal} is not defined in modals array.`
            )}
          </ThemeProvider>
        </div>
      </div>
    </ReactModal>
  )
})
