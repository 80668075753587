import {
  compose,
  setDisplayName,
  branch,
  renderComponent,
  withState
} from 'recompose'
import { graphql } from 'react-apollo'

import SubscriptionSelection from '../../../../../components/modals/add-voucher/neon/step-two/subscription-selection-view'
import { LoadingModal } from '../../../../../components/loading/modal'

import { withSubscriptionMutation } from './subscription-selection-view-graphql'
import { withSubscriptionSelectionViewLifecycle } from './subscription-selection-view-lifecycle'

import VOUCHER_WITHOUT_SKU_QUERY from '../../../../../../graphql/queries/voucher-without-sku.gql'

const enhance = compose(
  setDisplayName('SubscriptionSelectionViewContainer'),
  graphql(VOUCHER_WITHOUT_SKU_QUERY, {
    name: 'voucherWithoutSkuQuery',
    options: ({ payload: { voucher } }) => {
      return {
        variables: {
          voucherCode: voucher
        },
        fetchPolicy: 'network-only'
      }
    },
    props: ({
      ownProps,
      voucherWithoutSkuQuery: {
        loading: voucherQueryLoading,
        error: voucherQueryError,
        voucherWithoutSku: voucherDetails
      }
    }) => ({
      ...ownProps,
      voucherQueryLoading,
      voucherQueryError,
      voucherDetails
    })
  }),
  withState('subscribingToPlan', 'startSubscribing', false),
  withSubscriptionMutation,
  withSubscriptionSelectionViewLifecycle,
  branch(
    ({
      voucherQueryLoading,
      voucherQueryError,
      voucherDetails,
      subscribingToPlan
    }) => voucherQueryLoading ||
    voucherQueryError ||
    voucherDetails == null ||
    subscribingToPlan,
    renderComponent(LoadingModal)
  )
)

export default enhance(SubscriptionSelection)
