import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { LoadingModal } from '../../../../../components/loading/modal'
import { useStyles } from './payment-method-iframe-content.styles'

const PaymentMethodIframeContent = ({ loading, iframeUrl, iframeRef }) => {
  const styles = useStyles()

  if (loading) {
    return <LoadingModal />
  }

  return (
    <iframe
      ref={iframeRef}
      src={iframeUrl}
      className={classNames(styles.iframe)}
      data-e2e="my-account-add-card-payment-iframe"
    />
  )
}

PaymentMethodIframeContent.propTypes = {
  loading: PropTypes.bool.isRequired,
  iframeUrl: PropTypes.string,
  iframeRef: PropTypes.func
}

export default PaymentMethodIframeContent
