import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import { propEq, prop } from 'ramda'
import classNames from 'classnames'

import ProfileAvatar from './profile-avatar'

import styles from './profile.css'
import PROFILE_FRAGMENT from '../../../../graphql/fragments/profile.gql'

function Profile({
  profile,
  currentProfile,
  manage,
  onEditProfile,
  onDeleteProfile,
  onAvatarClick,
  isEditActive,
  onActiveEdit,
  onDeactiveEdit,
  isProfileDisabled
}) {
  const isNewKidProfile = profile.isKid && profile.needToConfigure
  const isEditButtonShown =
    (onEditProfile || isNewKidProfile) &&
    manage &&
    isEditActive &&
    !isProfileDisabled
  const isDeleteButtonShown =
    onDeleteProfile &&
    (manage || isNewKidProfile) &&
    !profile.needToConfigure &&
    isEditActive &&
    !isProfileDisabled
  const setupButtonClass = profile.needToConfigure ? styles.setup : ''

  const editButton = (
    <button
      key="edit_button"
      className={classNames(styles.editButton, setupButtonClass)}
      onClick={onEditProfile}
    >
      {isNewKidProfile ? 'Configure' : 'Edit'}
    </button>
  )

  const deleteButton = (
    <button
      key="delete_button"
      aria-label="Delete"
      className={styles.deleteButton}
      onClick={onDeleteProfile}
    />
  )

  const profileAvatarClassName = classNames({
    [styles.selected]: propEq('id', prop('id', profile), currentProfile)
  })

  const controls =
    (
      <div className={styles.controlWrapper}>
        {isEditButtonShown && editButton}
        {isDeleteButtonShown && deleteButton }
      </div>
    )

  return (
    <div
      className={styles.wrapper}
      onMouseEnter={onActiveEdit}
      onMouseLeave={onDeactiveEdit}
      onClick={onActiveEdit}
    >
      {onAvatarClick
        // eslint-disable-next-line jsx-a11y/no-static-element-interactions
        ? (
          <div
            className={classNames(
              styles.avatar,
              styles.avatarClickable,
              styles.hoverCircle
            )}
            data-lbx-e2e={`profile-selector-target:${profile.name}`}
            onClick={(event) => {
              onActiveEdit(event)
              onAvatarClick(event)
              event.stopPropagation()
            }}
          >
            <ProfileAvatar
              className={profileAvatarClassName}
              uri={isProfileDisabled ? profile.avatar.uriForDisabledProfile : profile.avatar.uri}
            />
          </div>
        )
        : (
          <div
            className={classNames(styles.avatar,
              {
                [styles.hoverOverlay]: isEditButtonShown && isEditActive,
                [styles.hoverCircle]: !isEditButtonShown
              })}
          >
            <ProfileAvatar
              className={profileAvatarClassName}
              uri={isProfileDisabled ? profile.avatar.uriForDisabledProfile : profile.avatar.uri}
            />
          </div>
        )}
      <div className={styles.name}>{profile.name}</div>
      {controls}
    </div>
  )
}

Profile.propTypes = {
  profile: propType(PROFILE_FRAGMENT).isRequired,
  currentProfile: propType(PROFILE_FRAGMENT),
  manage: PropTypes.bool,
  onEditProfile: PropTypes.func,
  onDeleteProfile: PropTypes.func,
  onAvatarClick: PropTypes.func,
  isEditActive: PropTypes.bool,
  onActiveEdit: PropTypes.func,
  onDeactiveEdit: PropTypes.func,
  isProfileDisabled: PropTypes.bool
}

Profile.defaultProps = {
  currentProfile: {},
  manage: false,
  onEditProfile: null,
  onDeleteProfile: null,
  onAvatarClick: null,
  isEditActive: false,
  isProfileDisabled: false,
  onActiveEdit: () => {
  },
  onDeactiveEdit: () => {
  }
}

export default Profile
