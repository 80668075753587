import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { Formik } from 'formik'

import Yup from '../../lib/yup'
import { getGQLErrorMsg } from '../../lib/apollo'

import PasswordForgottenForm from '../../components/password-reset/request-form'

import withMutateAndLoading from '../../hoc/with-mutate-and-loading'

import { FORM_MESSAGES } from '../../constants'
import RESET_PASSWORD_REQUEST_MUTATION from '../../../graphql/mutations/request-password-reset.gql'

const enhance = compose(
  withRouter,
  graphql(RESET_PASSWORD_REQUEST_MUTATION),
  withMutateAndLoading, // add mutateWithLoading with loading screen handling
  connect(
    () => ({}),
    dispatch => ({
      dispatch
    })
  ),
  withHandlers({
    onCancel: ({ history, location }) => () => {
      history.push(location.pathname)
    }
  }),
  Formik({
    validateOnChange: true,
    // Define the form's validation schema with Yup.
    validationSchema: Yup.object().shape({
      email: Yup.string().email(FORM_MESSAGES.email.valid).required(FORM_MESSAGES.required)
    }),

    // Formik lets you colocate your submission handler with your form.
    // In addition to the values (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (values, { props, setError, setSubmitting }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setError('')
      const { mutateAndLoading } = props

      mutateAndLoading('passwordForgotten', {
        variables: {
          email: values.email
        }
      })
        .then((res) => {
          setSubmitting(false)
          if (res.data.requestPasswordReset) {
            props.onRequestSuccess(values.email)
          }
        })
        .catch((error) => {
          // display error
          setSubmitting(false)
          setError({
            errorMessage: getGQLErrorMsg(error),
            email: values.email
          })
        })
    }
  })
)

export default enhance(PasswordForgottenForm)
