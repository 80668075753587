import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import {
  compose,
  setPropTypes,
  defaultProps,
  branch,
  withProps,
  withHandlers,
  renderComponent,
  shouldUpdate
} from 'recompose'
import { pathOr, F } from 'ramda'
import { connect } from 'react-redux'

import SubscriptionConfirmationPromotion from '../../../components/subscriptions/neon/subscription-confirmation-promotion'
import SubscriptionConfirmationOrder from '../../../components/subscriptions/neon/subscription-confirmation-order'
import { LoadingModal } from '../../../components/loading/modal'
import { getModalLocation, getModalReturnTarget } from '../../../lib/modal'
import { checkQueryStatus } from '../../../lib/utils'
import { MODALS } from '../../../constants'
import { findDefaultTVODCreditCard } from '../../../lib/account'
import { subscriptionGa } from '../../../lib/analytics/ga'
import withSetCreditcardDefaultMutation from '../../../hoc/with-set-creditcard-default-mutation'

import SUBSCRIPTION_CONFIRMATION_VIEW_QUERY from '../../../../graphql/queries/subscription-confirmation-view.gql'
import ACCOUNT_QUERY_WITH_DIR from '../../../../graphql/queries/account-with-directives.gql'
import { withSubscriptionConfirmationLifeCycle } from './subscription-confirmation-handler'
import { getUserSubjectFromState } from '../../../selectors/session'

export const getCurrentSubscriptionName = accountQuery => {
  return pathOr('None', ['account', 'subscription', 'currentSubscription', 'name'], accountQuery)
}

export default compose(
  withRouter,
  withSetCreditcardDefaultMutation,
  defaultProps({
    orderId: '',
    paymentMethodId: '',
    voucherCode: ''
  }),
  setPropTypes({
    orderId: PropTypes.string,
    paymentMethodId: PropTypes.string,
    voucherCode: PropTypes.string
  }),
  // We need to prevent this entire container from re-rendering
  // after the default creditcard mutation, because we are subscribed
  // to account data changes after fetching the account data.
  shouldUpdate(F),
  graphql(SUBSCRIPTION_CONFIRMATION_VIEW_QUERY, {
    name: 'subscriptionConfirmationQuery',
    options: ({ orderId, paymentMethodId }) => {
      return {
        variables: {
          orderId,
          paymentMethodId
        },
        fetchPolicy: 'network-only'
      }
    }
  }),
  graphql(ACCOUNT_QUERY_WITH_DIR, {
    name: 'accountQuery',
    options: {
      fetchPolicy: 'network-only',
      variables: {
        withCreditcards: true,
        withSubscription: true
      }
    },
    props: ({ ownProps, accountQuery }) => ({
      ...ownProps,
      accountQuery,
      defaultTVODCreditCard: findDefaultTVODCreditCard(accountQuery.account)
    })
  }),
  branch(
    ({
      subscriptionConfirmationQuery,
      accountQuery
    }) => {
      return checkQueryStatus(subscriptionConfirmationQuery) || checkQueryStatus(accountQuery)
    },
    renderComponent(LoadingModal)
  ),
  withSubscriptionConfirmationLifeCycle,
  withProps(({ subscriptionConfirmationQuery }) => ({
    subscriptionConfirmation: subscriptionConfirmationQuery.subscriptionConfirmationView
  })),
  connect(state => ({
    gtmUserId: getUserSubjectFromState(state)
  })),
  branch(
    ({ subscriptionConfirmation }) => subscriptionConfirmation.__typename === 'SubscriptionConfirmationPromotionView',
    compose(
      withProps(({ subscriptionConfirmation, isSignupSubscriptionFlow }) => ({
        header: subscriptionConfirmation.header,
        message: subscriptionConfirmation.message,
        image: subscriptionConfirmation.image,
        cta: subscriptionConfirmation.cta,
        isSignupSubscriptionFlow
      })),
      withHandlers({
        onCtaClick: ({ accountQuery, history, location }) => () => {
          subscriptionGa({
            action: 'Confirmation',
            label: getCurrentSubscriptionName(accountQuery)
          })
          history.push(getModalReturnTarget(location))
        }
      }),
      renderComponent(SubscriptionConfirmationPromotion)
    ),
    compose(
      withProps(({ subscriptionConfirmation, isSignupSubscriptionFlow, gtmUserId }) => ({
        header: subscriptionConfirmation.header,
        subHeader: subscriptionConfirmation.subHeader,
        details: subscriptionConfirmation.details,
        features: subscriptionConfirmation.features,
        cta: subscriptionConfirmation.cta,
        isSignupSubscriptionFlow,
        gtmUserId
      })),
      withHandlers({
        onCtaClick: ({ accountQuery, history, location }) => () => {
          subscriptionGa({
            action: 'Confirmation',
            label: getCurrentSubscriptionName(accountQuery)
          })
          history.push(
            getModalLocation(
              location,
              MODALS.qsParams.profilesSwitch
            )
          )
        }
      }),
      renderComponent(SubscriptionConfirmationOrder)
    )
  )
)()
