import { useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/react-hooks'
import { isEmpty } from 'ramda'

import VALIDATE_PASSWORD from '../../../../../../../graphql/queries/validate-password.gql'
import Yup from '../../../../../../lib/yup'
import { FORM_MESSAGES, FORM_VALUES } from '../../../../../../constants'

const passwordSchema = Yup.object()
  .shape({
    password: Yup.string()
      .required(FORM_MESSAGES.required)
      .min(FORM_VALUES.password.min, FORM_MESSAGES.password.min)
  })
/**
 * Hook for taking charging of card picker item action
 * Once validate password correctly, it will subscribe according to url
 * @returns {{onBlur: onBlur, submitting: boolean, chargeInfo: (string), onChange: onChange, buttonOnClick: buttonOnClick, error: string, value: string}}
 */
export const useCreditCardPickerSubmit = (selectedPaymentId, onCompletePayment, setSubscriptionLoading) => {
  const [error, setError] = useState('')
  const [password, setPassword] = useState('')
  // Define each validation button state
  const [buttonClicked, setButtonClicked] = useState(false)

  const [validate, { loading: validationLoading, data: validateData, error: validateError }] = useLazyQuery(VALIDATE_PASSWORD)

  /**
   * Validate password
   */
  useEffect(() => {
    if (buttonClicked) {
      if (validateData && validateData.validatePassword) {
        setSubscriptionLoading(true)
        onCompletePayment(selectedPaymentId)
        return
      }
      if (validateError) {
        setError('Sorry! We don\'t recognise this password.')
        setButtonClicked(false)
      }
    }
  }, [validateData, validateError, validationLoading, selectedPaymentId, buttonClicked])

  const onChange = (event) => {
    const { target } = event
    setPassword(target.value)
    setError('')
  }

  const validateInput = (event) => {
    const { target } = event

    passwordSchema.validate({
      [target.name]: target.value
    })
      .catch(err => {
        setError(err.errors[0])
      })
  }

  const onBlur = e => {
    validateInput(e)
  }

  const buttonOnClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    validate({
      variables: {
        password
      },
      fetchPolicy: 'network-only'
    })
    setButtonClicked(true)
  }

  /**
   * Clear password input value and error
   */
  const clearInput = () => {
    setError('')
    setPassword('')
    setButtonClicked(false)
  }

  const chargeInfo = 'Your card will be charged immediately for the price listed above once you click "Complete Payment".'

  return {
    onChange,
    onBlur,
    buttonOnClick,
    buttonDisabled: isEmpty(password),
    validationLoading,
    error,
    value: password,
    chargeInfo,
    clearInput
  }
}
