import PropTypes from 'prop-types'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'query-string'
import Button from '../../forms/neon/button'
import styles from './subscription-voucher-warn.css'
import { MANAGE_PLAN_OPTIONS } from '../../../constants'
import exclamationWhiteSmall from '../../../../images/exclamation-white-small.svg'

const SubscriptionVoucherWarn = ({
  onRequestClose,
  manageSubsOptions
}) => {
  const history = useHistory()
  const location = useLocation()
  const { nextModal, plan } = qs.parse(location.search)

  // Go to the change plan process (payment modal)
  const goToChangePlanProcess = () => {
    history.replace({
      ...location,
      search: qs.stringify({
        modal: nextModal,
        plan
      })
    })
  }

  return (
    <div className={styles.subscriptionVoucherWarnModal}>
      <div>
        <img
          src={exclamationWhiteSmall}
          alt="exclamation"
        />
      </div>
      <h1 className={styles.header}>Cancel your Neon discount?</h1>
      {(manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.UPGRADE) || manageSubsOptions.includes(MANAGE_PLAN_OPTIONS.DOWNGRADE)) && (
        <div>
          <div className={styles.body}>
            Heads up, looks like you have a voucher applied to your account!
            <br />
            If you change your Neon plan today, you’ll lose any discounts applied to your current plan.
          </div>

          <div className={styles.buttonRow}>
            <Button
              onClick={goToChangePlanProcess}
              wrapperClassName={styles.buttonWrapper}
              className={styles.button}
              text="Yes, Change my plan"
            />
            <Button
              secondary
              onClick={onRequestClose}
              wrapperClassName={styles.buttonWrapper}
              className={styles.button}
              text="No, Keep my discount"
            />
          </div>
        </div>
      )}
    </div>
  )
}

SubscriptionVoucherWarn.propTypes = {
  onRequestClose: PropTypes.func.isRequired,
  manageSubsOptions: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default SubscriptionVoucherWarn
