import { path } from 'ramda'
import { lifecycle } from 'recompose'
import { getGQLErrorCode, getGQLErrorMsg } from '../../../../../lib/apollo'
import {
  MW_ERRORS,
  VOUCHER_VALIDATE_AFTER_LOGIN_WITH_PLAN
} from '../../../../../constants'
import { segmentTrackAddVoucher } from '../../../../../segment/segment-track'

export const withSubscriptionRedeemVoucherViewLifecycle = lifecycle({
  componentDidUpdate() {
    const { redeemVoucherViewError, orderId, setVoucherValidateError } = this.props
    setVoucherValidateError('')

    if (orderId) {
      this.props.renderStep(4, {
        orderId,
        voucher: path(['payload', 'voucher'], this.props),
        hasPlan: true
      })

      // Add segment data analytics for adding voucher
      segmentTrackAddVoucher({
        email: path(['account', 'email'], this.props),
        orderId,
        voucher: path(['payload', 'voucher'], this.props)
      })
    }
    if (redeemVoucherViewError) {
      const errorMsg = getGQLErrorMsg(redeemVoucherViewError)
      const errorCode = getGQLErrorCode(redeemVoucherViewError)

      if (errorCode === MW_ERRORS.VOUCHER_NOT_APPLICABLE) {
        setVoucherValidateError(errorMsg)
        // go to Step0 (VoucherValidateErrorModal) with ErrorMessage, type, and renderStep
        this.props.renderStep(0, {
          error: errorMsg,
          type: VOUCHER_VALIDATE_AFTER_LOGIN_WITH_PLAN,
          renderStep: this.props.renderStep
        })
      } else if (errorCode !== MW_ERRORS.INTERNAL_SERVER_ERROR) {
        // back to Step1 with ErrorMessage
        this.props.renderStep(1, {
          error: errorMsg
        })
      }
    }
  }
})
