import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../../signup/lib/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),

  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: 550
  },

  title: {
    textAlign: 'center',

    [theme.media.smallMobile]: {
      fontSize: 26,
      paddingTop: 10
    }
  },

  copy: {
    textAlign: 'center',
    paddingLeft: 10,
    paddingRight: 10
  },

  copyMessage: {
    textAlign: 'center',
    color: theme.neonGreen
  },

  red: {
    color: theme.error
  },

  divider: {
    width: '100%',
    height: 1,
    margin: [5, 0, 15],
    backgroundColor: theme.field
  },

  annualInfo: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: 1.5,
    textAlign: 'justify',
    marginBottom: 20,
    [theme.media.mobile]: {
      padding: [0, 10]
    },
    [theme.media.smallMobile]: {
      padding: [0, 10]
    }
  }
}))
