import { withRouter } from 'react-router-dom'
import { compose, withHandlers } from 'recompose'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import { Formik } from 'formik'

import Yup from '../../lib/yup'
import { getGQLErrorMsg } from '../../lib/apollo'
import { FORM_VALUES, FORM_MESSAGES } from '../../constants'

import PasswordResetForm from '../../components/password-reset/reset-form'

import withMutateAndLoading from '../../hoc/with-mutate-and-loading'

import RESET_PASSWORD_MUTATION from '../../../graphql/mutations/reset-password.gql'

const enhance = compose(
  withRouter,
  graphql(RESET_PASSWORD_MUTATION),
  withMutateAndLoading, // add mutateWithLoading with loading screen handling
  connect(
    () => ({}),
    dispatch => ({
      dispatch
    })
  ),
  withHandlers({
    onCancel: ({ history, location }) => () => {
      history.push(location.pathname)
    }
  }),
  Formik({
    validateOnChange: true,
    // Define the form's validation schema with Yup.
    validationSchema: Yup.object().shape({
      password: Yup.string().required(FORM_MESSAGES.required).min(
        FORM_VALUES.password.min,
        FORM_MESSAGES.password.min
      ),
      passwordConfirmation: Yup.string().required(FORM_MESSAGES.required)
        .sameAs(Yup.ref('password'), FORM_MESSAGES.password.match)
    }),

    // Map the field values to props if necessary. Used as empty strings for empty forms
    mapPropsToValues: () => ({
      password: ''
    }),

    // Define the payload object received in handleSubmit.
    mapValuesToPayload: values => ({
      password: values.password
    }),

    // Formik lets you colocate your submission handler with your form.
    // In addition to the payload (the result of mapValuesToPayload), you have
    // access to all props and some stateful helpers.
    handleSubmit: (payload, { props, setError, setSubmitting }) => {
      // e.preventDefault(), setSubmitting, setError(undefined) are
      // called before handleSubmit is. So you don't have to do repeat this.
      // handleSubmit will only be executed if form values pass Yup validation.
      setError('')
      const { mutateAndLoading, setIsRequestDone } = props

      mutateAndLoading('passwordForgotten', {
        variables: Object.assign(
          payload,
          {
            email: props.email,
            passwordResetToken: props.passwordResetToken
          }
        )
      })
        .then((res) => {
          setSubmitting(false)
          if (res.data.resetPassword) {
            setIsRequestDone(true)
          }
        })
        .catch((error) => {
          // display error
          setSubmitting(false)
          setError(getGQLErrorMsg(error))
        })
    }
  })
)

export default enhance(PasswordResetForm)
