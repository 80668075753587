import { graphql } from 'react-apollo'
import {
  compose,
  defaultProps,
  lifecycle,
  withHandlers,
  withProps
} from 'recompose'
import {
  path, pipe, prop, view, evolve
} from 'ramda'

import { parseDecimalInt } from '../../../../lib/utils'
import { lensSearch, pushQueryParams } from '../../../../lib/location'
import { getModalLocation } from '../../../../lib/modal'
import withIsAuthenticated from '../../../../hoc/is-authenticated'
import { MODALS } from '../../../../constants'

import SubscriptionRepair from '../../../../components/subscriptions/neon/subscription-repair'
import ACCOUNT_QUERY_WITH_DIR from '../../../../../graphql/queries/account-with-directives.gql'

export default compose(
  withIsAuthenticated,
  withProps(pipe(
    prop('location'),
    view(lensSearch),
    evolve({
      step: parseDecimalInt
    })
  )),
  defaultProps({
    step: 1
  }),
  graphql(ACCOUNT_QUERY_WITH_DIR, {
    skip: ({ isAuthenticated }) => !isAuthenticated,
    options: {
      variables: {
        withSubscription: true
      }
    },
    props: ({ ownProps, data: { error, loading, account } }) => ({
      ...ownProps,
      error,
      loading,
      activeSubscription: path(['subscription'], account),
      sku: path(['subscription', 'currentSubscription', 'sku'], account)
    })
  }),
  withProps(({ location }) => ({
    voucherLink: getModalLocation(location, MODALS.qsParams.addVoucher)
  })),
  withHandlers({
    dismissUpgradeScreen: ({ history, location }) => () => {
      pushQueryParams(history, location, {
        modal: undefined,
        step: undefined
      })
    },
    goToCancellationScreen: ({ history, location }) => () => {
      pushQueryParams(history, location, {
        step: 2
      })
    },
    goToResumeScreen: ({ history, location }) => () => {
      pushQueryParams(history, location, {
        step: 3
      })
    },
    onPaymentSubmission: ({ history, location }) => (orderId) => {
      let orderIdParam = orderId
      /**
       * TODO: This is only here until Evergent fixes their responses.
       * Evergent currently doesn't return an `orderId`
       * (which isn't strictly necessary, but is required by middleware to behave properly),
       * so we pass a nonsense `orderId`.
       * When Evergent fixes their API, we can remove this and depend on `orderId` again.
       * */
      if (!orderId) {
        orderIdParam = 'nonsense'
      }
      pushQueryParams(history, location, { step: 4, orderId: orderIdParam })
    }
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { isAuthenticated, onRequestClose } = this.props
      if (!isAuthenticated) {
        onRequestClose()
      }
    }
  })
)(SubscriptionRepair)
