import { graphql } from 'react-apollo'
import { omit } from 'ramda'
import SUBSCRIPTION_VOUCHER_VIEW_QUERY from '../../../../../../graphql/queries/subscription-voucher-view.gql'

export const withSubscriptVoucherQuery = graphql(
  SUBSCRIPTION_VOUCHER_VIEW_QUERY,
  {
    name: 'subscriptionVoucherViewQuery',
    props: handleProps
  }
)

function handleProps({
  ownProps: { renderStep, setOnCloseHandler, payload = {} },
  subscriptionVoucherViewQuery: {
    loading: voucherViewLoading,
    error: voucherViewError,
    subscriptionVoucherView
  }
}) {
  return {
    handleSubmitVoucher: voucher => {
      if (!voucher) return
      renderStep(2, { voucher, setOnCloseHandler })
    },
    ...payload,
    voucherViewLoading,
    voucherViewError,
    // LBXW-1618: For now, the `subHeader` prop needs to be hidden from this view
    ...omit(['subHeader', '__typename'], subscriptionVoucherView || {}),
    setOnCloseHandler
  }
}
