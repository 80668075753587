import React from 'react'

import ProfilesEditForm from '../../containers/profiles/profiles-edit-form'

import styles from './profiles-add.css'

function ProfilesEdit({ ...props }) {
  return (
    <div className={`manage-profiles ${styles.wrapper}`}>
      <ProfilesEditForm
        {...props}
        header={(
          <div className={styles.header}>
            <h1 className={styles.title}>Edit profile</h1>
            <span className={styles.subHeader}>
              You can set up your profile here.
            </span>
          </div>
        )}
        footer={(
          <div className={styles.footer}>
            <p>
              Looking for more options?
              <a href="/my-account" target="_blank" rel="noopener noreferrer">Account Settings</a>
            </p>
          </div>
        )}
      />
    </div>
  )
}

export default ProfilesEdit
