import { find, map, propEq } from 'ramda'

import { FORM_VALUES } from '../constants'

// Age groups
export const getAgeGroupOptions = map(ageGroup => ({
  label: ageGroup.label,
  value: ageGroup
}))

export const getDefaultParentalRating = find(propEq('rating', FORM_VALUES.addProfile.defaultParentalRating))

export const getParentalRatingOptions = map(parentalRating => ({
  label: parentalRating.label,
  value: parentalRating.rating
}))

export const getKidsParentalRatingOptions = (parentalRatings, maxRating) => {
  const ratingOptions = []
  // limit the ratings options to the max allowed for the age group
  parentalRatings.find((rating) => {
    ratingOptions.push({
      label: rating.label,
      value: rating.rating
    })
    return (rating.rating === maxRating)
  })
  return ratingOptions
}
