import {
  compose, lifecycle, withProps, withHandlers
} from 'recompose'
import { graphql } from 'react-apollo'
import {
  evolve,
  isNil,
  pipe,
  prop,
  view
} from 'ramda'

import withIsAuthenticated from '../../../../hoc/is-authenticated'
import { findDefaultSVODCreditCard } from '../../../../lib/account'
import { parseDecimalInt } from '../../../../lib/utils'
import { lensSearch, pushQueryParams } from '../../../../lib/location'
import ACCOUNT_QUERY from '../../../../../graphql/queries/account.gql'
import SUBSCRIBE_MUTATION from '../../../../../graphql/mutations/subscribe.gql'
import WelcomeBack from '../../../../components/subscriptions/neon/welcome-back'

const WelcomeBackContainer = compose(
  withIsAuthenticated,
  withProps(pipe(
    prop('location'),
    view(lensSearch),
    evolve({
      step: parseDecimalInt
    })
  )),
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    options: {
      fetchPolicy: 'cache-only'
    },
    skip: ({ isAuthenticated }) => !isAuthenticated,
    props: ({ ownProps, accountQuery: { error, loading, account } }) => ({
      ...ownProps,
      validCreditCard: account && findDefaultSVODCreditCard(account),
      accountQueryError: error,
      accountQueryLoading: loading,
      accountQueryData: account
    })
  }),
  graphql(SUBSCRIBE_MUTATION, {
    name: 'subscribeMutation'
  }),
  lifecycle({
    UNSAFE_componentWillMount() {
      const {
        isAuthenticated,
        onRequestClose
      } = this.props
      // Dismiss the modal if not logged in.
      if (!isAuthenticated) {
        onRequestClose()
      }
    },
    shouldComponentUpdate(nextProps) {
      // in case URL query '?modal=welcomeBack' is accessed directly, we close the pop-up automatically if user already have subs
      const { onRequestClose, accountQueryData } = nextProps
      if (accountQueryData && accountQueryData.subscription.currentSubscription != null) {
        onRequestClose()
      }
    }
  }),
  // TODO: to remove unused hanlders when we tidy up / remove unused welcome back steps(2-4)
  withHandlers({
    // There's really not much difference between these two, just
    // whether an empty `voucherCode` ends up in the query string
    selectPlanWithVoucher: ({ history, location }) => (voucherCode) => {
      pushQueryParams(history, location, {
        step: 2,
        voucherCode
      })
    },
    selectPlanNoVoucher: ({ history, location }) => () => {
      pushQueryParams(history, location, {
        step: 2
      })
    },
    onSubscriptionSelection: ({
      history, location, subscribeMutation, voucherCode
    }) => (subscriptionId, paymentMethodId) => {
      if (!isNil(paymentMethodId)) {
        return subscribeMutation({
          variables: {
            paymentMethodId,
            productId: subscriptionId,
            voucherCode
          }
        }).then(({ data: { subscribe: orderId } }) => {
          pushQueryParams(history, location, {
            step: 4,
            orderId,
            paymentMethodId
          })
        })
      }

      return pushQueryParams(history, location, {
        step: 3,
        subscriptionId
      })
    },
    onPaymentSubmission: ({ history, location }) => (orderId, eligiblePromotionId) => {
      return pushQueryParams(history, location, {
        orderId, // come from the iframe
        eligiblePromotionId, // come from the iframe
        step: 4
      })
    },
    onSkipSelection: ({ history, location }) => (subscriptionId) => {
      pushQueryParams(history, location, {
        step: 3,
        subscriptionId
      })
    }
  })
)(WelcomeBack)

export default WelcomeBackContainer
