import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { propType } from 'graphql-anywhere'
import { Helmet } from 'react-helmet'

import Subscription from './shared/subscription'
import SubscriptionTerms from './shared/subscription-terms'
import SubscriptionNoButton from './shared/subscription-no-button'
import SubscriptionSecondaryButton from './shared/subscription-secondary-button'

import SUBSCRIPTION_VIEW_FRAGMENT from '../../../graphql/fragments/subscription-view.gql'

import styles from './subscription-selection.css'
import { TESTERS } from '../../lib/tester-classnames'

function getSubscriptionComponent(subscription, direction) {
  if (direction === 'UPGRADE' && subscription.isActive) return SubscriptionNoButton
  if (direction === 'DOWNGRADE' && !subscription.isActive) return SubscriptionSecondaryButton
  return Subscription
}

function SubscriptionSelection({
  header,
  subHeader,
  subscriptions,
  footer,
  continueCta,
  onSubscriptionSelection,
  direction,
  onContinueCtaClick
}) {
  const brand = 'NEON'
  const title = `Select your plan - ${brand}`

  return (
    <div className={classNames(styles.wrapper, TESTERS.SIGNUP.SELECTION)}>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <h2>
        {header}
        <p>
          {subHeader}
          {direction === 'DOWNGRADE' && (
            <span> Your current plan is Lightbox Premium.</span>
          )}
        </p>
      </h2>
      <div className={styles.subscriptionsList}>
        {subscriptions.map(subscription => {
          const SubscriptionComponent = getSubscriptionComponent(
            subscription,
            direction
          )
          return (
            <SubscriptionComponent
              key={subscription.id}
              subscription={subscription}
              onSubscriptionSelection={onSubscriptionSelection}
            />
          )
        })}
      </div>
      {direction === 'UPGRADE' || direction === 'DOWNGRADE' ? null : (
        <div className={styles.footer}>
          <h2>{footer}</h2>
          {continueCta && (
            <div
              data-lbx-e2e="signup-continue-without-tv-plan"
              className={styles.cta}
              onClick={onContinueCtaClick}
            >
              {continueCta}
            </div>
          )}
        </div>
      )}
      {direction === 'UPGRADE' ? (
        <div className={styles.disclaimer}>
          By selecting Lightbox Premium you are agreeing to Lightbox’s
          {' '}
          <a href="/pages/terms">terms and conditions</a>
          .
        </div>
      ) : (
        <SubscriptionTerms />
      )}
    </div>
  )
}

SubscriptionSelection.propTypes = {
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string,
  subscriptions: PropTypes.arrayOf(propType(SUBSCRIPTION_VIEW_FRAGMENT))
    .isRequired,
  footer: PropTypes.string,
  continueCta: PropTypes.string,
  onSubscriptionSelection: PropTypes.func.isRequired,
  direction: PropTypes.string,
  onContinueCtaClick: PropTypes.func
}

SubscriptionSelection.defaultProps = {
  subHeader: '',
  footer: '',
  continueCta: '',
  direction: null,
  onContinueCtaClick() { }
}

export default SubscriptionSelection
