import {
  compose,
  setDisplayName,
  branch,
  renderComponent
} from 'recompose'
import { graphql } from 'react-apollo'
import { omit } from 'ramda'
import { connect } from 'react-redux'

import SubscriptionVoucherWarnView from '../../../../../components/modals/add-voucher/neon/step-two/subscription-voucher-warn-view'
import SubscriptionCreditcardView from '../../../../../components/subscriptions/neon/subscription-creditcard'
import { LoadingModal } from '../../../../../components/loading/modal'

import SUBSCRIPTION_VOUCHER_VIEW_QUERY from '../../../../../../graphql/queries/subscription-voucher-view.gql'
import { withRedeemVoucherViewQuery } from './subscription-redeem-voucher-view-graphql'
import { withSubscriptionRedeemVoucherViewLifecycle } from './subscription-redeem-voucher-view-lifecycle'

import { setVoucherValidateError } from '../../../../../actions'

const mapDispatchToProps = dispatch => ({
  setVoucherValidateError: (errorMsg) => {
    dispatch(setVoucherValidateError(errorMsg))
  },
  dispatch
})

const enhance = compose(
  setDisplayName('RedeemVoucherViewContainer'),
  connect(() => ({}), mapDispatchToProps),
  graphql(SUBSCRIPTION_VOUCHER_VIEW_QUERY, {
    name: 'voucherViewQuery',
    options: { fetchPolicy: 'cache-only' },
    // We need to reuse the voucher props from step one.
    // But we need to omit the header and subHeader fields.
    props: ({ voucherViewQuery }) => ({
      ...omit(
        ['header', 'subHeader', '__typename'],
        voucherViewQuery.subscriptionVoucherView
      )
    })
  }),
  withRedeemVoucherViewQuery,
  withSubscriptionRedeemVoucherViewLifecycle,
  branch(
    ({ redeemVoucherViewLoading, redeemVoucherViewError }) => redeemVoucherViewLoading || redeemVoucherViewError,
    renderComponent(LoadingModal)
  ),
  branch(
    ({ __typename }) => __typename === 'SubscriptionVoucherWarnView',
    renderComponent(SubscriptionVoucherWarnView),
    branch(
      ({ iframe: iframeUrl }) => iframeUrl == null,
      renderComponent(LoadingModal), // don't render the CC view if src is nil
      renderComponent(SubscriptionCreditcardView)
    )
  )
)

export default enhance()
