import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 30,
    [theme.media.desktop]: {
      minWidth: 1000
    }
  },

  title: {
    fontSize: 36,
    lineHeight: 1.08,
    fontWeight: 500,
    letterSpacing: 'normal',
    textAlign: 'center',
    margin: [15, 0]
  }
}))

export default useStyles
