import {
  compose, setDisplayName, branch, renderComponent
} from 'recompose'
import { graphql } from 'react-apollo'

import ACCOUNT_QUERY_WITH_DIR from '../../../../../../graphql/queries/account-with-directives.gql'

import { SUBSCRIPTION_STATUS } from '../../../../../constants'

import { LoadingModal } from '../../../../../components/loading/modal'

import SubscriptionSelectionView from './subscription-selection-view'
import SubscriptionRedeemVoucherView from './subscription-redeem-voucher-view'

/**
 * Checks the subscription information of the active subscription
 * and determines whether the subscription is eligible to redeem
 * a voucher without needing to go through a preliminary subscription signup process.
 * This is important because this determines how to go about validating the voucher.
 *
 * @param {Object} activeSubscription
 * @returns {boolean} - Returns true if ineligible
 */
function checkEligibility(activeSubscription) {
  return (
    activeSubscription.status === SUBSCRIPTION_STATUS.NONE ||
    activeSubscription.renewalStatus === SUBSCRIPTION_STATUS.NONE
  )
}

/**
 * For eligible subscriptions, we're validating the voucher with
 * the subscriptionRedeemVoucherView query, which returns a union of either
 * SubscriptionVoucherWarnView or SubscriptionCreditcardView
 *
 * For ineligible subscriptions, we're validating the voucher with
 * the `voucher` query first, and then taking the user through the signup flow.
 */
const enhance = compose(
  setDisplayName('StepTwoEligibleSubscriptionStatus'),
  graphql(ACCOUNT_QUERY_WITH_DIR, {
    options: {
      variables: {
        withCreditcards: true,
        withSubscription: true
      }
    },
    props: ({ ownProps, data: { loading, error, account } }) => ({
      ...ownProps,
      account,
      loading,
      error
    })
  }),
  branch(
    props => props.loading || props.error,
    renderComponent(LoadingModal),
    branch(
      ({ account }) => checkEligibility(account.subscription),
      renderComponent(SubscriptionSelectionView),
      renderComponent(SubscriptionRedeemVoucherView)
    )
  )
)

export default enhance()
