import React from 'react'
import PropTypes from 'prop-types'

import SubmitButton from '../forms/button'

import styles from './reset-sent.css'

function PinResetRequestSent({ onOkClick, isSubmitting }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>PIN is Reset</h1>
      </div>
      <p className={styles.description}>
        Your PIN has been updated successfully.
      </p>
      <SubmitButton
        className={styles.button}
        text="OK"
        disabled={isSubmitting}
        onClick={onOkClick}
      />
    </div>
  )
}

PinResetRequestSent.propTypes = {
  onOkClick: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired
}

export default PinResetRequestSent
