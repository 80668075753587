import PropTypes from 'prop-types'
import { pathOr } from 'ramda'
import { graphql } from 'react-apollo'
import {
  compose,
  setPropTypes,
  defaultProps,
  branch,
  renderComponent,
  withProps,
  lifecycle
} from 'recompose'

import { LoadingModal } from '../../../../components/loading/modal'
import Step2 from '../../../../components/subscriptions/neon/welcome-back/step-two'

import SUBSCRIPTION_SELECTION_VIEW_QUERY from '../../../../../graphql/queries/subscription-selection-view.gql'

const enhance = compose(
  defaultProps({
    voucherCode: ''
  }),
  setPropTypes({
    voucherCode: PropTypes.string
  }),
  graphql(SUBSCRIPTION_SELECTION_VIEW_QUERY, {
    name: 'subscriptionsQuery',
    options: ({ validCreditCard, voucherCode }) => {
      return {
        variables: {
          voucher: voucherCode,
          paymentMethodId: validCreditCard && validCreditCard.id,
          flow: 'ONBOARDING'
        },
        notifyOnNetworkStatusChange: true
      }
    }
  }),
  branch(
    ({ subscriptionsQuery }) => (
      subscriptionsQuery &&
      (subscriptionsQuery.loading || subscriptionsQuery.error)
    ),
    renderComponent(LoadingModal)
  ),
  withProps(({ subscriptionsQuery }) => ({
    header: pathOr('', ['subscriptionSelectionView', 'header'], subscriptionsQuery),
    subheader: pathOr('', ['subscriptionSelectionView', 'subHeader'], subscriptionsQuery),
    subscriptions: subscriptionsQuery.subscriptionSelectionView.subscriptions,
    subscriptionId: pathOr('', ['subscriptionSelectionView', 'subscriptionId'], subscriptionsQuery),
    footer: pathOr('', ['subscriptionSelectionView', 'footer'], subscriptionsQuery),
    continueCta: pathOr('', ['subscriptionSelectionView', 'continueCta'], subscriptionsQuery)
  })),
  branch(
    ({ subscriptionId }) => (
      subscriptionId
    ),
    lifecycle({
      UNSAFE_componentWillMount() {
        const { onSkipSelection } = this.props
        if (!onSkipSelection) {
          return
        }
        onSkipSelection(this.props.subscriptionId)
      }
    })
  )
)

export default enhance(Step2)
