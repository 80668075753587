import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import Button from '../../../forms/neon/button'

import SUBSCRIPTION_SUSPENDED_VIEW_FRAGMENT from '../../../../../graphql/fragments/subscription-suspended-view.gql'

import styles from './index.css'

const StepOne = ({
  goToCancellationScreen,
  goToResumeScreen,
  voucherLink,
  subscriptionSuspendedView: {
    voucherText,
    voucherLinkText,
    paymentText,
    paymentLinkText
  }
}) => {
  return (
    <div className={styles.wrapper}>
      <h1 className={styles.header}>Resume your Neon subscription</h1>
      <p className={styles.subHeader}>
        To continue watching TV, please update your payment details
      </p>
      <Button
        className={styles.button}
        text="Resume Subscription"
        onClick={goToResumeScreen}
      />
      <Button
        className={styles.button}
        text="Cancel Subscription"
        secondary
        onClick={goToCancellationScreen}
      />
      <div className={styles.actionLinks}>
        <p className={styles.actionText}>{paymentText}</p>
        <a
          className={styles.actionLink}
          href="https://help.neontv.co.nz/support/solutions/articles/64000203685-what-to-do-when-your-subscription-is-suspended"
          target="_blank"
          rel="noopener noreferrer"
        >
          {paymentLinkText}
        </a>
      </div>
    </div>
  )
}

StepOne.propTypes = {
  goToCancellationScreen: PropTypes.func.isRequired,
  goToResumeScreen: PropTypes.func.isRequired,
  voucherLink: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  subscriptionSuspendedView: propType(SUBSCRIPTION_SUSPENDED_VIEW_FRAGMENT).isRequired
}

export default StepOne
