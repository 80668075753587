import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import {
  branch, compose, renderComponent, setPropTypes
} from 'recompose'

import SUBSCRIPTION_SUSPENDED_VIEW from '../../../../../graphql/queries/subscription-suspended-view.gql'
import { LoadingModal } from '../../../../components/loading/modal'
import StepOne from '../../../../components/subscriptions/neon/subscription-repair/step-one'

const enhance = compose(
  setPropTypes({
    isAuthenticated: PropTypes.bool.isRequired
  }),
  graphql(SUBSCRIPTION_SUSPENDED_VIEW, {
    skip: ({ isAuthenticated }) => !isAuthenticated,
    props: ({ ownProps, data: { error, loading, subscriptionSuspendedView } }) => ({
      ...ownProps,
      error,
      loading,
      subscriptionSuspendedView
    })
  }),
  branch(
    ({ error, loading }) => (error || loading),
    renderComponent(LoadingModal)
  )
)

export default enhance(StepOne)
