import PropTypes from 'prop-types'
import { graphql } from 'react-apollo'
import {
  path,
  ifElse,
  pipe,
  pathEq
} from 'ramda'
import { withRouter, matchPath } from 'react-router-dom'
import {
  branch,
  compose,
  createSink,
  renderComponent,
  setPropTypes,
  withProps,
  withStateHandlers
} from 'recompose'

import LoginRental from '../../components/login/login-rental'
import withIsAuthenticated from '../../hoc/is-authenticated'
import { LoadingModal } from '../../components/loading/modal'
import { UUID_SLUG } from '../../constants'

import CONTENT_ITEM_QUERY from '../../../graphql/queries/content-item.gql'

const parseLocationPathname = pathname => matchPath(pathname, {
  path: '/:slug/:screenId'
})

const getContentItemIdFromLocation = pipe(
  ifElse(
    path(['state', 'detailsUrl']),
    path(['state', 'detailsUrl']),
    path(['pathname'])
  ),
  parseLocationPathname,
  ifElse(
    pathEq(['params', 'slug'], UUID_SLUG),
    path(['params', 'screenId']),
    path(['url'])
  )
)

const enhance = compose(
  withRouter,
  withIsAuthenticated,
  withProps(({ location }) => {
    return {
      /**
       * We're getting the `contentItemId` from either:
       * - `/movie/`
       * - `/uuid/`
       * - or from history push state as `detailsUrl`,
       */
      contentItemId: getContentItemIdFromLocation(location)
    }
  }),
  setPropTypes({
    contentItemId: PropTypes.string.isRequired,
    onRequestClose: PropTypes.func.isRequired
  }),
  /**
   * If `contentItemId` is null, we'll want to discontinue the flow altogether,
   * since we cannot proceed onto the payment view without it.
   */
  graphql(CONTENT_ITEM_QUERY, {
    name: 'contentItemQuery',
    skip: ({ contentItemId }) => contentItemId == null,
    options: ({ contentItemId }) => {
      return {
        variables: {
          id: contentItemId
        }
      }
    },
    props: ({
      ownProps,
      contentItemQuery: {
        loading: contentItemLoading,
        error: contentItemError,
        contentItem
      }
    }) => ({
      ...ownProps,
      contentItemLoading,
      contentItemError,
      contentItem
    })
  }),
  branch(
    ({ contentItemLoading, contentItemError }) => (
      contentItemLoading || contentItemError
    ),
    renderComponent(LoadingModal)
  ),
  branch(
    // Products may be null if this modal is opened for content other than a title
    ({ contentItem: { products } = {} }) => !products || !products.length,
    renderComponent(createSink(({ onRequestClose }) => {
      onRequestClose()
    }))
  ),
  withStateHandlers(({ isAuthenticated }) => ({
    step: !isAuthenticated ? 1 : 2,
    orderId: ''
  }), {
    onLoginSuccess: ({ step }) => () => ({
      step: step + 1
    }),
    onPaymentSubmission: ({ step }) => orderId => ({
      orderId,
      step: step + 1
    })
  })
)

export default enhance(LoginRental)
