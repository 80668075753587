import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),

  container: {
    maxWidth: 500,
    padding: [40, 20, 20, 20],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },

  text: {
    fontSize: 16,
    lineHeight: '2'
  },

  button: {
    width: 180
  }
}))
