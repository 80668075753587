import React from 'react'
import { NavLink } from 'react-router-dom'

import PlanDetails from '../../../signup/components/plan-details'
import VoucherMessages from '../../../signup/components/voucher-messages'

import useStyles from './subscription-confirmation-modal.styles'
import { useCommonStyles } from '../../../signup/lib/common.styles'
import { LoadingModal } from '../../../../components/loading/modal'
import { signupImage } from '../../../shared/subscription-constants'
import { useSubscriptionModalConfirmation } from '../../hooks/use-subscription-modal-confirmation'

/**
 * Component display payment confirmation in my account add subscription modal
 */
const SubscriptionConfirmationModal = React.memo(() => {
  const styles = useStyles()
  const commonStyles = useCommonStyles()

  const {
    accountLoading,
    loading,
    details,
    hasVoucher,
    hasActiveSubscription
  } = useSubscriptionModalConfirmation()

  const header = hasVoucher
    ? 'Your voucher is good to go!'
    : 'You\'re good to go!'

  return (
    <div className={styles.container}>
      {(loading || accountLoading) && (
        <LoadingModal />
      )}
      {!loading && hasActiveSubscription && (
        <>
          <h1 className={styles.title}>{header}</h1>
          <div className={commonStyles.container}>
            <PlanDetails detail={details} />

            {hasVoucher && <VoucherMessages />}

            <img
              src={signupImage}
              alt="Plan confirmation"
              className={commonStyles.image}
            />

            <div className={commonStyles.buttons}>
              <NavLink to="/" className={commonStyles.primaryButton}>
                Start Watching
              </NavLink>
            </div>
          </div>
        </>
      )}
    </div>
  )
})

export default SubscriptionConfirmationModal
