import {
  compose, setDisplayName, branch, renderComponent
} from 'recompose'

import SubscriptionPaymentView from './subscription-payment-view'
import SubscriptionCreditcardView from './subscription-creditcard-view'

const enhance = compose(
  setDisplayName('StepThreePaymentProcess'),
  branch(
    ({ payload: { addSubscriptionFlow } }) => addSubscriptionFlow,
    renderComponent(SubscriptionPaymentView),
    renderComponent(SubscriptionCreditcardView)
  )
)

export default enhance()
