import { connect } from 'react-redux'
import { compose, lifecycle } from 'recompose'

import { requirePin } from '../actions'
import { isPinValid } from '../lib/pin'
import { requirePinForManageProfile } from '../lib/account'

// Higher order component to manage PIN validation at the component rendering tree
// Required accountQuery in context,
// can use withIsAuthenticated or withIsAuthenticatedAndRedirect before
export default compose(
  connect(
    state => ({
      isPinRequired: !isPinValid(state)
    }),
    dispatch => ({
      dispatch
    }),
    ({ isPinRequired }, dispatch, ownProps) => {
      return {
        ...dispatch,
        ...ownProps,
        isPinRequired: isPinRequired && requirePinForManageProfile(ownProps.accountQuery.account)
      }
    }
  ),
  lifecycle({
    UNSAFE_componentWillMount() {
      const { isPinRequired, dispatch } = this.props

      if (isPinRequired) {
        dispatch(requirePin())
      }
    }
  })
)
