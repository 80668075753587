import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../shared/common.styles'

const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  mediumScreenContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexWrap: 'nowrap',
    [theme.media.smallMobile]: {
      padding: [30, 20],
      maxWidth: 479
    },
    [theme.media.mobile]: {
      paddingTop: 16,
      width: '100%',
      maxWidth: 767
    },
    [theme.media.desktop]: {
      display: 'none'
    },
    [theme.media.tablet]: {
      paddingTop: 22,
      width: '85%',
      minWidth: 600,
      maxWidth: 1023
    },
    margin: [22, 0, 0, 0]
  },

  tableRowContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    [theme.media.desktop]: {
      maxWidth: 1120
    },
    width: '100%',
    gap: '10px'
  },

  tableRowTitle: {
    display: 'flex',
    flex: 2,
    paddingBottom: 38,
    [theme.media.mobile]: {
      paddingBottom: 30,
      flex: 0
    },
    [theme.media.smallMobile]: {
      paddingBottom: 22,
      flex: 0
    },
    [theme.media.tablet]: {
      flex: 0
    },
    flexDirection: 'column',
    alignItems: 'center'
  },

  tableRowTitleText: {
    fontSize: 22,
    [theme.media.smallMobile]: {
      fontSize: 16
    }
  },

  divider: {
    height: 1,
    margin: [15, 0, 15],
    backgroundColor: theme.field,
    width: '100%'
  },

  activeSubscription: {
    color: theme.neonGreen
  },

  selectPlanButton: {
    zIndex: theme.zIndexCardBadge + 1,
    margin: [70, 0, 50, 0],
    width: '55%',
    color: theme.black,
    backgroundColor: theme.neonGreen,
    opacity: 100
  }
}))

export default useStyles
