import { getConfigValue } from './getConfigValue'

/**
 * @returns {Object[]} Array of birth year options.
 *
 * @example
 * // Example of returned array
 * [
 * {lable: 2011, value: 2011}
 * {lable: 2010, value: 2010}
 * {lable: 2009, value: 2009}
 * ...
 * {lable: 1925, value: 1925}
 * ]
 */

const getBirthYearOptions = (configValues, isSignup) => {
  const getRange = (start, stop, step) => {
    return Array.from({ length: (stop - start) / step + 1 }, (_, i) => start + i * step)
  }

  const currentYear = (new Date()).getFullYear()
  const accountMaxAge = getConfigValue(configValues, isSignup ? 'ACCOUNT_SIGNUP_MAX_AGE' : 'PROFILE_MAX_AGE')
  const accountMinAge = getConfigValue(configValues, isSignup ? 'ACCOUNT_SIGNUP_MIN_AGE' : 'PROFILE_MIN_AGE')

  return getRange(currentYear - accountMinAge, currentYear - accountMaxAge, -1).map(value => ({
    label: value,
    value
  }))
}

export default getBirthYearOptions
