import React from 'react'

import styles from '../subscription-selection.css'

export default function SubscriptionTerms() {
  return (
    <div className={styles.disclaimer}>
      Downloads are only available on devices using iOS 10 and above or Android 5 and above.
    </div>
  )
}
