import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../forms/button'

import styles from './subscription-confirmation.css'
import { TESTERS } from '../../lib/tester-classnames'
import { dataLayerSignUp } from '../../lib/analytics/datalayer'

function SubscriptionConfirmationPromotion({
  cta,
  header,
  image,
  message,
  onCtaClick,
  orderId,
  isSignupSubscriptionFlow
}) {
  isSignupSubscriptionFlow && dataLayerSignUp(orderId)
  return (
    <div className={classNames(styles.wrapper, TESTERS.SIGNUP.CONFIRMATION_PROMOTION)}>
      <div className={styles.header}>{header}</div>
      <div className={styles.message}>{message}</div>
      <img alt="promotion" src={image} />
      <Button
        e2eIdentifier="signup-complete-order"
        className={styles.button}
        text={cta}
        onClick={onCtaClick}
      />
    </div>
  )
}

SubscriptionConfirmationPromotion.propTypes = {
  cta: PropTypes.string,
  header: PropTypes.string,
  image: PropTypes.string,
  message: PropTypes.string,
  onCtaClick: PropTypes.func.isRequired,
  orderId: PropTypes.string,
  isSignupSubscriptionFlow: PropTypes.bool
}

SubscriptionConfirmationPromotion.defaultProps = {
  cta: '',
  header: '',
  image: '',
  message: '',
  orderId: '',
  isSignupSubscriptionFlow: false
}

export default SubscriptionConfirmationPromotion
