import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import Profile from './shared/profile'
import AddProfile from './shared/add-profile'
import { useProfileIsDisabled } from '../../hooks/useProfileIsDisabled'

import styles from './profiles-list.css'

import PROFILE_FRAGMENT from '../../../graphql/fragments/profile.gql'
import NavLinkWithQuery from '../shared/nav-link-with-query'
import { MODALS, PROFILES, WARNING_CREATE_KIDS_WITH_NO_PROFILE_SLOT } from '../../constants'
import { getModalLocation } from '../../lib/modal'
import { showPlanUpgradePopup } from '../../actions'

function ProfilesList({
  profiles,
  currentProfile,
  onAddProfileClick,
  onConfigureProfile,
  onSwitchProfile,
  location,
  isKidsNoProfileSlot,
  isProfileAllowBasicUpsell,
  profileLimit
}) {
  const brand = 'NEON'
  const title = `Who’s watching ${brand}?`
  const dispatch = useDispatch()

  const showUpgradePopup = () => {
    dispatch(showPlanUpgradePopup())
  }

  const onProfileClick = (isProfileDisabled, profile) => {
    if (isProfileDisabled) {
      showUpgradePopup()
    } else {
      onSwitchProfile(profile)
    }
  }

  const onAddIconClick = () => {
    if (isProfileAllowBasicUpsell) {
      dispatch(showPlanUpgradePopup())
    } else {
      onAddProfileClick()
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <h3 className={styles.subTitle}>
          Create individual profiles and watchlists of your favourite shows
          for everyone in your household.
        </h3>
      </div>
      <div className={styles.profileList}>
        {
          profiles.map((profile, index) => {
            const isProfileDisabled = useProfileIsDisabled(isProfileAllowBasicUpsell, profileLimit, index)
            return (
              <div
                className={styles.profileListItem}
                key={profile.id}
              >
                <Profile
                  profile={profile}
                  onAvatarClick={() => onProfileClick(isProfileDisabled, profile)}
                  currentProfile={currentProfile}
                  onEditProfile={(profile.isKid && profile.needToConfigure)
                    ? () => onConfigureProfile(profile.id) : null}
                  manage
                  isProfileDisabled={isProfileDisabled}
                />
              </div>
            )
          })
        }
        {
          profiles.length < PROFILES.maxNumber && (
            <div className={styles.profileListItem}>
              <AddProfile
                onClick={onAddIconClick}
                isProfileAllowBasicUpsell={isProfileAllowBasicUpsell}
              />
            </div>
          )
        }

      </div>
      {isKidsNoProfileSlot && (<div className={styles.warningMessage}>{WARNING_CREATE_KIDS_WITH_NO_PROFILE_SLOT}</div>)}
      <NavLinkWithQuery
        to={getModalLocation(location, MODALS.qsParams.profilesManagement)}
        className={classnames(
          styles.manageProfileButton,
          {
            [styles.withWarning]: isKidsNoProfileSlot
          }
        )}
      >
        Manage Profiles
      </NavLinkWithQuery>
    </div>
  )
}

ProfilesList.propTypes = {
  profiles: PropTypes.arrayOf(propType(PROFILE_FRAGMENT)).isRequired,
  currentProfile: propType(PROFILE_FRAGMENT),
  onAddProfileClick: PropTypes.func.isRequired,
  onConfigureProfile: PropTypes.func.isRequired,
  onSwitchProfile: PropTypes.func,
  isKidsNoProfileSlot: PropTypes.bool,
  isProfileAllowBasicUpsell: PropTypes.bool,
  profileLimit: PropTypes.number
}

ProfilesList.defaultProps = {
  onSwitchProfile: null,
  currentProfile: {},
  isKidsNoProfileSlot: false,
  isProfileAllowBasicUpsell: false
}

export default ProfilesList
