import { compose } from 'recompose'
import { connect } from 'react-redux'

import { getAccountIdFromState } from '../../../selectors/session'
import SubscriptionVoucherWarn from '../../../components/subscriptions/neon/subscription-voucher-warn'
import withIsAuthenticated from '../../../hoc/is-authenticated'

const mapStateToProps = state => ({
  accountId: getAccountIdFromState(state),
  manageSubsOptions: state.manageSubsOptions
})

const SubscriptionVoucherWarnContainer = compose(
  withIsAuthenticated,
  connect(mapStateToProps)
)(SubscriptionVoucherWarn)

export default SubscriptionVoucherWarnContainer
