import React, { useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Button from '../../forms/neon/button'

import styles from './subscription-confirmation.css'
import { useCommonStyles } from '../../../modules/signup/lib/common.styles'
import { TESTERS } from '../../../lib/tester-classnames'
import { signup } from '../../../lib/analytics/events'
import { signupImage } from '../../../modules/shared/subscription-constants'

function SubscriptionConfirmationPromotion({
  cta,
  header,
  image,
  message,
  onCtaClick,
  orderId,
  isSignupSubscriptionFlow,
  gtmUserId
}) {
  useEffect(() => {
    if (!isSignupSubscriptionFlow) return

    if (orderId) {
      signup.subscriptionConfirmationSeen()
      signup.completeWithSubscription(gtmUserId, orderId, false)
    } else {
      signup.rentalOnlyConfirmationSeen()
      signup.completeWithoutSubscription(gtmUserId)
    }
  }, [])

  const commonStyles = useCommonStyles()

  return (
    <div className={classNames(styles.wrapper, TESTERS.SIGNUP.CONFIRMATION_PROMOTION)}>
      <div className={styles.header}>You&apos;re good to go, welcome to Neon!</div>
      <div className={styles.message}>Your account is ready to go. Now it&apos;s time to rent a movie!</div>
      <img
        src={signupImage}
        alt="Plan confirmation"
        className={commonStyles.image}
      />
      <Button
        e2eIdentifier="signup-complete-order"
        className={styles.button}
        text="Get started!"
        onClick={onCtaClick}
      />
    </div>
  )
}

SubscriptionConfirmationPromotion.propTypes = {
  cta: PropTypes.string,
  header: PropTypes.string,
  image: PropTypes.string,
  message: PropTypes.string,
  onCtaClick: PropTypes.func.isRequired,
  orderId: PropTypes.string,
  isSignupSubscriptionFlow: PropTypes.bool,
  gtmUserId: PropTypes.string.isRequired
}

SubscriptionConfirmationPromotion.defaultProps = {
  cta: '',
  header: '',
  image: '',
  message: '',
  orderId: '',
  isSignupSubscriptionFlow: false
}

export default SubscriptionConfirmationPromotion
