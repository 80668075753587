import { compose, mapProps, setDisplayName } from 'recompose'

import SubscriptionConfirmation from '../../../../../components/modals/add-voucher/neon/step-four/subscription-voucher-confirmation-view'

export const withMapProps = mapProps(({ payload: { voucher, orderId, hasPlan } }) => ({
  orderId,
  voucherCode: voucher,
  hasPlan
}))

// Need to pull the orderId out and into the confirmation container
const enhance = compose(
  setDisplayName('StepFourConfirmationView'),
  withMapProps
)

export default enhance(SubscriptionConfirmation)
