import React from 'react'
import PropTypes from 'prop-types'

import ProfileAvatar from './shared/profile-avatar'
import SubmitButton from '../forms/button'

import styles from './profiles-avatar-form.css'

function ProfilesAvatarForm({
  error,
  isSubmitting,
  selectedAvatar,
  avatars,
  onAvatarClick,
  onNextClick,
  onCancel: onCancelClick
}) {
  const safeId = (avatar) => { return avatar ? avatar.id : '' }
  return (
    <div className={`${styles.wrapper}`}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Choose your profile picture
        </h1>
        <span className={styles.subHeader}>
          You can change this at any time when you edit your profile
        </span>
      </div>
      <div className={styles.avatarList}>
        {
          avatars.map((avatar) => {
            const avatarClass =
              safeId(selectedAvatar) === avatar.id ? styles.selectedItem : styles.avatarListItem
            return (
              // eslint-disable-next-line jsx-a11y/no-static-element-interactions
              <div
                className={avatarClass}
                key={avatar.id}
                onClick={() => onAvatarClick(avatar)}
              >
                <ProfileAvatar uri={avatar.uri} />
              </div>
            )
          })
        }
      </div>
      <div className={styles.buttonWrapper}>
        <div className={styles.buttonContainer}>
          <div className={styles.saveWrapper}>
            <SubmitButton
              disabled={isSubmitting}
              text="Next"
              onClick={onNextClick}
              error={error}
            />
          </div>
          <div className={styles.cancelWrapper}>
            <SubmitButton
              disabled={isSubmitting}
              text="Cancel"
              onClick={onCancelClick}
              secondary
            />
          </div>
        </div>
      </div>
    </div>
  )
}

ProfilesAvatarForm.propTypes = {
  error: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  onAvatarClick: PropTypes.func.isRequired,
  onNextClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  // TODO: Validate these `avatar` fragment
  selectedAvatar: PropTypes.oneOfType([PropTypes.object]),
  avatars: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired
}

ProfilesAvatarForm.defaultProps = {
  error: '',
  selectedAvatar: null,
  isSubmitting: false
}

export default ProfilesAvatarForm
