import React from 'react'
import PropTypes from 'prop-types'

import useStyles from './subscription-selection-modal-medium-view.styles'
import { useSubscriptionModalSelection } from '../../hooks/use-subscription-modal-selection'
import { useSelectPlan } from '../../../signup/hooks/use-select-plan'
import SubscriptionPricesView from '../../../signup/components/subscription-prices-view/subscription-prices-view'
import SubscriptionContentsView from '../../../signup/components/subscription-contents-view/subscription-contents-view'
import SubscriptionCardContent from '../../../signup/components/subscription-card-content/subscription-card-content'
import { planDetailsForAvod } from '../../../shared/subscription-constants'

/**
 * Component display plan options in my account add subscription modal
 */
const SubscriptionSelectionModalMediumView = props => {
  const styles = useStyles()
  const { currentSubscription, oneWayChangeDirection } = props
  const { processSelection } = useSubscriptionModalSelection()
  const { activeSubscription, updateActiveSubscription } = useSelectPlan(currentSubscription)

  return (
    <div className={styles.mediumScreenContainer}>
      <div className={styles.tableRowTitle}>
        <div className={styles.tableRowTitleText}>{planDetailsForAvod.componentTitle}</div>
        <SubscriptionCardContent />
      </div>

      <div className={styles.tableRowContainer}>
        <SubscriptionPricesView
          activeSubscription={activeSubscription}
          updateActiveSubscription={updateActiveSubscription}
          currentSubscription={currentSubscription}
        />
      </div>

      <SubscriptionContentsView
        activeSubscription={activeSubscription}
        updateActiveSubscription={updateActiveSubscription}
        selectToNext={() => processSelection(activeSubscription.toUpperCase())}
        currentSubscription={currentSubscription}
        oneWayChangeDirection={oneWayChangeDirection}
        addSubscriptionFromSignup={false}
      />
    </div>
  )
}

SubscriptionSelectionModalMediumView.propTypes = {
  currentSubscription: PropTypes.string.isRequired,
  oneWayChangeDirection: PropTypes.string
}

SubscriptionSelectionModalMediumView.defaultProps = {
  currentSubscription: ''
}

export default SubscriptionSelectionModalMediumView
