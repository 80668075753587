import React from 'react'
import PropTypes from 'prop-types'
import Step1 from '../../../../containers/subscriptions/neon/welcome-back/step-one'
import Step2 from '../../../../containers/subscriptions/neon/welcome-back/step-two'
import Step3 from '../../../../containers/subscriptions/neon/welcome-back/step-three'
import SubscriptionConfirmation from '../../../../containers/subscriptions/neon/subscription-confirmation'

import styles from '../../../signup/neon/signup-subscriptions.css'

const SubscriptionResume = (props) => {
  const { step } = props
  const renderStep = () => {
    switch (step) {
      case 1:
        return (
          <Step1 {...props} />
        )
      case 2:
        return (
          <Step2 {...props} />
        )
      case 3:
        return (
          <Step3 {...props} />
        )
      case 4:
        return (
          <SubscriptionConfirmation {...props} />
        )
      default:
        return null
    }
  }
  return (
    <div className={styles.wrapper}>
      {renderStep()}
    </div>
  )
}

SubscriptionResume.propTypes = {
  step: PropTypes.number
}

SubscriptionResume.defaultProps = {
  step: 1
}

export default SubscriptionResume
