import React from 'react'
import PropTypes from 'prop-types'

import styles from './price-text.css'

function PriceText({ text }) {
  return (
    <div className={styles.wrapper}>
      <span className={styles.text}>{text}</span>
    </div>
  )
}

PriceText.propTypes = {
  text: PropTypes.string.isRequired
}

export default PriceText
