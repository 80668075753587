import React from 'react'
import ReactToggle from 'react-toggle'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './toggle.css'

function Toggle({
  label,
  id,
  name,
  checked,
  onChange,
  border,
  className
}) {
  return (
    <div
      className={classnames(
        styles.wrapper,
        className,
        {
          [styles.border]: border
        }
      )}
    >
      <span>{label}</span>
      <ReactToggle
        icons={false}
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />
    </div>
  )
}

Toggle.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  border: PropTypes.bool,
  className: PropTypes.string
}

Toggle.defaultProps = {
  label: '',
  id: '',
  checked: false,
  border: true,
  onChange: (evt) => { console.info('evt', evt) },
  className: ''
}

export default Toggle
