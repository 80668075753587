import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'
import classNames from 'classnames'

import Button from '../forms/button'

import styles from './subscription-confirmation.css'

import SUBSCRIPTION_PAYMENT_VIEW_DETAILS_FRAGMENT from '../../../graphql/fragments/subscription-payment-view-details.gql'
import { TESTERS } from '../../lib/tester-classnames'
import { dataLayerSignUp } from '../../lib/analytics/datalayer'

function SubscriptionConfirmationOrder({
  header,
  details,
  cta,
  onCtaClick,
  orderId,
  isSignupSubscriptionFlow
}) {
  isSignupSubscriptionFlow && dataLayerSignUp(orderId)
  return (
    <div className={classNames(styles.wrapper, TESTERS.SIGNUP.CONFIRMATION_ORDER)}>
      <div className={styles.header}>{header}</div>
      <div className={styles.confirmationBox}>
        <div className={styles.details}>
          {
            details.map(detail => (
              <div key={detail.name} className={styles.detail}>
                <div className={styles.detailLabel}>
                  {
                    detail.image
                      ? <img className={styles.detailImage} alt={detail.name} src={detail.image} />
                      : detail.name
                  }
                </div>
                <div className={styles.detailValue}>
                  {detail.value}
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <Button
        className={styles.button}
        text={cta}
        e2eIdentifier={cta}
        onClick={onCtaClick}
      />
    </div>
  )
}

SubscriptionConfirmationOrder.propTypes = {
  cta: PropTypes.string,
  header: PropTypes.string,
  details: PropTypes.arrayOf(propType(SUBSCRIPTION_PAYMENT_VIEW_DETAILS_FRAGMENT)),
  onCtaClick: PropTypes.func.isRequired,
  orderId: PropTypes.string,
  isSignupSubscriptionFlow: PropTypes.bool
}

SubscriptionConfirmationOrder.defaultProps = {
  header: '',
  details: [],
  cta: '',
  orderId: '',
  isSignupSubscriptionFlow: false
}

export default SubscriptionConfirmationOrder
