import React from 'react'
import PropTypes from 'prop-types'

import NeonButton from './neon-form-elements/button'
import styles from './reset-sent.css'

function PasswordResetRequestSent({ onLoginClick }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Success!
        </h1>
      </div>

      <p className={styles.description}>
        Your password was reset successfully.
      </p>

      <NeonButton
        className={styles.button}
        text="Login"
        onClick={onLoginClick}
      />
    </div>
  )
}

PasswordResetRequestSent.propTypes = {
  onLoginClick: PropTypes.func.isRequired
}

export default PasswordResetRequestSent
