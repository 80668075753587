import React from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'

import NeonVoucherSubscriptionValidated from '../../../../subscriptions/neon/neon-voucher-subscription-validated'
import { modalContent } from '../../../../../modules/shared/subscription-constants'
import { MODALS } from '../../../../../constants'
import { STORAGE_KEY_VOUCHER_APPLICABLE_PLANS } from '../../../../../storage-keys'
import styles from './subscription-selection-view.css'
import { usePlanChangeFlag } from '../../../../../hooks/usePlanChange'

function SubscriptionSelectionView({
  voucherDetails,
  onSubscriptionSelection
}) {
  const history = useHistory()
  const getPlanDetails = usePlanChangeFlag()

  sessionStorage.setItem(STORAGE_KEY_VOUCHER_APPLICABLE_PLANS, voucherDetails.applicablePlans?.toString())
  const defaultSubscriptions = [getPlanDetails.basic, getPlanDetails.standard, getPlanDetails.annual]
  const subscriptions = defaultSubscriptions.filter(sub => voucherDetails.applicablePlans.includes(sub.sku))

  if (subscriptions.length === 1) {
    return (
      <div className={styles.wrapper}>
        <Helmet>
          <title>Select your plan - NEON</title>
        </Helmet>

        <NeonVoucherSubscriptionValidated
          subscription={subscriptions[0]}
          onSubscriptionSelection={onSubscriptionSelection}
        />
      </div>
    )
  }

  if (subscriptions.length > 1) {
    // Nav to select plan modal
    history.replace({
      ...location,
      search: qs.stringify({
        content: modalContent.selection,
        currentSubscription: null,
        modal: MODALS.qsParams.addSubscription,
        voucher: voucherDetails.id
      })
    })
  }

  return null
}

SubscriptionSelectionView.propTypes = {
  voucherDetails: PropTypes.shape({
    id: PropTypes.string.isRequired,
    code: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    amount: PropTypes.string,
    couponType: PropTypes.string,
    planSku: PropTypes.string,
    applicablePlans: PropTypes.arrayOf(PropTypes.string),
    planId: PropTypes.string
  }).isRequired,
  onSubscriptionSelection: PropTypes.func.isRequired
}

export default SubscriptionSelectionView
