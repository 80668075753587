import { lifecycle } from 'recompose'
import { creditCardStatus } from '../../../../../modules/shared/subscription-constants'

export const withSubscriptionPaymentViewLifeCycle = lifecycle({
  componentDidUpdate(prevProps) {
    const {
      paymentViewError,
      iframe,
      orderId,
      setSavedOrderId,
      savedOrderId,
      setOnCloseHandler,
      renderStep,
      payload: { voucher, subscriptionId },
      creditcards,
      accountCreditCardsError,
      validCreditCards,
      setValidCreditCards,
      setSelectedCardPaymentId
    } = this.props

    if (isOrderIdReturnedWithoutPayment(orderId, savedOrderId)) {
      saveOrderIdForConfirmation({ setSavedOrderId, orderId })
      return
    }

    if (isPaymentViewError(paymentViewError, iframe, accountCreditCardsError)) {
      this.props.renderStep(1)
    }

    if (creditcards !== prevProps.creditcards) {
      setValidCreditCards(creditcards.filter(card => card.status === creditCardStatus.ACTIVE))
    }

    if (validCreditCards !== prevProps.validCreditCards && validCreditCards.length) {
      setSelectedCardPaymentId(validCreditCards[0].id)
    }

    if (isOrderVouherSubscriptionIdReturned(orderId, voucher, subscriptionId)) {
      setOnCloseHandler(() => {
        renderStep(4, { orderId, voucher })
      })
    }
  },
  componentWillUnmount() {
    this.props.setOnCloseHandler(null)
  }
})

const saveOrderIdForConfirmation = ({ setSavedOrderId, orderId }) => {
  setSavedOrderId(orderId)
}

const isOrderIdReturnedWithoutPayment = (orderId, savedOrderId) => (
  orderId && orderId !== savedOrderId
)

const isPaymentViewError = (paymentViewError, iframe, accountCreditCardsError) => {
  return paymentViewError || iframe === null || accountCreditCardsError
}

const isOrderVouherSubscriptionIdReturned = (orderId, voucher, subscriptionId) => {
  return orderId && voucher && subscriptionId
}
