import React from 'react'
import PropTypes from 'prop-types'

import Step1 from '../../../../containers/subscriptions/neon/subscription-repair/step-one'
import Step2 from '../../../../containers/subscriptions/neon/cancel'
import Step4 from '../../../../containers/subscriptions/neon/subscription-confirmation'
import Step5 from '../../../../containers/subscriptions/neon/subscription-repair/step-five'
import styles from '../../../signup/neon/signup-subscriptions.css'

const renderStep = (step, props) => {
  switch (step) {
    case 1:
      return <Step1 {...props} />
    case 2:
      return <Step2 {...props} />
    case 4:
      return <Step4 {...props} />
    case 5:
      return <Step5 {...props} />
    default:
      return null
  }
}

const SubscriptionRepair = ({ step, ...props }) => {
  return (
    <div className={styles.wrapper}>
      {renderStep(step, props)}
    </div>
  )
}

SubscriptionRepair.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired
}

export default SubscriptionRepair
