import React from 'react'
import { propType } from 'graphql-anywhere'

import Subscription from './subscription'

import SUBSCRIPTION_VIEW_FRAGMENT from '../../../../graphql/fragments/subscription-view.gql'

import styles from './subscription.css'

function SubscriptionNoButton({
  subscription
}) {
  return (
    <Subscription
      subscription={subscription}
      buttonStyle={styles.ctaNoButton}
    />
  )
}

SubscriptionNoButton.propTypes = {
  subscription: propType(SUBSCRIPTION_VIEW_FRAGMENT).isRequired
}

export default SubscriptionNoButton
