import React from 'react'
import PropTypes from 'prop-types'
import useStyles from './subscription-error-modal.styles'
import { useCommonStyles } from '../../../shared/common.styles'

const SubscriptionErrorModal = ({ errorMessage, onClick }) => {
  const styles = useStyles()
  const commonStyles = useCommonStyles()
  return (
    <div className={styles.container}>
      <h2 className={styles.subTitle}>
        Oops, something went wrong
      </h2>
      <div>
        {errorMessage}
      </div>
      <div className={styles.buttonContainer}>
        <div
          className={commonStyles.primaryButton}
          onClick={onClick}
        >
          Close
        </div>
      </div>
    </div>
  )
}

SubscriptionErrorModal.propTypes = {
  errorMessage: PropTypes.string,
  onClick: PropTypes.func
}

export default SubscriptionErrorModal
