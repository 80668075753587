import { useLocation, useHistory } from 'react-router-dom'
import qs from 'query-string'

import { VOUCHER_VALIDATE_FOR_SIGNUP, VOUCHER_VALIDATE_AFTER_LOGIN } from '../../../constants'
import { modalContent, links } from '../../shared/subscription-constants'

export const useVoucherValidateError = (type) => {
  const location = useLocation()
  const history = useHistory()

  const changePlanHandler = () => {
    if (type === VOUCHER_VALIDATE_FOR_SIGNUP) {
      // Keep the voucher, remove selected plan and close modal
      const { voucher } = qs.parse(location.search)

      history.replace({
        ...location,
        search: qs.stringify({
          voucher
        })
      })
    }

    if (type === VOUCHER_VALIDATE_AFTER_LOGIN) {
      history.push({
        ...location,
        search: qs.stringify({
          ...qs.parse(location.search),
          content: modalContent.selection
        })
      })
    }
  }

  /**
   * Handle skip button
   */
  const skipVoucherHandler = () => {
    if (type === VOUCHER_VALIDATE_FOR_SIGNUP) {
      // remove voucher and Close modal
      const { plan } = qs.parse(location.search)
      history.push({
        pathname: links.payment,
        search: qs.stringify({
          plan
        })
      })
    }

    if (type === VOUCHER_VALIDATE_AFTER_LOGIN) {
      history.push({
        ...location,
        search: qs.stringify({
          ...qs.parse(location.search),
          content: modalContent.payment
        })
      })
    }
  }

  return {
    changePlanHandler,
    skipVoucherHandler
  }
}
