import React from 'react'
import { useSelector } from 'react-redux'

import VoucherValidateErrorModal from '../../../components/modals/voucher-validate-error'
import { VOUCHER_VALIDATE_AFTER_LOGIN } from '../../../constants'

const VoucherValidateErrorModalAfterLogin = () => {
  const mapState = useSelector(state => state)
  const { voucherValidateError } = mapState

  return <VoucherValidateErrorModal error={voucherValidateError} type={VOUCHER_VALIDATE_AFTER_LOGIN} />
}

export default VoucherValidateErrorModalAfterLogin
