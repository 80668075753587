import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Imgix from '../../imgix'

import styles from './profile-avatar.css'

function ProfileAvatar({ uri, className }) {
  return (
    <div className={styles.avatar}>
      <Imgix
        className={classnames(className, styles.avatarImg)}
        alt="Avatar"
        src={uri}
        background
      />
    </div>
  )
}

ProfileAvatar.propTypes = {
  uri: PropTypes.string.isRequired,
  className: PropTypes.string
}

ProfileAvatar.defaultProps = {
  className: ''
}

export default ProfileAvatar
