import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import styles from './reset-expired.css'

function PasswordResetError({ passwordForgottenLocation }) {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <h1 className={styles.title}>
          Your previous attempt to reset your password has expired
        </h1>
      </div>

      <p className={styles.description}>
        Please submit another password reset email below.
      </p>

      <Link
        className={styles.button}
        to={passwordForgottenLocation}
      >
        Reset Password
      </Link>
    </div>
  )
}

PasswordResetError.propTypes = {
  passwordForgottenLocation: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string
  }).isRequired
}

export default PasswordResetError
