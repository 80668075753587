import { propOr } from 'ramda'
import * as qs from 'query-string'

export const getSearchProperty = (prop) => {
  return propOr(
    '',
    prop,
    // eslint-disable-next-line no-restricted-globals
    qs.parse(location && location.search)
  )
}
