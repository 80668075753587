import PropTypes from 'prop-types'
import {
  isNil, find as ramdaFind, pathEq, pathOr, pipe
} from 'ramda'
import { graphql, withApollo } from 'react-apollo'
import { withRouter } from 'react-router-dom'
import {
  compose,
  setPropTypes,
  defaultProps,
  branch,
  withProps,
  withHandlers,
  renderComponent,
  lifecycle
} from 'recompose'

import {
  MODALS,
  SUBSCRIPTION_NAMES
} from '../../constants'

import { getModalLocation } from '../../lib/modal'
import { checkQueryStatus } from '../../lib/utils'
import { findDefaultSVODCreditCard } from '../../lib/account'
import ConfirmationView from '../../components/rentals/confirmation-view'
import { LoadingModal } from '../../components/loading/modal'

import SUBSCRIPTION_MUTATION from '../../../graphql/mutations/subscribe.gql'
import RENTAL_CONFIRMATION_VIEW_QUERY from '../../../graphql/queries/rental-confirmation-view.gql'
import ACCOUNT_CREDIT_CARDS_QUERY from '../../../graphql/queries/account-credit-cards.gql'
import CONTENT_ITEM_QUERY from '../../../graphql/queries/content-item.gql'
import MY_RENTALS_QUERY from '../../../graphql/queries/my-rentals.gql'
import SUBSCRIPTION_SELECTION_VIEW_QUERY from '../../../graphql/queries/subscription-selection-view.gql'
import { segmentTrackCompletePurchase } from '../../segment/segment-track'

/**
 * Getting the first available card after refetching and confirming
 * that there's currently no default SVOD payment method available
 */
const findFirstAvailableCardId = pathOr('', ['account', 'creditcards', '0', 'id'])

const hasCouponForPremium = ({
  subscriptionSelectionView: {
    subscriptionId: selectedSubscriptionId,
    subscriptions
  }
}) => {
  return pipe(
    // Something was causing a global `find` to exist, allowing this to compile,
    // but causing problems at runtime.
    // Rename the function to prevent this issue for now.
    ramdaFind(pathEq(['id'], selectedSubscriptionId)),
    pathEq(['name'], SUBSCRIPTION_NAMES.PREMIUM)
  )(subscriptions)
}

const enhance = compose(
  withRouter,
  withApollo,
  defaultProps({
    orderId: '',
    contentItemId: ''
  }),
  setPropTypes({
    orderId: PropTypes.string,
    contentItemId: PropTypes.string.isRequired,
    isAuthenticated: PropTypes.bool.isRequired
  }),
  graphql(RENTAL_CONFIRMATION_VIEW_QUERY, {
    name: 'rentalConfirmationQuery',
    options: ({ orderId, contentItemId }) => {
      return {
        variables: {
          orderId,
          contentItemId
        },
        fetchPolicy: 'network-only'
      }
    }
  }),
  graphql(ACCOUNT_CREDIT_CARDS_QUERY, {
    name: 'accountCreditCardsQuery',
    options: {
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true
    },
    props: ({ ownProps, accountCreditCardsQuery }) => ({
      ...ownProps,
      accountCreditCardsQuery,
      defaultSVODCreditCard: findDefaultSVODCreditCard(accountCreditCardsQuery.account),
      firstAvailableCardId: findFirstAvailableCardId(accountCreditCardsQuery)
    })
  }),
  graphql(CONTENT_ITEM_QUERY, {
    name: 'contentItemQuery',
    skip: ({ contentItemId }) => {
      return !contentItemId
    },
    options: ({ contentItemId }) => {
      return {
        variables: {
          id: contentItemId
        }
      }
    }
  }),
  graphql(MY_RENTALS_QUERY, {
    name: 'myRentalsQuery'
  }),
  branch(
    ({
      rentalConfirmationQuery,
      accountCreditCardsQuery,
      contentItemQuery,
      myRentalsQuery
    }) => {
      return (
        checkQueryStatus(rentalConfirmationQuery) ||
        checkQueryStatus(accountCreditCardsQuery) ||
        checkQueryStatus(contentItemQuery) ||
        checkQueryStatus(myRentalsQuery)
      )
    },
    renderComponent(LoadingModal)
  ),
  lifecycle({
    componentDidMount() {
      // Now that the purchase has been completed, we update the cache so that
      // the total number of rentals is correct without having to refetch
      this.props.myRentalsQuery.updateQuery((previousResult) => {
        const totalCurrentRentals = previousResult.myRentalHistory.totalCurrentRentals + 1
        const items = previousResult.myRentalHistory.items.concat({
          contentItem: {
            __typename: 'Title',
            id: this.props.contentItemId
          },
          expired: false,
          __typename: 'MyRentalItem'
        })

        return {
          ...previousResult,
          myRentalHistory: {
            items,
            totalCurrentRentals,
            __typename: 'MyRentalHistory'
          }
        }
      })
      // Refetch contentItemQuery on route change to get the updated rentalInfo data
      this.props.contentItemQuery.refetch()

      // Add segment data analytics for completing rental purchase
      const contentItem = this.props.contentItemQuery?.contentItem
      if (contentItem) {
        const {
          title, isRental, ldId, products
        } = contentItem
        segmentTrackCompletePurchase({
          title,
          isRental,
          ldId,
          payment: Number(products[0]?.currentPrice)
        })
      }
    }
  }),
  withProps(({ rentalConfirmationQuery }) => ({
    header: pathOr('', ['rentalConfirmationView', 'header'], rentalConfirmationQuery),
    subHeader: pathOr('', ['rentalConfirmationView', 'subHeader'], rentalConfirmationQuery),
    image: pathOr('', ['rentalConfirmationView', 'image', 'uri'], rentalConfirmationQuery),
    notes: pathOr('', ['rentalConfirmationView', 'notes'], rentalConfirmationQuery),
    details: pathOr('', ['rentalConfirmationView', 'details'], rentalConfirmationQuery),
    cta: pathOr('', ['rentalConfirmationView', 'cta'], rentalConfirmationQuery),
    upsellSection: pathOr(null, ['rentalConfirmationView', 'upsell'], rentalConfirmationQuery)
  })),
  withHandlers({
    onUpsellCtaClick: ({
      client: apolloClient,
      history,
      defaultSVODCreditCard,
      firstAvailableCardId
    }) => (voucherCode) => {
      /**
       * The side effects of pressing of this button can vary significantly,
       * depending on whether a voucher code is supplied, and on the nature of the voucher code.
       * Since this gets a little nesty with various conditional branches, the code is annotated
       * according to conditions and potential outcomes.
       *
       * Yes. It _should_ be a huge pain in the neck to add new behavior to this.
       * There's too much already!

       * The decision tree is as follows.
       *
       * I. VOUCHER CODE IS SUPPLIED
       *   A. For a standard plan,
       *     1. bring the user to the subscription select view.
       *   B. For a premium plan,
       *     1. effect the actual subscription for the premium plan, then
       *     2. bring the user to the subscription confirmation view.
       * II. VOUCHER CODE IS NOT SUPPLIED
       *   A.1. Bring the user to the subscription select view.
       */
      const paymentMethodId = pathOr(firstAvailableCardId, ['id'], defaultSVODCreditCard)

      /* I */
      if (!isNil(voucherCode)) {
        return apolloClient.query({
          query: SUBSCRIPTION_SELECTION_VIEW_QUERY,
          variables: {
            voucher: voucherCode,
            paymentMethodId
          },
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          /* I.A */
          if (!hasCouponForPremium(data)) {
            return { voucher: voucherCode, paymentMethodId }
          }

          /* I.B */
          const { subscriptionSelectionView: { subscriptionId } } = data
          /* I.B.1 */
          return apolloClient.mutate({
            mutation: SUBSCRIPTION_MUTATION,
            variables: {
              productId: subscriptionId,
              voucher: voucherCode
            }
          })
            .catch(() => {
              return history.push(
                getModalLocation(
                  location,
                  MODALS.qsParams.subscriptionSelectionUpsell,
                  {
                    subscriptionId,
                    voucher: voucherCode,
                    paymentMethodId
                  }
                )
              )
            })
            .then(({ data: { subscribe: orderId } }) => ({
              orderId,
              subscriptionId,
              voucher: voucherCode,
              paymentMethodId
            }))
        }).then((queryParams) => {
          /* I.A.1 */
          /* I.B.2 */
          /**
           * Both paths lead here.
           * If I.B is followed, `orderId` and `subscriptionId` will be present,
           * and the user will be forwarded directly to the confirmation screen.
           */
          return history.push(
            getModalLocation(
              history.location,
              MODALS.qsParams.subscriptionSelectionUpsell,
              queryParams
            )
          )
        })
      }

      /* II.A.1 */

      return history.push(
        getModalLocation(
          location,
          MODALS.qsParams.subscriptionSelectionUpsell,
          {
            voucher: voucherCode,
            paymentMethodId
          }
        )
      )
    }
  })
)

export default enhance(ConfirmationView)
