import React from 'react'
import PropTypes from 'prop-types'
import Button from '../../../../forms/neon/button'
import { sanitizeDom } from '../../../../../utils'

import styles from './subscription-voucher-warn-view.css'

function SubscriptionVoucherWarnView({
  header,
  subHeader,
  message,
  cta,
  cancelCta,
  redeemThisVoucher,
  onCancelCta
}) {
  return (
    <div className={styles.wrapper}>
      {
        header && <h1 className={styles.header}>{header}</h1>
      }
      {
        subHeader && (
          <div
            className={styles.subHeading}
            dangerouslySetInnerHTML={{ __html: sanitizeDom(subHeader) }} // eslint-disable-line react/no-danger
          />
        )
      }
      <p className={styles.message}>
        {message}
      </p>
      <div className={styles.buttons}>
        <Button
          wrapperClassName={styles.buttonWrapper}
          className={styles.btn}
          text={cta}
          onClick={redeemThisVoucher}
        />
        <Button
          wrapperClassName={styles.buttonWrapper}
          className={styles.btn}
          text={cancelCta}
          outlined
          onClick={onCancelCta}
        />
      </div>
    </div>
  )
}

SubscriptionVoucherWarnView.propTypes = {
  header: PropTypes.string,
  subHeader: PropTypes.string,
  message: PropTypes.string.isRequired,
  cta: PropTypes.string.isRequired,
  cancelCta: PropTypes.string.isRequired,
  redeemThisVoucher: PropTypes.func.isRequired,
  onCancelCta: PropTypes.func.isRequired

}

SubscriptionVoucherWarnView.defaultProps = {
  header: '',
  subHeader: ''
}

export default SubscriptionVoucherWarnView
