import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import {
  compose, withState, withHandlers, pure
} from 'recompose'

import ResetPin from '../../components/pin/reset'
import PinForgotten from '../../components/pin/request'
import PinResetSent from '../../components/pin/reset-sent'

const PinForgottenContainer = ({
  isRequestDone,
  onPasswordRequestSuccess,
  password,
  onPinReset,
  isPinReset,
  onResetSuccess,
  ...rest
}) => {
  return (
    <div>
      {
        !isRequestDone && !isPinReset && (
          <PinForgotten
            onRequestSuccess={onPasswordRequestSuccess}
          />
        )
      }
      {
        !isPinReset && isRequestDone && (
          <ResetPin
            password={password}
            onPinReset={onPinReset}
          />
        )
      }
      {
        isPinReset && isRequestDone && (
          <PinResetSent
            {...rest}
            onOkClick={onResetSuccess}
          />
        )
      }
    </div>
  )
}

PinForgottenContainer.propTypes = {
  password: PropTypes.string.isRequired,
  isRequestDone: PropTypes.bool.isRequired,
  isPinReset: PropTypes.bool.isRequired,
  onPinReset: PropTypes.func.isRequired,
  onResetSuccess: PropTypes.func.isRequired,
  onPasswordRequestSuccess: PropTypes.func.isRequired
}

const enhance = compose(
  withRouter,
  withState('password', 'setPassword', ''),
  withState('isRequestDone', 'setIsRequestDone', false),
  withState('isPinReset', 'setIsPinReset', false),
  withHandlers({
    onPasswordRequestSuccess: ({ setIsRequestDone, setPassword }) => (password) => {
      setIsRequestDone(true)
      setPassword(password)
    },
    onPinReset: ({ setIsPinReset }) => () => {
      setIsPinReset(true)
    },
    onResetSuccess: ({ history }) => () => {
      history.goBack()
    }
  }),
  pure
)

export default enhance(PinForgottenContainer)
