import {
  compose,
  setDisplayName,
  mapProps,
  withStateHandlers,
  componentFromProp
} from 'recompose'
import {
  prop,
  pipe,
  find,
  whereEq
} from 'ramda'

import Step1 from './step-one'
import Step2 from './step-two'

/**
 * Update Payment Details Flow:
 *
 * Step 1:
 *  - SubscriptionCreditCardView
 * Step 2:
 *  - SubscriptionConfirmationView
 */

const steps = [
  {
    step: 1,
    component: Step1
  },
  {
    step: 2,
    component: Step2
  }
]

const getStepComponent = step => pipe(
  find(
    whereEq({ step })
  ),
  prop('component')
)(steps)

const initialContainerState = ({ payload = {} }) => ({
  step: 1,
  payload
})

const enhance = compose(
  setDisplayName('UpdatePaymentDetailsContainer'),
  withStateHandlers(
    initialContainerState,
    {
      renderStep: () => (step, payload = {}) => {
        return {
          step,
          payload
        }
      }
    }
  ),
  mapProps(({ step, renderStep, payload }) => {
    const stepComponent = getStepComponent(step)
    return {
      stepComponent,
      renderStep,
      payload
    }
  })
)

export default enhance(
  componentFromProp('stepComponent')
)
