import React from 'react'
import PropTypes from 'prop-types'

import Step1 from '../../../containers/subscriptions/signup-subscription-rental/step-one'
import Step2 from '../../../containers/subscriptions/subscription-confirmation'
import styles from '../../signup/neon/signup-subscriptions.css'

function SignupSubscriptionRental({
  dismiss,
  onSubscriptionSelection,
  orderId,
  step,
  voucherCode,
  paymentMethodId
}) {
  function renderStep(currentStep) {
    switch (currentStep) {
      case 1:
        return (
          <Step1
            voucherCode={voucherCode}
            paymentMethodId={paymentMethodId}
            onSubscriptionSelection={onSubscriptionSelection}
            dismiss={dismiss}
          />
        )
      case 2:
        return (
          <Step2
            voucherCode={voucherCode}
            orderId={orderId}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className={styles.wrapper}>
      {renderStep(step)}
    </div>
  )
}

SignupSubscriptionRental.propTypes = {
  step: PropTypes.number.isRequired,
  voucherCode: PropTypes.string,
  orderId: PropTypes.string,
  onSubscriptionSelection: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
  paymentMethodId: PropTypes.string
}

SignupSubscriptionRental.defaultProps = {
  orderId: '',
  voucherCode: '',
  paymentMethodId: null
}

export default SignupSubscriptionRental
