import {
  compose, setDisplayName, branch, renderComponent
} from 'recompose'

import { LoadingModal } from '../../../../../components/loading/modal'
import SubscriptionVoucherView from '../../../../../components/modals/add-voucher/neon/step-one'
import { withSubscriptVoucherQuery } from './graphql'

const enhance = compose(
  setDisplayName('StepOneVoucherViewContainer'),
  withSubscriptVoucherQuery,
  branch(
    ({ voucherViewLoading, voucherViewError }) => voucherViewLoading || voucherViewError,
    renderComponent(LoadingModal)
  )
)

export default enhance(SubscriptionVoucherView)
