import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import qs from 'query-string'
import classnames from 'classnames'

import { MODALS } from '../../../../constants'
import { useStyles } from './user-exists-modal-content.styles'

const UserExistsModalContent = () => {
  const styles = useStyles()

  const location = useLocation()
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        This email address already exists with a Neon account
        <br />
        Log in instead?
        <br />
      </div>
      <NavLink
        to={{
          pathname: '/',
          search: qs.stringify({
            ...qs.parse(location.search),
            modal: MODALS.qsParams.login
          })
        }}
        className={classnames(styles.primaryButton, styles.button)}
      >
        Login
      </NavLink>
    </div>
  )
}

export default UserExistsModalContent
