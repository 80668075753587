import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import Subscription from './subscription'

import SUBSCRIPTION_VIEW_FRAGMENT from '../../../../graphql/fragments/subscription-view.gql'

import styles from './subscription.css'

function SubscriptionSecondaryButton({
  subscription,
  onSubscriptionSelection
}) {
  return (
    <Subscription
      subscription={subscription}
      onSubscriptionSelection={onSubscriptionSelection}
      buttonStyle={styles.ctaGrayedOut}
    />
  )
}

SubscriptionSecondaryButton.propTypes = {
  subscription: propType(SUBSCRIPTION_VIEW_FRAGMENT).isRequired,
  onSubscriptionSelection: PropTypes.func.isRequired
}

export default SubscriptionSecondaryButton
