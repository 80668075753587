import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { propType } from 'graphql-anywhere'

import Imgix from '../imgix'
import ProductSelector from './product-selector'
import Price from './price'
import PriceText from './price-text'
import EvIframe from '../../containers/shared/evergent-iframe'
import Clamped from '../shared/clamped'

import CONTENT_ITEM_FRAGMENT from '../../../graphql/fragments/content-item.gql'

import styles from './payment-view.css'
import { dataLayerAddToCart } from '../../lib/analytics/datalayer'
import { E2E_SELECTORS } from '../../e2e-selectors'

import { boldRegex } from '../title/title-detail'

function PaymentView({
  contentItem,
  selectedProduct,
  setSelectedProduct,
  header,
  iframe,
  image,
  notes,
  price,
  priceText,
  promotionalPrice,
  onCancel,
  onIframeLoad,
  dataLayerAddToCartFunction,
  onIframePostMessage,
  shouldShowPriceText,
  hasPromoPrice
}) {
  dataLayerAddToCartFunction(
    contentItem,
    promotionalPrice && promotionalPrice < price ? promotionalPrice : price,
    selectedProduct.name
  )

  const brand = 'NEON'
  const title = `Rent blockbuster movies online with ${brand}`

  return (
    <div className={styles.parentWrapper}>
      <Helmet>
        <meta property="og:title" content={title} />
        <title>{title}</title>
      </Helmet>

      <div className={styles.imageWrapper}>
        <Imgix
          className={styles.productImage}
          src={image}
          recipe="purchaseConfirmation"
        />
      </div>

      <div className={styles.productContentWrapper}>
        <div className={styles.productInfo}>
          <Clamped className={styles.title}>{header}</Clamped>
          <div className={styles.productOffer}>
            <Price
              amount={price}
              strikethrough={hasPromoPrice || shouldShowPriceText}
            />
            {hasPromoPrice && !shouldShowPriceText && (
              <Price amount={promotionalPrice} secondary />
            )}
            {shouldShowPriceText && <PriceText text={priceText} />}
          </div>
        </div>

        <div className={styles.productQualitySelectors}>
          <ProductSelector
            products={contentItem.products}
            selectedProduct={selectedProduct}
            setSelectedProduct={setSelectedProduct}
            videoEncodings={contentItem.videoEncodings}
          />
        </div>

        <ul
          className={styles.productNotes}
        >
          {notes.map(note => {
            if (boldRegex.test(note)) {
              const match = boldRegex.exec(note)
              return (
                <li className={styles.productNote} key={note}>
                  <b>{match[1]}</b>
                </li>
              )
            }

            return (
              <li className={styles.productNote} key={note}>
                {note}
              </li>
            )
          })}
        </ul>
      </div>

      <div
        className={styles.iframeWrapper}
        data-lbx-e2e={E2E_SELECTORS.RENTAL_IFRAME}
      >
        <EvIframe
          className="e2e-iframe-rental"
          src={iframe}
          onLoad={onIframeLoad}
          onCancel={onCancel}
          onMessage={onIframePostMessage}
        />
      </div>
    </div>
  )
}

PaymentView.propTypes = {
  contentItem: propType(CONTENT_ITEM_FRAGMENT).isRequired,
  selectedProduct: PropTypes.oneOfType([PropTypes.object]).isRequired,
  setSelectedProduct: PropTypes.func.isRequired,
  header: PropTypes.string.isRequired,
  iframe: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  notes: PropTypes.arrayOf(PropTypes.string).isRequired,
  price: PropTypes.string.isRequired,
  priceText: PropTypes.string,
  promotionalPrice: PropTypes.string,
  onCancel: PropTypes.func.isRequired,
  onIframeLoad: PropTypes.func.isRequired,
  dataLayerAddToCartFunction: PropTypes.func,
  onIframePostMessage: PropTypes.func.isRequired,
  shouldShowPriceText: PropTypes.bool.isRequired,
  hasPromoPrice: PropTypes.bool.isRequired
}

PaymentView.defaultProps = {
  dataLayerAddToCartFunction: dataLayerAddToCart,
  priceText: '',
  promotionalPrice: ''
}

export default PaymentView
