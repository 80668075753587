import { createUseStyles } from 'react-jss'
import { commonStyles } from '../../../modules/shared/common.styles'

export const useStyles = createUseStyles(theme => ({
  ...commonStyles(theme),
  voucherContainer: {
    maxWidth: 550,
    paddingTop: 40,
    [theme.media.smallMobile]: {
      padding: 40
    },
    [theme.media.mobile]: {
      padding: 40
    }
  },

  iconFrame: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 14
  },

  title: {
    fontSize: 30,
    fontWeight: 500,
    lineHeight: '36px',
    textAlign: 'center',
    marginBottom: 26,
    [theme.media.smallMobile]: {
      fontSize: 26
    },
    [theme.media.mobile]: {
      fontSize: 26
    }
  },

  errorMessage: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 325,
    lineHeight: '28px',
    [theme.media.smallMobile]: {
      fontSize: 16
    },
    [theme.media.mobile]: {
      fontSize: 18
    }
  },

  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    [theme.media.desktop]: {
      width: 550
    }
  },

  buttons: {
    width: '48%',
    [theme.media.mobile]: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  },

  secondaryButton: {
    display: 'block',
    fontSize: 18,
    padding: [10, 0],
    textAlign: 'center',
    cursor: 'pointer',
    fontWeight: 350,
    color: theme.black,
    background: theme.white,
    border: `1px solid transparent`,
    outline: 'none',
    borderRadius: 4,
    transition: 'all 0.25s',
    margin: [20, 0],

    '&:hover': {
      background: theme.neonGreenLight
    }
  }
}))
