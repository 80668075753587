import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import SubscriptionCard from './subscription-card'
import SubscriptionAmount from './subscription-amount'
import SubscriptionFeature from './subscription-feature'
import Button from '../../forms/button'

import { subscriptionGa } from '../../../lib/analytics/ga'

import SUBSCRIPTION_VIEW_FRAGMENT from '../../../../graphql/fragments/subscription-view.gql'

import styles from './subscription.css'

function Subscription({
  subscription,
  onSubscriptionSelection,
  buttonStyle
}) {
  return (
    <SubscriptionCard
      highlight={subscription.highlighted}
    >
      <h3 className={styles.header}>
        {subscription.name}
      </h3>
      <div className={styles.priceWrapper}>
        {
          subscription.priceText
            ? (
              <div
                className={styles.priceText}
              >
                {subscription.priceText || ''}
              </div>
            )

            : (
              <SubscriptionAmount
                amount={subscription.price}
                recursivity={subscription.period}
                currency="$"
              />
            )
        }
        <div
          className={styles.priceNote}
        >
          {subscription.priceNote || ''}
        </div>
      </div>
      <div className={styles.features}>
        {
          subscription.features.map(
            feature => (
              <SubscriptionFeature
                key={feature.description}
                feature={feature}
              />
            )
          )
        }
      </div>
      <div className={styles.ctaWrapper}>
        <Button
          className={buttonStyle || styles.cta}
          text={subscription.cta}
          e2eIdentifier={`${subscription.cta}: ${subscription.name}`}
          onClick={() => {
            onSubscriptionSelection(subscription.id)
            subscriptionGa({ action: 'Choose plan', label: subscription.name })
          }}
        />
      </div>
    </SubscriptionCard>
  )
}

Subscription.propTypes = {
  subscription: propType(SUBSCRIPTION_VIEW_FRAGMENT).isRequired,
  onSubscriptionSelection: PropTypes.func,
  buttonStyle: PropTypes.string
}

Subscription.defaultProps = {
  onSubscriptionSelection: null,
  buttonStyle: null
}

export default Subscription
