import {
  compose,
  setDisplayName,
  withStateHandlers,
  componentFromProp
} from 'recompose'

import { withAddVoucherStepProps } from './map-props'

/**
 * Add Voucher Flow:
 *
 * Step 1:
 *  - SubscriptionVoucherView
 * Step 2:
 *  - For active subscriptions: (SubscriptionRedeemVoucherWarnView || SubscriptionCreditCardView)
 *  - For no current/cancelled subscriptions: SubscriptionSelectionView
 * Step 3:
 *  - For active subscriptions: SubscriptionCreditCardView
 *  - For no current/cancelled subscriptions: SubscriptionPaymentView
 * Step 4:
 *  - SubscriptionConfirmationView
 */

const initialContainerState = ({ payload = {} }) => ({
  step: 1,
  payload
})

const enhance = compose(
  setDisplayName('AddVoucherContainer'),
  withStateHandlers(initialContainerState, {
    renderStep: () => (step, payload = {}) => ({ step, payload })
  }),
  withAddVoucherStepProps
)

export default enhance(componentFromProp('stepComponent'))
