import qs from 'query-string'
import { connect } from 'react-redux'
import { graphql } from 'react-apollo'
import {
  compose, branch, renderComponent, withProps, onlyUpdateForKeys
} from 'recompose'

import ProfilesEdit from '../../components/profiles/profiles-edit'
import withIsAuthenticatedAndRedirect from '../../hoc/is-authenticated-redirect'
import requirePin from '../../hoc/require-pin'
import { isPinValid } from '../../lib/pin'
import { getAccountProfileById, requirePinForManageProfile } from '../../lib/account'
import { LoadingModal } from '../../components/loading/modal'

import ACCOUNT_QUERY from '../../../graphql/queries/account.gql'

const enhance = compose(
  withIsAuthenticatedAndRedirect,
  graphql(ACCOUNT_QUERY, {
    name: 'accountQuery',
    options: {
      notifyOnNetworkStatusChange: true
    },
    skip: ownProps => !ownProps.isAuthenticated
  }),
  branch(
    ({ accountQuery }) => accountQuery && (accountQuery.loading || accountQuery.error),
    renderComponent(LoadingModal)
  ),
  withProps(({ location, accountQuery }) => {
    // Get Selected Profile Id from URL
    const selectedProfileId = qs.parse(location.search).profileId
    // TODO check if the profile has pin or not
    // Get selected profile data from account
    const selectedProfile = getAccountProfileById(accountQuery.account, selectedProfileId)
    return {
      selectedProfile
    }
  }),
  connect(
    state => ({
      isPinRequired: !isPinValid(state)
    }),
    () => ({}),
    ({ isPinRequired }, dispatch, ownProps) => {
      return {
        ...ownProps,
        isPinRequired: isPinRequired && requirePinForManageProfile(ownProps.accountQuery.account)
      }
    }
  ),
  onlyUpdateForKeys(['selectedProfile']),
  branch(
    ({ isPinRequired }) => {
      return isPinRequired
    },
    requirePin
  )
)

export default enhance(ProfilesEdit)
