import React from 'react'
import PropTypes from 'prop-types'
import { propType } from 'graphql-anywhere'

import Login from '../../containers/login'
import PaymentView from '../../containers/rentals/payment-view'
import ConfirmationView from '../../containers/rentals/confirmation-view'

import CONTENT_ITEM_FRAGMENT from '../../../graphql/fragments/content-item.gql'
import styles from './login-rental.css'

function LoginRental({
  step,
  onLoginSuccess,
  onPaymentSubmission,
  contentItem,
  orderId,
  onRequestClose,
  isAuthenticated
}) {
  function renderStep() {
    switch (step) {
      case 1:
        return (
          <Login
            onLoginSuccess={onLoginSuccess}
            isRental
          />
        )
      case 2:
        return (
          <PaymentView
            contentItem={contentItem}
            onPaymentSubmission={onPaymentSubmission}
            onRequestClose={onRequestClose}
          />
        )
      case 3:
        return (
          <ConfirmationView
            contentItemId={contentItem.id}
            orderId={orderId}
            isAuthenticated={isAuthenticated}
          />
        )
      default:
        return null
    }
  }
  return (
    <div className={styles.wrapper}>
      {renderStep(step)}
    </div>
  )
}

LoginRental.propTypes = {
  step: PropTypes.number.isRequired,
  onLoginSuccess: PropTypes.func.isRequired,
  onPaymentSubmission: PropTypes.func.isRequired,
  contentItem: propType(CONTENT_ITEM_FRAGMENT),
  orderId: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired
}

LoginRental.defaultProps = {
  orderId: '',
  contentItem: null
}

export default LoginRental
