import qs from 'query-string'
import { useState, useEffect } from 'react'

import { useNode } from '../../signup/hooks/use-node'

/**
 * Orchastrates the payment process via an Evergent iframe.
 *
 * @param {function} onSuccess Callback for a successful payment event
 */
export const useSubscriptionIframe = (onSuccess) => {
  const [iframeRef, iframeNode] = useNode()
  const [promotionRejected, setPromotionRejected] = useState(false)

  useEffect(() => {
    if (!iframeNode) return () => {}

    const onIframeLoad = () => {
      const returnUrl =
        iframeNode.contentWindow &&
        iframeNode.contentWindow.location &&
        iframeNode.contentWindow.location.href

      const extractedSearchParams = qs.extract(returnUrl)
      const { orderId } = qs.parse(extractedSearchParams)

      window.removeEventListener('message', onWindowMessage)
      if (orderId) onSuccess(orderId)
    }

    const onWindowMessage = event => {
      if (event.origin.indexOf('evergent.com') < 0) return

      const data = JSON.parse(event.data)
      const msgType = data && data.type
      const msgHeight = data && data.height
      const msgPromotions = data && data.promotions

      if (msgType === 'height' && msgHeight) {
        iframeNode.height = msgHeight
      }

      if (msgType === 'promotion' && msgPromotions.length) {
        setPromotionRejected(false)
      }

      if (msgType === 'promotion' && !msgPromotions.length) {
        setPromotionRejected(true)
      }
    }

    iframeNode.addEventListener('load', onIframeLoad)
    window.addEventListener('message', onWindowMessage)

    return () => {
      if (iframeNode) {
        iframeNode.removeEventListener('load', onIframeLoad)
        window.removeEventListener('message', onWindowMessage)
      }
    }
  }, [iframeNode, onSuccess])

  return { iframeRef, promotionRejected }
}
