import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useSubscriptionModalPayment } from '../../hooks/use-subscription-modal-payment'
import { useStyles } from './subscription-payment-method-modal.styles'

import CreditCardPicker from '../../../shared/credit-card-picker/credit-card-picker'
import { useCreditCards } from '../../hooks/use-credit-cards'
import { useCreditCardPickerSubmit } from '../../hooks/use-credit-card-picker-submit'
import { LoadingModal } from '../../../../components/loading/modal'
import PaymentMethodIframeContent
  from './payment-method-iframe-content/payment-method-iframe-content'
import PaymentMethodHeader from './payment-method-header-content/payment-method-header'
import RadioDotChecked from '../../../../components/icons/radio-dot-checked'
import RadioDot from '../../../../components/icons/radio-dot'
import { LoadingComponent } from '../../../../components/loading'

const DotIndicator = ({ isSelected }) => {
  const styles = useStyles()
  return isSelected ? <RadioDotChecked className={styles.icon} /> : <RadioDot className={styles.icon} />
}

DotIndicator.propTypes = {
  isSelected: PropTypes.string.isRequired
}

const SubscriptionPaymentMethodModal = () => {
  const [selectedCardPaymentId, setSelectedCardPaymentId] = useState()
  const [isSelectedCardPicker, setIsSelectedCardPicker] = useState(true)

  const styles = useStyles()

  const { validCreditCards, loading: creditCardLoading } = useCreditCards()

  const {
    validationLoading,
    addSubscriptionLoading,
    clearInput,
    ...passwordValidation
  } = useCreditCardPickerSubmit(selectedCardPaymentId)

  const {
    loading: iframeLoading,
    copy,
    detail,
    iframeRef,
    promotionRejected,
    iframeUrl,
    showVoucherMessage,
    isAnnuallyPlan,
    isStandardPlan
  } = useSubscriptionModalPayment()

  useEffect(() => {
    if (validCreditCards.length > 0) {
      setSelectedCardPaymentId(validCreditCards[0].id)
      setIsSelectedCardPicker(true)
    }
  }, [validCreditCards])

  const creditCardsPickerHandler = (paymentId) => {
    setSelectedCardPaymentId(paymentId)
    clearInput()
  }

  if (creditCardLoading) {
    return <LoadingModal />
  }

  return (
    <div className={styles.container}>
      <PaymentMethodHeader
        promotionRejected={promotionRejected}
        showVoucherMessage={showVoucherMessage}
        detail={detail}
        isAnnuallyPlan={isAnnuallyPlan}
        isStandardPlan={isStandardPlan}
        copy={copy}
      />
      {
        validCreditCards.length > 0
          ? (
            <>
              {(validationLoading || addSubscriptionLoading)
                ? (
                  <div className={styles.border}>
                    <LoadingComponent pastDelay />
                  </div>
                )
                : (
                  <>
                    <div className={styles.border}>
                      <div
                        className={styles.selector}
                        onClick={() => setIsSelectedCardPicker(true)}
                      >
                        <DotIndicator isSelected={isSelectedCardPicker} />
                        Use an existing credit card
                      </div>
                      <div className={styles.divider} />
                      {
                        isSelectedCardPicker &&
                        (
                          <CreditCardPicker
                            validCreditCards={validCreditCards}
                            defaultCardId={selectedCardPaymentId || validCreditCards[0].id}
                            onSelected={(paymentId) => creditCardsPickerHandler(paymentId)}
                            {...passwordValidation}
                          />
                        )
                      }
                    </div>
                    <div className={classNames(styles.border, styles.paymentContainer)}>
                      <div
                        className={styles.selector}
                        onClick={() => setIsSelectedCardPicker(false)}
                      >
                        <DotIndicator
                          isSelected={!isSelectedCardPicker}
                        />
                        Use a different card
                      </div>
                      {!isSelectedCardPicker &&
                      (
                        <PaymentMethodIframeContent
                          iframeRef={iframeRef}
                          iframeUrl={iframeUrl}
                          loading={iframeLoading}
                        />
                      )}
                    </div>
                  </>
                )}
            </>
          )
          : (
            <PaymentMethodIframeContent
              iframeRef={iframeRef}
              iframeUrl={iframeUrl}
              loading={iframeLoading}
            />
          )
      }
    </div>
  )
}

export default SubscriptionPaymentMethodModal
