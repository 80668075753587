import React from 'react'
import PropTypes from 'prop-types'
import qs from 'query-string'
import {
  compose, withState, withStateHandlers, withHandlers
} from 'recompose'

import PasswordResetRequest from '../../components/password-reset/request'
import PasswordResetRequestSent from '../../components/password-reset/request-sent'
import { getModalLocation } from '../../lib/modal'
import { MODALS } from '../../constants'

const PasswordForgottenContainer = ({
  isRequestDone,
  onPasswordRequestSuccess,
  email,
  onLoginClick
}) => {
  return (
    <div>
      {
        isRequestDone
          ? (
            <PasswordResetRequestSent
              email={email}
              onLoginClick={onLoginClick}
            />
          )
          : (
            <PasswordResetRequest
              email={email}
              onRequestSuccess={onPasswordRequestSuccess}
            />
          )
      }
    </div>
  )
}

PasswordForgottenContainer.propTypes = {
  email: PropTypes.string.isRequired,
  isRequestDone: PropTypes.bool.isRequired,
  onLoginClick: PropTypes.func.isRequired,
  onPasswordRequestSuccess: PropTypes.func.isRequired
}

const enhance = compose(
  withStateHandlers(({ location }) => {
    const parsedSearch = qs.parse(location && location.search)
    const email = (parsedSearch && parsedSearch.email) || ''

    return {
      email
    }
  }, {
    setEmail: () => (email) => {
      return {
        email
      }
    }
  }),
  withState('isRequestDone', 'setIsRequestDone', false),
  withHandlers({
    onPasswordRequestSuccess: ({ setIsRequestDone, setEmail }) => (email) => {
      setIsRequestDone(true)
      setEmail(email)
    },
    onLoginClick: ({ history, location }) => () => {
      history.push(getModalLocation(location, MODALS.qsParams.login))
    }
  })
)

export default enhance(PasswordForgottenContainer)
