import React from 'react'
import PropTypes from 'prop-types'

import styles from './subscription-amount.css'

function SubscriptionAmount({ currency, amount, recursivity }) {
  if (!Number(amount)) {
    return null
  }
  const amountSplit = String(amount).split('.')
  const leftAmount = amountSplit[0]
  const rightAmount = amountSplit[1]

  return (
    <div className={styles.amount}>
      <div className={styles.currency}>
        {currency}
      </div>
      <div className={styles.leftAmount}>
        {leftAmount}
      </div>
      <div className={styles.rightAmountWrapper}>
        <div className={styles.rightAmount}>
          {`.${rightAmount || '00'}`}
        </div>
        {
          recursivity && (
            <div className={styles.recursivity}>
              /
              {recursivity}
            </div>
          )
        }
      </div>
    </div>
  )
}

SubscriptionAmount.propTypes = {
  currency: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  recursivity: PropTypes.string.isRequired
}

export default SubscriptionAmount
