import React from 'react'
import PropTypes from 'prop-types'

import styles from './quote.css'

const QUOTE_CHAR_LIMIT = 60

function Quote({ text, author }) {
  if (!text) {
    return null
  }

  const trimmedText = text.length > QUOTE_CHAR_LIMIT ? `${text.substring(0, QUOTE_CHAR_LIMIT)}...` : text
  return (
    <div className={styles.quoteWrapper}>
      <div className={styles.quote}>
        &ldquo;
        {trimmedText}
        &rdquo;
      </div>
      <span className={styles.quoteAuthor}>{author}</span>
    </div>
  )
}

Quote.propTypes = {
  text: PropTypes.string.isRequired,
  author: PropTypes.string
}

Quote.defaultProps = {
  author: null
}

export default Quote
